import styled, { css } from 'styled-components'

export const RulesContentCard = styled.div`
  font-family: Montserrat;
`

export const ConfirmButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const ButtonCentered = styled.div`
  margin-right: 0;
  margin-left: 215px;
  margin-top: calc(100% - 119%);

  ${({ isLeft }) =>
    isLeft &&
    css`
      margin-right: 215px;
      margin-left: 0;
      margin-top: calc(100% - 122%);
    `}
`

export const RightArrow = styled.div`
  margin-right: 1%;
  margin-top: calc(100% - 142%);
`

export const LeftArrow = styled.div`
  margin-left: 1%;
  margin-top: calc(100% - 142%);
`

export const CharactersFigure = styled.figure`
  background-color: transparent;
  border: 0;
  display: grid;
  place-items: center;
  margin: 1.6rem 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const DesktopFigure = styled.figure`
  display: grid;
  place-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const RulesText = styled.section`
  font-family: MontSerrat;

  span {
    font-weight: bolder;
  }
`

export const Paragraphs = styled.p`
  font-size: 0.875rem;
  letter-spacing: 0.06px;
  line-height: 1.25rem;
  margin: 0 0 1.42rem;

  ${({ isLast }) =>
    isLast &&
    css`
      margin: 0;
    `}
`
export const DesktopContainer = styled.main`
  @media screen and (max-width: 1023px) {
    display: none;
  }

  margin-left: -1px;
  margin-bottom: -5px;
  font: Montserrat;
`

export const CurrentImageContainer = styled.div`
  svg {
    height: 100%;
    width: 100%;
  }
`

export const DesktopContents = styled.div`
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.142rem;
    padding: 2.142rem 2.1rem 0;
    width: 800px;
  }
`

export const MobileContainer = styled.div`
  padding: 0 1rem 1rem;
  max-width: 382px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const ContainerButtonNFigures = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  margin: 1rem 0;
`

export const ContainerMarkStepRule = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 3px;
  width: 116px;
  height: 38px;
  border-radius: 19px;
  border: solid 4px ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};

  span {
    background-color: ${({ theme }) => theme.colors.veryLightGrey};
    border-radius: 50vh;
    border: solid 3px ${({ theme }) => theme.colors.lightGrey};
    width: 17px;
    height: 17px;
    margin: 0 5.5px;
  }

  span:nth-child(${({ currentMarkIndex }) => currentMarkIndex + 1}) {
    background-color: ${({ theme }) => theme.colors.amber};
    border: solid 3px ${({ theme }) => theme.colors.black};
  }

  @media screen and (min-width: 1000px) {
    margin-right: 60px;
    margin-top: -54px;
  }
`

export const JustifyContantEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const FooterButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  button,
  div {
    width: 100% !important;
  }
`
