import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IcFacebook, IcPlay, IcSignUp } from '../../../../assets/img/buttonIcons'
import { loginFacebook, playWithoutAccount } from '../../../../services/API/login'
import { clearAll, set } from '../../../../services/storage'
import { useStore } from '../../../../store/hooks'
import { ButtonContainer } from '../../styles'
import { ButtonWithRadiusBorderAndIcon } from '../ButtonWithRadiusBorderAndIcon'
import { CardPlayWithoutLogin } from '../CardPlayWithoutLogin'

export const LoginSocialMediaCollunm = ({
  loginStrings,
  isLoginCard,
  closeLoginCard,
  showSignUpCard,
}) => {
  const { dispatch } = useStore()
  const history = useHistory()

  const [showCardPlayWithoutLogin, setShowCardPlayWithoutLogin] = useState(false)

  function goToSignUp() {
    if (isLoginCard) {
      showSignUpCard()
    } else {
      history.push('/sign-up')
    }
  }

  function openCardPlayWithoutLogin() {
    if (isLoginCard) {
      closeLoginCard()
    } else {
      setShowCardPlayWithoutLogin(true)
    }
  }

  function closeCardWithoutLogin(go) {
    setShowCardPlayWithoutLogin(false)

    if (go) {
      playWithoutAccount(dispatch)
      history.push('/start')
    }
  }

  function checkLoginCardFacebook() {
    if (!isLoginCard) {
      clearAll()
    } else {
      set('isLoginFacebook', true)
    }

    loginFacebook()
  }

  return (
    <>
      {showCardPlayWithoutLogin && (
        <CardPlayWithoutLogin closeCardWithoutLogin={closeCardWithoutLogin} />
      )}
      <ButtonContainer withoutPaddingBottom>
        <ButtonWithRadiusBorderAndIcon
          buttonValues={loginStrings.facebookButton}
          Icon={IcFacebook}
          sendClick={checkLoginCardFacebook}
        />
        <div className="signUpBottom">
          <ButtonWithRadiusBorderAndIcon
            buttonValues={loginStrings.signUpButton}
            Icon={IcSignUp}
            sendClick={goToSignUp}
          />
        </div>
        <ButtonWithRadiusBorderAndIcon
          buttonValues={loginStrings.withoutLoginButton}
          Icon={IcPlay}
          sendClick={openCardPlayWithoutLogin}
        />
      </ButtonContainer>
      <div
        className="fb-like"
        data-share="true"
        data-width="150"
        data-show-faces="true"></div>
    </>
  )
}

LoginSocialMediaCollunm.propTypes = {
  loginStrings: PropTypes.object.isRequired,
  isLoginCard: PropTypes.bool,
  closeLoginCard: PropTypes.func,
  showSignUpCard: PropTypes.func,
}

LoginSocialMediaCollunm.defaultProps = {
  isLoginCard: false,
}
