import styled, { css } from 'styled-components'

import { setColorOfButton } from '../validateColorForButton'

export const CircularButton = styled.button`
  ${setColorOfButton};
  border: solid 0.2rem ${({ theme }) => theme.colors.black};
  border-radius: 50vh;
  box-shadow: -2.5px 2px rgb(29, 29, 27);
  padding: 0.45rem 0.5rem 0.5rem 0.55rem;
  display: grid;
  place-items: center;

  ${({ rotateImg }) =>
    rotateImg &&
    css`
      padding: 0.45rem 0.5rem 0.5rem 0.45rem;
      svg,
      img {
        transform: rotate(180deg);
      }
    `}

  &:focus,
  &:hover {
    outline: 1px dotted;
  }

  &:disabled {
    background-image: none;
    background-color: ${({ theme }) => theme.colors.veryLightPink};
    border: solid 0.2rem transparent;
    box-shadow: -3px 2px 3px 0.5px transparent;
    svg,
    img {
      opacity: 0.25;
    }
  }

  ${({ isDesktop, disabled }) =>
    isDesktop &&
    disabled &&
    css`
      opacity: 0;
    `}

  ${({ showingArrowDice, isMobile }) =>
    showingArrowDice &&
    css`
    ${
      !isMobile
        ? `&::after{
      content: 'Role o dado para jogar';
      width: 142px;
      position: absolute;
      right: -245px;
      top: 28px;
      font-family: 'MilkyNice';
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: ${({ theme }) => theme.colors.black};
      }`
        : ''
    }

    &::before {
      content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="74" height="54" viewBox="0 0 74 54"><g fill="none" fill-rule="evenodd" stroke-linejoin="round"><g fill="orange" stroke="black" stroke-width="3"><path d="M313 530.568L313 569.073 274.677 562.903 274.677 576 243 552.295 274.677 526 274.677 538.962z" transform="translate(-241 -524)"/></g></g></svg>');
      position: absolute;
      ${isMobile ? 'right: calc(50% + 80px);' : 'left: calc(105% - 20px);'} 
      bottom: ${isMobile ? '72px' : '86px'} ;
      animation: animateArrowDice 1s linear infinite;
    }

    @keyframes animateArrowDice {
      from { transform: ${isMobile ? 'rotate(180deg)' : ''} translate(0em, 0.5em); }
      50% { transform: ${isMobile ? 'rotate(180deg)' : ''} translate(1em, 0.5em); }
      to { transform: ${isMobile ? 'rotate(180deg)' : ''} translate(0em, 0.5em); }
    }
  `}
`
