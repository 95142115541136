import React from 'react'
import PropTypes from 'prop-types'

import { IcX } from '../../../assets/img'
import { Card } from '..'
import { HeaderCard } from './styles'

import { CircularButtonComponent } from '../../Buttons/circularButton'

export const CardWithHeader = ({
  titleCard,
  backgroundColorHeader,
  fontColorHeader,
  withCloseButton,
  handleClickCloseButton,
  children,
  data_test,
  isSmall,
  className,
}) => {
  return (
    <>
      <Card className={className} isSmall={isSmall} data_test={data_test}>
        <HeaderCard
          backgroundColor={backgroundColorHeader}
          fontColor={fontColorHeader}
          withoutButton={withCloseButton}
          data-test={`${data_test}Header`}>
          <h1>{titleCard}</h1>

          {withCloseButton && (
            <CircularButtonComponent
              color="lemonGreen"
              type="button"
              onClick={handleClickCloseButton}
              data_test={`${data_test}HeaderButton`}>
              <IcX />
            </CircularButtonComponent>
          )}
        </HeaderCard>

        <section>{children}</section>
      </Card>
    </>
  )
}

CardWithHeader.propTypes = {
  titleCard: PropTypes.string.isRequired,
  fontColorHeader: PropTypes.string,
  backgroundColorHeader: PropTypes.string,
  withCloseButton: PropTypes.bool,
  handleClickCloseButton: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  data_test: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
}

CardWithHeader.defaultProps = {
  fontColorHeader: 'black',
  backgroundColorHeader: 'white',
  withCloseButton: false,
  handleClickCloseButton: () => {
    throw new Error('if withCloseButton is true, handleClickCloseButton is required !')
  },
  isSmall: false,
}
