import React, { useEffect, useState } from 'react'
import PropTypes, { bool, func, object, string } from 'prop-types'

import { Label, InputText, Info, EyeClose, EyeOpen, DisplayFlex } from './styles'

import { OpenEyeIcon, CloseEyeIcon } from '../../assets/img/inputIcon'
import { validateEmail } from '../../common/utils'

export const Input = ({
  objectString,
  sendValue,
  clearInput,
  inputName,
  type,
  maxLength,
  value,
  matchPassword,
  isRequired,
  showRequired,
  isPassword,
  isDisabled,
  showError,
}) => {
  const [showRequiredBorder, setShowRequiredBorder] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [inputType, setInputType] = useState(type)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  function seePassword() {
    const input = inputType === 'text' ? 'password' : 'text'
    setInputType(input)
  }

  useEffect(checkRequiredValue, [showRequired])
  function checkRequiredValue() {
    const showErrorOrRequired = showRequired && (showError || !inputValue)

    if (showErrorOrRequired) {
      setShowRequiredBorder(true)
    } else {
      setShowRequiredBorder(false)
    }
  }

  function setValue(e) {
    checkRequiredValue()
    setInputValue(e.target.value)

    if (type === 'email' && !validateEmail(e.target.value)) {
      setShowRequiredBorder(true)
      clearInput(inputName)
    } else {
      setShowRequiredBorder(false)
      sendValue(e)
    }
  }

  return (
    <div>
      <Label>{objectString.label}</Label>

      <DisplayFlex>
        <InputText
          matchPassword={matchPassword}
          showRequiredBorder={showRequiredBorder}
          type={inputType}
          maxLength={maxLength}
          data-test={objectString.dataTest}
          placeholder={objectString.placeholder}
          name={inputName}
          onChange={setValue}
          required={isRequired}
          disabled={isDisabled}
          value={inputValue}
          autoComplete="off"
        />

        {isPassword && inputType !== 'password' && (
          <EyeOpen onClick={seePassword}>
            <OpenEyeIcon />
          </EyeOpen>
        )}

        {isPassword && inputType === 'password' && (
          <EyeClose onClick={seePassword}>
            <CloseEyeIcon />
          </EyeClose>
        )}
      </DisplayFlex>

      {objectString.info && (
        <Info dangerouslySetInnerHTML={{ __html: objectString.info }} />
      )}

      {showError && (
        <Info
          dangerouslySetInnerHTML={{ __html: objectString.error }}
          data-teste="msgErro"
        />
      )}
    </div>
  )
}

Input.propTypes = {
  objectString: object,
  sendValue: func.isRequired,
  clearInput: func,
  inputName: string,
  type: string,
  maxLength: string,
  matchPassword: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isRequired: bool,
  showRequired: bool,
  isPassword: bool,
  isDisabled: bool,
  showError: bool,
  value: string,
}

Input.defaultProps = {
  type: 'text',
  matchPassword: undefined,
  isRequired: false,
  isDisabled: false,
  showRequired: false,
  isPassword: false,
  showError: false,
  value: '',
}
