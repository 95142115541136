import React, { useMemo, useRef, useState } from 'react'
import ReactDice from 'react-dice-complete'
import { number, func } from 'prop-types'
import 'react-dice-complete/dist/react-dice-complete.css'

import { isMobileSize } from '../../../../common/utils'

import { Counter } from '../../../Counter'
import { DiceContainer } from './styles'
import { gamePropTypes } from '../../../../game'
import { CircularButtonComponent } from '../../../Buttons/circularButton'

export const Dice = ({ numDices, rollDone, G }) => {
  const isMobile = useMemo(isMobileSize, [])

  const reactDice = useRef(null)

  const [timerId, setTimerId] = useState()
  const [isRollDone, setIsRollDone] = useState(true)
  const [showingArrowDice, setShowingArrowDice] = useState(true)

  let timingToShowArrowDice = 0
  const secondsToShowArrowDice = 30

  function clearTiming() {
    timingToShowArrowDice = 0
    clearInterval(timerId)
  }

  function initTimeToShowArrowDice() {
    clearTiming()

    setTimerId(
      setInterval(function () {
        timingToShowArrowDice = timingToShowArrowDice + 1

        if (timingToShowArrowDice >= secondsToShowArrowDice) {
          clearTiming()
          setShowingArrowDice(true)
        }
      }, 1000),
    )
  }

  function sendRollDone(diceValue) {
    rollDone(diceValue)
    setIsRollDone(true)
  }

  function rollDice() {
    if (isRollDone) {
      setIsRollDone(false)
      setShowingArrowDice(false)
      initTimeToShowArrowDice()
      reactDice.current.rollAll()
    }
  }

  return (
    <DiceContainer>
      <CircularButtonComponent
        showingArrowDice={showingArrowDice}
        isMobile={isMobile}
        data_test="buttonDice"
        type="button"
        color="lemonGreen"
        onClick={rollDice}>
        <ReactDice
          data-test="Dice"
          numDice={numDices}
          rollDone={sendRollDone}
          faceColor="#fff"
          dotColor="#000"
          disableIndividual={true}
          ref={reactDice}
        />
      </CircularButtonComponent>
      <Counter data-test="TurnCounter" value={G.turnCounter} maxValue={G.maxTurns} />
    </DiceContainer>
  )
}

Dice.propTypes = {
  numDices: number,
  rollDone: func.isRequired,
  G: gamePropTypes.G,
}

Dice.defaultProps = {
  numDices: 1,
}
