import React, { useState, useEffect, useCallback, createRef, useMemo } from 'react'

import { theme } from '../../styles/theme'
import { calculateUserProgress } from './utils'
import { IcFlagWin, IcMiddle, IcStar, IcSuperior, IcX } from '../../assets/img'
import { characters } from '../../game/characters'
import { gamePropTypes } from '../../game'

import {
  ComponentContainer,
  CharacterPicture,
  BarFrame,
  TitleBarContainer,
  StageTitle,
  ExpTagContainer,
  BarProgress,
  CharacterPictureContainer,
  ProgressBarContainer,
  Divider,
  SvgDivider,
  Tooltip,
  CounterHome,
  FlagPortalZ,
} from './styles'
import { portalZInfo, thisIsPortalZ } from '../../common/strings'
import useWindowSize, { isMobileSize, replaceKeyToText } from '../../common/utils'
import { bool } from 'prop-types'
import { Counter } from '../Counter'

export function ProgressBar({ G, isHome }) {
  const { colors } = theme
  const isMobile = useMemo(isMobileSize, [])
  const outerRef = createRef()
  const width = useWindowSize()

  const tooltipPortalZ = {
    widthSize: 310,
    heightSize: 165,
    afterTop: -152,
    afterLeft: 1,
    afterTopMobile: -152,
    afterLeftMobile: 220,
    marginTop: 20,
    marginLeft: 1,
    marginTopMobile: 17,
    marginLeftMobile: -240,
  }

  function getStageKey() {
    return Object.keys(G.progressLevels)[G.gameStage]
  }

  function getStageProgress() {
    return G.progressLevels[getStageKey()]
  }

  function getProgress(min, max) {
    return calculateUserProgress(G.progress, 0, 1000)
  }

  const cbGetCharacterPortrait = useCallback(() => {
    if (!G.character) return
    return characters.filter((character) => character.name === G.character.name)[0]?.icon
  }, [G.character])

  const [progress, setProgress] = useState(0)
  const [stageName, setStageName] = useState(G.gameStagesNames[G.gameStage])
  const [stageProgress, setStageProgress] = useState(getStageProgress())
  const [progressColor, setProgressColor] = useState(colors.stages[getStageKey()])
  const [characterPortrait, setCharacterPortrait] = useState()
  const [progressWidth, setProgressWidth] = useState(0)
  const [showPortalZInfo, setShowPortalZInfo] = useState(false)

  useEffect(getProgressWidth, [width])
  function getProgressWidth() {
    setProgressWidth(outerRef.current.offsetWidth)
  }

  const stageDividers = useMemo(setStageDividers, [progressWidth])
  function setStageDividers() {
    return [
      {
        progressWidth: progressWidth,
        percentDesktop: 23.1,
        percentMobile: 30,
        Icon: IcMiddle,
      },
      {
        progressWidth: progressWidth,
        percentDesktop: 53,
        percentMobile: 59,
        Icon: IcSuperior,
      },
    ]
  }

  useEffect(setPortrait, [G.character])
  function setPortrait() {
    setCharacterPortrait(cbGetCharacterPortrait())
  }

  useEffect(calculateUserPoint, [G.progress])
  function calculateUserPoint() {
    const { min, max } = stageProgress
    const calculatedProgress = getProgress(min, max)
    setProgress(calculatedProgress)
  }

  useEffect(verifyStageProgression, [progress])
  function verifyStageProgression() {
    if (progress >= 1) {
      calculateUserPoint()
    }
  }

  useEffect(changeStage, [G.gameStage])
  function changeStage() {
    setStageName(G.gameStagesNames[G.gameStage])
    setStageProgress(getStageProgress())
    setProgressColor(colors.stages[getStageKey()])
    const { min, max } = stageProgress
    const progress = getProgress(min, max)
    setProgress(progress)
  }

  function handlePortalZInfo() {
    setShowPortalZInfo(!showPortalZInfo)
  }

  function openPortalZInfo() {
    const tenSecounds = 10000
    setShowPortalZInfo(true)
    setInterval(function () {
      closePortalZInfo()
    }, tenSecounds)
  }

  function closePortalZInfo() {
    setShowPortalZInfo(false)
  }

  return (
    <ComponentContainer isHome={isHome} data-test="ProgressBar">
      <CharacterPictureContainer>
        <CharacterPicture portrait={characterPortrait} />
        {isHome && (
          <CounterHome>
            <Counter maxValue={G.maxTurns} value={G.turnCounter} />
          </CounterHome>
        )}
      </CharacterPictureContainer>
      <ProgressBarContainer>
        <TitleBarContainer>
          <StageTitle data-test="stageName">{stageName}</StageTitle>
          <BarFrame ref={outerRef}>
            <BarProgress progress={progress} color={progressColor} />
            {stageDividers.map(
              ({ progressWidth, percentDesktop, percentMobile, Icon }) => {
                return (
                  <SvgDivider
                    progressWidth={progressWidth}
                    percentDesktop={percentDesktop}
                    percentMobile={percentMobile}
                    key={percentDesktop}>
                    <Divider />
                    <IcStar />
                    <Icon />
                  </SvgDivider>
                )
              },
            )}
          </BarFrame>

          {stageProgress && (
            <ExpTagContainer>
              <div>{`${G.progress}xp`}</div>
              <div>{`${1000}xp`}</div>
            </ExpTagContainer>
          )}
        </TitleBarContainer>
        <FlagPortalZ
          onMouseOver={handlePortalZInfo}
          onMouseOut={handlePortalZInfo}
          onTouchStart={openPortalZInfo}>
          {!isHome && <IcFlagWin />}
          {showPortalZInfo && (
            <Tooltip styles={tooltipPortalZ} withCloseButton={isMobile} bgColor="white">
              <ExpTagContainer>
                <b>{thisIsPortalZ}</b>
                <IcX onClick={closePortalZInfo} />
              </ExpTagContainer>
              {G.character && G.character.name && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: replaceKeyToText(portalZInfo, '<name>', G.character.name),
                  }}
                />
              )}
            </Tooltip>
          )}
        </FlagPortalZ>
      </ProgressBarContainer>
    </ComponentContainer>
  )
}

const { G } = gamePropTypes
ProgressBar.propTypes = {
  G,
  isHome: bool,
}

ProgressBar.defaultProps = {
  isHome: false,
}
