import React from 'react'
import PropTypes from 'prop-types'

import { BackgroundBlur, Container } from '../GameCards/styles'
import { CardWithHeader } from '../Cards'

import { rulesCardTitle } from '../../common/strings'

import { DesktopRules } from './desktopRules'
import { MobileRules } from './mobileRules'
import { RulesContentCard } from './styles'

export const RulesCard = ({ onStartButtonClick }) => (
  <>
    <Container>
      <CardWithHeader
        titleCard={rulesCardTitle}
        backgroundColorHeader="pistachioGreen"
        withCloseButton={true}
        handleClickCloseButton={onStartButtonClick}
        data_test="RulesCard">
        <RulesContentCard>
          <DesktopRules onStartButtonClick={onStartButtonClick} />
          <MobileRules onStartButtonClick={onStartButtonClick} />
        </RulesContentCard>
      </CardWithHeader>
    </Container>
    <BackgroundBlur />
  </>
)

RulesCard.propTypes = {
  onStartButtonClick: PropTypes.func.isRequired,
}
