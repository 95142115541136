import {
  emailText,
  passwordText,
  rememberText,
  forgetPasswordText,
  playText,
  signUpYourselfText,
  loginFacebookText,
  loginGoogleText,
  playWithoutLoginTitle,
  loginText,
  orPlayWithSocialMedia,
  dontHaveAccountText,
  invalidLogigText,
} from '../../common/strings'

export const getLoginStrings = () => {
  return {
    username: {
      label: emailText,
      placeholder: emailText,
      dataTest: 'inputUsernameLogin',
    },
    password: {
      label: passwordText,
      placeholder: passwordText,
      dataTest: 'inputPasswordLogin',
    },
    rememberPassword: {
      dataTest: 'checkboxRememberPassword',
      label: rememberText,
    },
    forgetPassword: {
      dataTest: 'linkDidYouForgetPassarword',
      label: forgetPasswordText,
    },
    loginButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'orange',
      },
      dataTest: 'buttonLogin',
      value: playText,
    },
    signUpButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'white',
      },
      dataTest: 'buttonSignUp',
      value: signUpYourselfText,
    },
    facebookButton: {
      colors: {
        borderColor: 'darkBlue',
        colorText: 'white',
        hoverColor: 'lightBlue',
        bgColor: 'blueFacebook',
      },
      dataTest: 'buttonLoginWithFacebook',
      value: loginFacebookText,
    },
    googleButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'white',
      },
      dataTest: 'buttonLoginWithGoogle',
      value: loginGoogleText,
    },
    withoutLoginButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'white',
      },
      dataTest: 'buttonPlayWithoutLogin',
      value: playWithoutLoginTitle,
    },
    labelLogin: {
      label: loginText,
    },
    labelPlayWithSocialMedia: {
      label: orPlayWithSocialMedia,
    },
    labelDontHaveAccount: {
      label: dontHaveAccountText,
    },
    labelInvalidLogin: {
      label: invalidLogigText,
    },
  }
}
