import { useMemo } from 'react'

import {
  IcCompleteJuniorStage,
  IcCompleteMiddleStage,
  IcCompleteSuperiorStage,
} from '../../assets/img/stages'

import { stringsStageAlert } from '../../common/strings'

export function useContentOfTextOnStage(characterName, nameOfCompleteLevel) {
  const contentCard = useMemo(
    () => ({
      junior: {
        ...stringsStageAlert.junior,
        Icon: IcCompleteJuniorStage,
      },
      middle: {
        ...stringsStageAlert.middle,
        Icon: IcCompleteMiddleStage,
      },
      superior: {
        ...stringsStageAlert.superior,
        Icon: IcCompleteSuperiorStage,
      },
    }),
    [],
  )[nameOfCompleteLevel]

  function addCharacterNameOnMessage(text, splitKey, textToJoin) {
    // const updatedText = text.split(splitKey).join(textToJoin)
    const updatedText = text.split('<name>').join(characterName)
    return updatedText
  }

  return {
    ...contentCard,
    message: addCharacterNameOnMessage(contentCard.message),
    addCharacterNameOnMessage,
  }
}
