import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { CircularButton } from './styles'
import { useStore } from '../../../store/hooks'

import ClickButton from '../../../assets/music/click_buttons.mp3'

export const CircularButtonComponent = ({
  color,
  onClick,
  disabled,
  children,
  rotateImg,
  data_test,
  id,
  isDesktop,
  to,
  isMobile,
  showingArrowDice,
}) => {
  const { store } = useStore()
  const { sound } = store
  const audio = useRef()

  async function sendOnClick(e) {
    if (sound && audio.current !== null) {
      audio.current.play()
    }

    setTimeout(function () {
      onClick(e)
    }, 300)
  }

  return (
    <>
      <audio ref={audio}>
        <source src={ClickButton} type="audio/mpeg" />
      </audio>
      <CircularButton
        to={to}
        id={id}
        rotateImg={rotateImg}
        type="button"
        color={color}
        isDesktop={isDesktop}
        disabled={disabled}
        onClick={sendOnClick}
        data-test={data_test}
        showingArrowDice={showingArrowDice}
        isMobile={isMobile}>
        {children}
      </CircularButton>
    </>
  )
}

CircularButtonComponent.propTypes = {
  color: PropTypes.string,
  data_test: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  key: PropTypes.string,
  withoutMarginTop: PropTypes.bool,
  Icon: PropTypes.object,
  disabled: PropTypes.bool,
  rotateImg: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  showingArrowDice: PropTypes.bool,
  to: PropTypes.string,
}

CircularButtonComponent.defaultProps = {
  color: '',
  withoutMarginTop: false,
  id: '',
  rotateImg: false,
  disabled: false,
  children: {},
  isMobile: false,
  showingArrowDice: false,
  isDesktop: false,
  to: '#',
}
