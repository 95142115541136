import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import { login } from '../../../../services/API/login'
import { clearAll, set } from '../../../../services/storage'

import { IcPlay, IcSignUp } from '../../../../assets/img/buttonIcons'

import { Input } from '../../../../components'

// import { CheckBox, LabelCheckBox } from '../../../../components/Forms/Checkbox'

import {
  LabelDontHaveAccount,
  InvalidLogin,
  InputLoginContainer,
  DisplayFlex,
  ForgetPassword,
} from './styles'

import { ButtonWithRadiusBorderAndIcon } from '../ButtonWithRadiusBorderAndIcon'
import { CardForgetPassword } from '../CardForgetPassword'

import { ButtonContainer } from '../../styles'
import { useStore } from '../../../../store/hooks'
import { types } from '../../../../store'
import { setToken } from '../../../../services/API'

export const LoginEmailCollunm = ({
  loginStrings,
  isLoginCard,
  closeLoginCard,
  showSignUpCard,
  handleLoading,
}) => {
  const { dispatch } = useStore()
  const history = useHistory()

  const [showCardForgetPassword, setShowCardForgetPassword] = useState(false)
  // const [rememberPassword, setRememberPassword] = useState(false)
  const [isValidLogin, setIsValidLogin] = useState(null)
  const [loginData, setLoginData] = useState({
    emailLogin: '',
    passwordLogin: '',
  })

  function setFormValue(event) {
    const { name, value } = event.target
    const inputValue = { [name]: value.trim() }
    setLoginData({ ...loginData, ...inputValue })
  }

  // function chooseRememberPassword() {
  //   setRememberPassword(!rememberPassword)
  // }

  function goToSignUp() {
    if (isLoginCard) {
      showSignUpCard()
    } else {
      history.push('/sign-up')
    }
  }

  async function setLogin(event) {
    event.preventDefault()

    if (loginData.emailLogin && loginData.passwordLogin) {
      const loginObj = {
        email: loginData.emailLogin,
        password: loginData.passwordLogin,
      }

      clearAll()
      setIsValidLogin(true)
      handleLoading(true)
      const response = await login(loginObj)
      handleLoading(false)

      if (response) {
        setIsValidLogin(true)

        dispatch({ type: types.SET_USER_DETAILS, payload: response.user })
        dispatch({ type: types.SET_AUTH_TOKEN, payload: response.token })
        set('user', response.user)
        set('token', response.token)
        setToken(response.token)

        if (isLoginCard) {
          closeLoginCard()
        } else {
          history.push('/home')
        }
      } else {
        setIsValidLogin(false)
      }
    } else {
      setIsValidLogin(false)
    }
  }

  function closeCardForgetPassword() {
    setShowCardForgetPassword(false)
  }

  function openCardForgetPassword() {
    setShowCardForgetPassword(true)
  }

  return (
    <>
      {showCardForgetPassword && (
        <CardForgetPassword closeCardForgetPassword={closeCardForgetPassword} />
      )}
      <InputLoginContainer>
        <Input
          objectString={loginStrings.username}
          inputName="emailLogin"
          sendValue={setFormValue}
          isRequired={true}
          matchPassword={isValidLogin}
        />

        <div>
          <Input
            objectString={loginStrings.password}
            inputName="passwordLogin"
            type="password"
            sendValue={setFormValue}
            isRequired={true}
            isPassword={true}
            matchPassword={isValidLogin}
          />

          <InvalidLogin data-test="EmailOrPasswordInvalid" isValidLogin={isValidLogin}>
            {loginStrings.labelInvalidLogin.label}
          </InvalidLogin>
        </div>
      </InputLoginContainer>

      <DisplayFlex justifyContent="space-between">
        <div>
          {/* <CheckBox
            id="remember"
            type="checkbox"
            data-test={loginStrings.rememberPassword.dataTest}
          />
          <LabelCheckBox
            onClick={chooseRememberPassword}
            dangerouslySetInnerHTML={{
              __html: loginStrings.rememberPassword.label,
            }}
            htmlFor="remember"
          /> */}
        </div>
        <ForgetPassword
          onClick={openCardForgetPassword}
          data-test={loginStrings.forgetPassword.dataTest}>
          {loginStrings.forgetPassword.label}
        </ForgetPassword>
      </DisplayFlex>

      <ButtonContainer>
        <ButtonWithRadiusBorderAndIcon
          buttonValues={loginStrings.loginButton}
          Icon={IcPlay}
          sendClick={setLogin}
        />
        <div className="signUpTop">
          <ButtonWithRadiusBorderAndIcon
            buttonValues={loginStrings.signUpButton}
            Icon={IcSignUp}
            sendClick={goToSignUp}
          />
        </div>
        <LabelDontHaveAccount
          onClick={goToSignUp}
          dangerouslySetInnerHTML={{
            __html: loginStrings.labelDontHaveAccount.label,
          }}
        />
      </ButtonContainer>
    </>
  )
}

LoginEmailCollunm.propTypes = {
  loginStrings: PropTypes.object.isRequired,
  isLoginCard: PropTypes.bool,
  closeLoginCard: PropTypes.func,
  showSignUpCard: PropTypes.func,
  handleLoading: PropTypes.func.isRequired,
}

LoginEmailCollunm.defaultProps = {
  isLoginCard: false,
}
