import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { isMobileSize } from '../../../../../common/utils'

import { characters } from '../../../../../game/characters'

import { chooseText } from '../../../../../common/strings'

import { CardBody, ContainerChooseButton } from '../../styles'

import { CharactersDetailsMobile, CharactersDetailsDesktop } from './characterDetails'
import { ButtonWithRadiusBorderComponent } from '../../../../../components/Buttons/buttonWithRadiusBorder'

export function Body() {
  const isMobile = useMemo(isMobileSize, [])
  const [indexCurrentCharacter, setIndexCurrentCharacter] = useState(isMobile ? 0 : null)
  const history = useHistory()
  const disableButton = React.useMemo(() => typeof indexCurrentCharacter !== 'number', [
    indexCurrentCharacter,
  ])

  const chooseCharacterData = {
    indexCurrentCharacter,
    previous: {
      isDisabled: indexCurrentCharacter <= 0,
      handleClick() {
        setIndexCurrentCharacter((current) => current - 1)
      },
    },
    next: {
      isDisabled: indexCurrentCharacter === characters.length - 1,
      handleClick() {
        setIndexCurrentCharacter((current) => current + 1)
      },
    },
    select(index) {
      return () => {
        setIndexCurrentCharacter(index)
      }
    },
  }

  function goToGame() {
    history.push({
      pathname: '/game',
      data: { indexCurrentCharacter },
    })
  }

  return (
    <CardBody isMobile={isMobile}>
      {isMobile ? (
        <CharactersDetailsMobile
          characters={characters}
          dataChooseCharacters={chooseCharacterData}
        />
      ) : (
        <CharactersDetailsDesktop
          characters={characters}
          dataChooseCharacters={chooseCharacterData}
        />
      )}

      <ContainerChooseButton>
        <ButtonWithRadiusBorderComponent
          disabled={disableButton}
          onClick={goToGame}
          text={chooseText}
          data_test="chooseCharacter"
        />
      </ContainerChooseButton>
    </CardBody>
  )
}
