import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { termsSignUp, okText, termsTextContent } from '../../../common/strings'
import { isMobileSize } from '../../../common/utils'

import { CardWithHeader } from '../../../components'
import { BackgroundBlur } from '../../../components/GameCards/styles'

import { Container, ContentCard, ContainerButton, TermsBox } from './styles'
import { Footer } from '../../../pages/ChoiceCharacter/CharactersCard/components'
import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'

export const CardTerms = ({ closeCardTerms }) => {
  const isMobile = useMemo(isMobileSize, [])

  return (
    <div>
      <Container>
        <CardWithHeader
          titleCard={termsSignUp}
          backgroundColorHeader="pistachioGreen"
          isSmall={isMobile}
          data_test="termsCard">
          <ContentCard>
            <TermsBox dangerouslySetInnerHTML={{ __html: termsTextContent }} />

            {closeCardTerms && (
              <ContainerButton>
                <ButtonWithRadiusBorderComponent
                  type="button"
                  data_test="goToGame"
                  onClick={closeCardTerms}
                  text={okText}
                />
              </ContainerButton>
            )}
          </ContentCard>
          <Footer />
        </CardWithHeader>
      </Container>
      {!isMobile && <BackgroundBlur />}
    </div>
  )
}

CardTerms.propTypes = {
  closeCardTerms: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.bool.isRequired,
  ]),
}
