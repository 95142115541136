import { config } from '../../../game/config'

const { employed } = config.player.conditions

export const superior = {
  challenge: [
    {
      level: 'superior',
      question:
        '<name> estudou muito para passar em uma universidade pública, mas infelizmente não conseguiu. A situação abalou os ânimos de <name> que está sem gás para recomeçar.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 10,
          feedback: 'Problemas fazem parte da vida! Foco no portal Z, <name>!',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question:
        '<name> estava indo para casa à noite, após a aula, quando furtaram sua mochila com o notebook e o dinheiro para o transporte do mês. <name> terá que buscar uma renda extra para recuperar o dinheiro e conseguir comprar um novo notebook.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: -30,
          feedback: 'Problemas fazem parte da vida! Foco no portal Z, <name>!',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question:
        '<name> criou uma conta no Instagram para dar dicas sobre estudo. Seus conteúdos vêm recebendo comentários positivos, porém <name> viu alguns comentários maldosos que abalaram sua autoconfiança. O que fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Focar nas melhorias e nos comentários positivos.',
          point: 40,
          feedback:
            'Ótima escolha! É muito importante filtrar os comentários, focar na melhoria do conteúdo e persistir.',
        },
        {
          response_text: 'Parar de postar conteúdos na rede social.',
          point: -20,
          feedback:
            'Que pena! <name> teve a iniciativa de criar um ótimo projeto, mas acabou desanimando em função de alguns comentários negativos. Faltou persistência e autoconfiança para seguir.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> está enfrentando problemas familiares e, por isso,  sente  angústia quanto pensa  no seu futuro. O que fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Segue sua rotina e procura ignorar seus sentimentos.',
          point: -20,
          feedback:
            'Ignorar os próprios sentimentos acabou agravando a situação de <name>.  Reconhecer suas dificuldades e buscar ajuda seria um ótimo começo!  ',
        },
        {
          response_text: 'Busca ajuda médica para ajustar a rotina da melhor forma.',
          point: 40,
          feedback:
            'Boa! <name> reconheceu suas dificuldades e buscou ajuda! Parabéns pela iniciativa.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> divide apartamento com uma amiga. Mas a amiga conseguiu uma bolsa de estudos em outro estado e terá que se mudar. <name> não consegue pagar todas as contas. E agora, o que fazer ?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Procura alguém para dividir o aluguel',
          point: 40,
          feedback: 'Ótima ideia! E a amiga ainda vai ajudar na divulgação.',
        },
        {
          response_text: 'Começa a dar aulas particulares para pagar o aluguel',
          point: 20,
          feedback:
            '<name> mostrou seu lado empreendedor, mas corre o risco de não conseguir o valor para todos os meses.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> começou o Ensino Superior e percebeu que vai precisar de dinheiro para pagar suas despesas. E agora?',
      type_question: 'decision_making',
      job_entry: true,
      options: [
        {
          response_text: 'Procura um emprego para pagar as contas e segue estudando',
          point: 20,
          feedback:
            '<name> conseguiu um estágio de meio período. Mandou bem na faculdade e ganhou elogios dos colegas de trabalho. <name> vai para o Mercado de Trabalho e acumula Xp para chegar ao Portal Z!! Boa',
          conditions: [employed],
        },
        {
          response_text: 'Pede ajuda da família e continua 100% com foco nos estudos ',
          point: 40,
          feedback:
            '<name> estudou em dobro e se envolveu em diversos projetos. Foi um semestre e tanto, e <name> garantiu mais Xp para chegar ao portal Z. Boa!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  knowledge: [
    {
      level: 'superior',
      question: 'Qual item abaixo é um exemplo de metáfora?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'A vida deles é um conto de fadas.',
          point: 50,
          feedback: 'Boa! Metáfora pode ser um recurso poderoso de comunicação.',
        },
        {
          response_text: 'Esta flor se parece com o sol.',
          point: -40,
          feedback:
            'Metáfora é uma figura de linguagem que produz sentidos figurados por meio de comparações, como “conto de fadas”. Saber usar isso é mais útil do que você pensa!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'O que é realidade virtual?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Robôs e computadores controlados por máquinas. ',
          point: -30,
          feedback:
            'Resposta incorreta. Realidade virtual é um ambiente criado por computador, no qual o usuário pode interagir como se fosse um mundo real.',
        },
        {
          response_text: 'Um mundo artificial e tridimensional criado por um computador.',
          point: 40,
          feedback:
            'Resposta Correta. Realidade virtual é um ambiente criado por computador, no qual o usuário pode interagir como se fosse um mundo real.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'Inteligência artificial é um ramo da ciência da computação que foca na:',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text:
            'Construção de computadores e máquinas capazes de simular comportamento inteligente.',
          point: 40,
          feedback:
            'Resposta correta! A inteligência artificial (IA) possibilita que máquinas aprendam com experiências e executem tarefas como seres humanos.',
        },
        {
          response_text: 'Democratização do conhecimento sobre informática. ',
          point: -30,
          feedback:
            'Resposta incorreta! Inteligência artificial é um ramo da ciência da computação que desenvolve máquinas capazes de simular comportamento inteligente.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'A palavra inglesa NETWORKING é utilizada no Brasil para descrever:',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'A habilidade digital de uma pessoa.',
          point: -30,
          feedback:
            'Resposta incorreta. Networking é o processo de interagir com pessoas para ampliar sua rede de relacionamento. ',
        },
        {
          response_text:
            'O processo de interagir com pessoas para trocar informações e desenvolver contatos pessoais ou profissionais. ',
          point: 40,
          feedback: 'Resposta correta!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'O que é plágio?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text:
            'Se apropriar de ideias ou textos de outras pessoas sem dar os devidos créditos.',
          point: 50,
          feedback: 'Boa! E não se esqueça: plágio é crime!',
        },
        {
          response_text: 'Publicar uma foto de uma pessoa sem sua autorização.',
          point: -40,
          feedback:
            'Plágio é o ato de usar ideias e textos de outras pessoas sem dar os devidos créditos. E não se esqueça: plágio é crime!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'Por que analisar dados e informações é tão importante no mundo atual?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text:
            'Porque selecionar e analisar as informações disponíveis ajuda a tomar decisões de forma mais eficiente',
          point: 50,
          feedback:
            'Boa! Você já sabe que tomar decisões simplesmente na base da intuição não é o caminho mais eficiente. E isso vale na vida e no trabalho!',
        },
        {
          response_text:
            'Porque a análise de dados te ajuda a programar e desenvolver sites',
          point: -40,
          feedback:
            'Análise de dados é um termo técnico usado para descrever o ato de selecionar e analisar as informações disponíveis.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'Qual item abaixo não é uma extensão de formato de imagem?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: '.png',
          point: -40,
          feedback:
            'Estas extensões podem parecer estranhas, mas formam a base do nosso mundo digital. HTML não é um tipo de imagem.',
        },
        {
          response_text: '.html',
          point: 50,
          feedback:
            'Parabéns! Você sabe que o mundo está ficando cada vez mais digital e não quer ficar para trás!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  skills: [
    {
      level: 'superior',
      question:
        'O colega de <name> está sendo vítima de preconceito no campus onde estuda. <name> resolve:',
      type_question: 'decision_making',
      options: [
        {
          response_text:
            'Aguardar seus colegas fazerem um movimento para poder se engajar.',
          point: -20,
          feedback:
            '<name> preferiu ficar em silêncio diante da situação e perdeu a chance de ser um agente da mudança, valorizando a diversidade.',
        },
        {
          response_text: 'Realizar um debate sobre o tema com a turma.',
          point: 40,
          feedback:
            'Parabéns pela iniciativa! <name> fez sua parte para diminuir o preconceito e valorizar a diversidade no campus.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> tem uma entrevista de emprego e quer muito conseguir a vaga, mas não preenche todos os requisitos. O que <name> faz?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Dá uma exagerada no currículo para preencher os requisitos.',
          point: -30,
          feedback: 'Opa! <name> se enrolou com as mentiras e acabou perdendo a vaga. ',
        },
        {
          response_text:
            'Destaca suas habilidades, experiências e demonstra motivação para aprender coisas novas. ',
          point: 40,
          feedback:
            '<name> conseguiu se sair bem na entrevista! Agora é torcer para o resultado ser positivo. ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> acaba de descobrir que o pré-vestibular gratuito está parado por falta de voluntários. Qual sua reação?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Se preocupa com os jovens que não têm outra alternativa. ',
          point: 10,
          feedback:
            'Empatia é importante, mas <name> poderia ter partido para ação e ajudado a mudar esta história. ',
        },
        {
          response_text:
            'Se oferece para participar e faz uma campanha entre os amigos para aumentar o time de voluntários.',
          point: 30,
          feedback:
            'Boa! <name> teve empatia e iniciativa, e ainda ganhou mais experiência! ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        'O semestre está sendo difícil. <name> não conseguiu terminar os trabalhos, perdeu uma ótima oportunidade de estágio e seu projeto recebeu duras críticas do professor.  Como <name> reage?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Começa a duvidar de si, mas prefere não falar com ninguém.',
          point: -20,
          feedback:
            'Quem nunca teve dúvidas ou perdeu a confiança em si mesmo?! Não existe uma receita única para enfrentar esta situação. Mas ignorar o problema certamente não ajuda! ',
        },
        {
          response_text:
            'Analisa seus erros e acertos, e pede conselhos a amigos e professores.',
          point: 40,
          feedback:
            'Quem nunca teve dúvidas ou perdeu a confiança em si mesmo?! Não existe uma receita única para enfrentar esta situação. Mas <name> decidiu encarar de frente e pedir ajuda, o que é um ótimo começo!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> está com dúvidas sobre a carreira, e resolveu conversar com um profissional da área que aconselhou <name> a pensar nos seus objetivos e fazer uma lista de CONHECIMENTOS e HABILIDADES importantes para conquistá-los. ',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 30,
          feedback: 'Pontos para <name> que está se planejando para chegar ao Portal Z!',
        },
      ],
      has_interaction: false,
      complete: false,
    },
    {
      level: 'superior',
      question:
        '<name> ganhou uma bolsa de pesquisa na universidade! A experiência foi incrível e <name> aprendeu na prática como a análise de dados pode ajudar a tomar decisões de forma mais inteligente. ',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 50,
          feedback: 'Boa, <name>! Esta habilidade vale ouro no Portal Z!',
        },
      ],
      has_interaction: false,
      complete: false,
    },
    {
      level: 'superior',
      question:
        '<name> perdeu o controle durante uma live e acabou maltratando o apresentador nos comentários. Se quiser chegar ao Portal Z, <name> vai ter que aprender a lidar com suas emoções e a respeitar ideias diferentes.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: -40,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question:
        '<name> criou uma proposta de startup para o pitch da Universidade. Os jurados acharam a ideia genial, mas apontaram algumas falhas no modelo de negócio. <name> resolve:',
      type_question: 'decision_making',
      options: [
        {
          response_text:
            'Procurar um parceiro com conhecimento em finanças para melhorar a proposta.',
          point: 40,
          feedback:
            'Boa! <name> analisou a situação e identificou o que faltava para melhorar sua proposta. Parabéns pela persistência!',
        },
        {
          response_text: 'Desistir da ideia e partir para outra.',
          point: -30,
          feedback:
            'Poxa! A ideia de <name> era muito boa e perdeu a oportunidade de melhorá-la. Persistência e capacidade de aprender são peças-chave para chegar ao Portal Z.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  inspiration: [
    {
      level: 'superior',
      question: '<b>Lia Zakiyyah</b><br/>Empreeendedora e ex aluna JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Buscar uma carreira é uma jornada para toda a vida. Não termina com o primeiro emprego ou com a primeira carreira. <b>Persiga o que você acredita e o que é bom durante toda a vida</b>"',
          point: 50,
          feedback: '',
        },
      ],
      has_interaction: false,
      complete: false,
    },
    {
      level: 'superior',
      question: '<b>Cosmin Malureanu</b><br/>Empreeendedora e ex aluna JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“<b>Aprenda a como aprender</b>. O mundo está mudando num <b>ritmo acelerado</b>. Você precisa evitar ficar parado num emprego ou carreira que não tem futuro. Tem que desafiar a si mesmo a <b>aprender coisas novas</b> e permanecer competitivo.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: '<b>Joseph Fortuno</b><br/>Ex-aluno JA EUA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Tenho apenas um conselho: Não tenha medo de tentar, nem mesmo de falhar. Se eu tivesse tido muito medo eu nunca teria chegado onde estou hoje."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: '<b>Youmn mazhzoul</b><br/>Ex-aluna JA Marrrocos',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Vamos pensar sobre o conceito do que é ser bem sucedido. A métrica que usamos para medir o sucesso muitas vezes está distorcida, pois só leva em conta o êxito financeiro. Embora seja importante, a humanidade deveria se empenhar mais em ter uma vida com significado."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: '<b>Jeff Hansberry</b><br/>Ex-aluno JA EUA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Você tem que acreditar que existe uma vida diferente para  além de  um raio de três ou quatro quarteirões de onde você mora, ou além das pessoas que conhece, ou das experiências  que  vivencia agora. Existe algo melhor para você além do mundo que vive hoje."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question:
        '<b>Joshuel Plasencia</b><br/>Cofundador do Projeto 99 e ex-aluno da JA EUA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Dar e receber <b>feedback</b> é uma dádiva, pois não existe essa de ser o melhor em algo sem perspectiva de melhorias. O <b>aperfeiçoamento</b> só é possível através dos feedbacks dados e recebidos."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: '<b>Leen Abu Baker</b><br/>Empreendedora e ex aluna da JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Comece a <b>buscar seus  interesses</b> ainda jovem e não tenha medo de <b>compartilhá-los</b> com a comunidade que o cerca. Acredite sempre em si mesmo e espalhe <b>energia positiva</b> à sua volta. E lembre-se sempre de que fracassos são apenas o começo do sucesso."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: '<b>Yesenia Cardenas</b><br/>Empreendedora e ex-aluna JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Se você tiver um <b>objetivo</b> ou um <b>sonho</b> que quer ver realizados, não importa se é se tornar advogado, abrir um negócio ou ter uma família, <b>haverá obstáculos</b> e pessoas que vão questionar os seus sonhos. É muito importante <b>manter-se fiel</b> a seus objetivos e desejos e <b>trabalhar</b> duro para perseverar.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: '<b>Bob Coughlin</b><br/>Empreendedor e ex-aluno JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Entenda o seus pontos fortes e cerque-se de pessoas que podem ajudá-lo a superar suas fraquezas.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: '<b>Mario A. Escutia</b><br/>Empreendedor e ex-aluno JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Meu conselho para os jovens é: não importa <b>de onde você veio</b>, qual é a sua classe social ou sua ideologia, <b>seus sonhos podem sim se tornar realidade.</b> Honestidade e trabalho duro sempre são recompensados.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
  ],
  job: [
    {
      level: 'superior',
      question:
        '<name> recebeu a tarefa de criar uma apresentação para o lançamento do novo produto. Usou a criatividade e surpreendeu a empresa! Ainda bem tinha as habilidades para fazer a tarefa, né?',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 20,
          feedback: '<name> recebe e vamos para a próxima carta!',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question:
        'A empresa que <name> trabalha fez um sorteio e <name> ganhou uma bolsa para um curso de especialização!',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 30,
          feedback: '<name> recebe e vamos para a próxima carta!',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question:
        '<name> está em semana de provas na faculdade e também está com muitas demandas no trabalho. Essa semana <name> não conseguiu dormir, nem se alimentar direito.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: -20,
          feedback: '<name> se recupera e vamos para a próxima carta!',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question:
        'Um cliente internacional irá visitar a empresa neste mês e <name> recebeu o convite para acompanhar a reunião. Porém, não se sente confortável para participar em outro idioma. O que fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Participar da reunião.',
          point: 30,
          feedback:
            '<name> conversou com sua liderança e participou como ouvinte da reunião. Mesmo não compreendendo 100% do assunto, essa experiência fez <name> valorizar mais as aulas de idiomas estrangeiros.',
        },
        {
          response_text: 'Faltar no dia da reunião para não passar vexame. ',
          point: -30,
          feedback:
            'Todos sentiram falta de <name> na reunião e sua ausência injustificada fez com que a liderança perdesse confiança no seu trabalho. ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> está no estágio e recebe uma proposta fora da sua área de interesse para trabalhar em horário integral. O que <name> vai fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Continuar no estágio, ganhando apenas a bolsa auxílio',
          point: 30,
          feedback:
            'Difícil essa, né? <name> preferiu investir na sua área de interesse e ter mais tempo para o estudo.',
        },
        {
          response_text: 'Aceitar o emprego, ganhando salário integral.',
          point: 10,
          feedback:
            'Difícil essa, né? Apesar de ganhar bem, o trabalho diminuiu o tempo de estudo.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question:
        '<name> precisa melhorar sua renda para morar mais perto da faculdade. O que fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Conversar com o seu chefe sobre a possibilidade de um aumento',
          point: 20,
          feedback:
            '<name> tentou negociar, mas infelizmente o chefe não tinha orçamento. <name> aprendeu sobre negociação e resiliência.',
        },
        {
          response_text: 'Vender sanduíches para ter uma renda extra.',
          point: 20,
          feedback:
            'Essa é uma boa ideia para ter uma renda extra, mas <name> terá que se esforçar para não prejudicar os estudos.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
}
