import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  feedbackText,
  feedbackInfo,
  disagreeText,
  agreeText,
  jumpText,
  sendText,
} from '../../../common/strings'

import { CardWithHeader } from '../cardWithHeader'
import { BackgroundBlur } from '../../GameCards/styles'

import {
  Container,
  ContentCard,
  ButtonContainer,
  FeedbackInfo,
  QuestionFeedback,
} from './styles'
import { RatingStar } from './Rating'
import { SimpleButton } from '../cardWithFlag/styles'
import { getFeedbackForm, setFeedback } from '../../../services/API/feedback'
import { get } from '../../../services/storage'
import { Loading } from '../../Loading'
import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'
import { reportAnsweredFeedback } from '../../../services/analytics'

export const FeedbackCard = ({ closeFeedbackCard, onCancelClick }) => {
  const [stepTwo, setStepTwo] = useState([
    {
      questions: [],
      _id: 0,
    },
  ])

  const [questionStep, setQuestionStep] = useState({
    questions: [],
    _id: 0,
  })

  const [answers, setAnswers] = useState([])
  const [numberStep, setNumberStep] = useState(1)
  const [showLoading, setShowLoading] = useState(false)
  const [formId, setFormId] = useState(0)

  useEffect(getFeedback, [])
  function getFeedback() {
    getFeedbackQuestions()
  }

  async function getFeedbackQuestions() {
    setShowLoading(true)
    const { data } = await getFeedbackForm()
    setShowLoading(false)
    if (data.length > 0) {
      setFormId(data[0]._id)
      setQuestionStep(data[0].steps[0])
      setStepTwo(data[0].steps[1])
    } else {
      closeFeedbackCard()
    }
  }

  function setNewStep() {
    setQuestionStep(stepTwo)
  }

  function setSelectedRating(value) {
    const answersAux = [...answers]
    const a = answers.findIndex((item) => item.question === value.question)

    if (a >= 0) {
      answersAux[a] = value
    } else {
      answersAux.push(value)
    }

    setAnswers(answersAux)
  }

  function goToLandingPage() {
    window.location.href = 'http://gamevuca.org.br/'
    return null
  }

  async function sendFeedBack(finalFunction) {
    const user = get('user')
    const data = {
      user: user?.id || '',
      feedbackForm: formId,
      answers: answers,
    }

    setShowLoading(true)
    await setFeedback(data)
    setShowLoading(false)
    setAnswers([])

    finalFunction()
  }

  function nextStep() {
    setNumberStep(2)
  }

  function handleClickSendButton() {
    if (numberStep === 1) {
      setNewStep()
      reportAnsweredFeedback('part1')
      sendFeedBack(nextStep)
    } else {
      reportAnsweredFeedback('part2')
      sendFeedBack(closeFeedbackCard)
    }
  }

  function checkAnswer() {
    if (!answers.length) {
      return true
    } else {
      return questionStep.questions.length !== answers.length
    }
  }

  return (
    <>
      {showLoading && <Loading />}
      <Container>
        <div className="FeedbackCard">
          <CardWithHeader
            titleCard={feedbackText}
            handleClickCloseButton={goToLandingPage}
            withCloseButton={true}
            withFooter={false}
            data_test="FeedbackCard">
            <ContentCard>
              {numberStep === 2 && <FeedbackInfo>{feedbackInfo}</FeedbackInfo>}
              {questionStep.questions.map(({ body, _id }, index) => {
                return (
                  <>
                    <QuestionFeedback
                      dangerouslySetInnerHTML={{ __html: body.ptBr }}
                      data-test="questionFeedback"
                    />
                    <RatingStar
                      sendValueSeletedRating={setSelectedRating}
                      key={_id}
                      name={_id}
                      body={body}
                      minText={disagreeText}
                      maxText={agreeText}
                      isLast={index + 1 === questionStep.questions.length}
                    />
                  </>
                )
              })}

              <ButtonContainer>
                <SimpleButton
                  data-test="pullButton"
                  color="white"
                  type="button"
                  onClick={onCancelClick}>
                  {jumpText}
                </SimpleButton>
                <ButtonWithRadiusBorderComponent
                  data_test="sendButton"
                  type="button"
                  disabled={checkAnswer()}
                  onClick={handleClickSendButton}
                  text={sendText}
                />
              </ButtonContainer>
            </ContentCard>
          </CardWithHeader>
        </div>
      </Container>
      <BackgroundBlur />
    </>
  )
}

FeedbackCard.propTypes = {
  closeFeedbackCard: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
}

FeedbackCard.defaultProps = {}
