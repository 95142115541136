import { config } from '../../config'

const { employed } = config.player.conditions

export const junior = {
  challenge: [
    {
      level: 'junior',
      question:
        '<name> teve um ano difícil, com muitos problemas familiares, e acabou sendo reprovada na escola. Problemas podem tirar qualquer um do sério,  mas fazem parte da vida. <name> deixou de ganhar alguns xp, mas está pronta para enfrentar o novo ano com foco e determinação!',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: -10,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question:
        '<name> caiu na conversa de seus colegas de que a escola é uma perda de tempo e acabou se dedicando pouco aos estudos. <br/>Agora <name> está sentindo dificuldade para concluir as matérias.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: -10,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question:
        '<name> não está conseguindo acompanhar e compreender o conteúdo das aulas de matemática e português. <name> sente vontade de:',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Largar a escola e voltar ano que vem.',
          point: -20,
          feedback:
            'Desistir dos estudos nunca é uma boa opção. <name> segue estudando, mas ainda sente dificuldade.',
        },
        {
          response_text: 'Fazer um esforço extra e pedir ajuda.',
          point: 30,
          feedback:
            'Muito bem! Seus esforços não foram suficientes para ser aprovado em matemática, mas por outro lado <name> desenvolveu resiliência e isso foi muito importante nos outros desafios da vida.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        'Um grupo de estudantes implicou com o cabelo da <name> e ir à escola virou um drama. E agora? O que fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Aguenta firme até o ano terminar.',
          point: 10,
          feedback:
            'Ficar calado não ajudou em nada. O semestre foi bem difícil e <name> ganhou pouco xp.',
        },
        {
          response_text: 'Comunica aos pais e a diretoria.​',
          point: 50,
          feedback:
            'Parabéns pela coragem! Boa! A escola descobriu que <name> não era a única vítima. Graças à sua atitude, a escola organizou uma campanha anti-bullying e o clima melhorou para todos!​',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        'A escola de <name> está promovendo debates sobre empreendedorismo e educação financeira como parte extra curricular.',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Se inscreve para participar.',
          point: 20,
          feedback:
            'Mesmo sem curtir muito esse assunto <name> participou dos debates a percebeu que pode aprender a gostar de novos temas. Boa!',
        },
        {
          response_text: 'Não curte muito esses temas.​',
          point: -10,
          feedback:
            'Poxa! Esses temas podem ajudar bastante na vida de <name>. Quem sabe na próxima né?!​',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        'Os pais de <name> se separaram e quem pagava as contas era o pai. Com a separação, <name> fica pensando se...',
      type_question: 'decision_making',
      job_entry: true,
      options: [
        {
          response_text: 'Começa a vender doces com a mãe.',
          point: 10,
          feedback:
            'Decisão difícil, né? Esta será a primeira experiência profissional de <name>, e agora terá que equilibrar os estudos com as novas obrigações para conquistar xp e chegar ao Portal Z.',
          conditions: [employed],
        },
        {
          response_text:
            'Continua foco exclusivamente nos estudos, mesmo sabendo que o padrão de vida da família irá diminuir.​',
          point: 50,
          feedback:
            'A decisão foi difícil, mas <name> percebeu como a falta do ensino básico limitou as opções da família, e resolveu focar nos estudos pra chegar mais perto do Portal Z.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  knowledge: [
    {
      level: 'junior',
      question: 'O que fez a princesa Isabel? ​',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Decretou o fim da escravidão.',
          point: 30,
          feedback: 'Resposta certa! ​A princesa Isabel assinou a Lei Áurea em 1888.',
        },
        {
          response_text: 'Declarou guerra a Portugal.',
          point: -10,
          feedback:
            'Ops! Resposta errada. ​A princesa Isabel decretou o fim da escravidão em 1888.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        'Quando Cristóvão Colombo saiu da Espanha, em 1492, o que ele esperava encontrar?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Índia',
          point: 30,
          feedback:
            'Boa! Colombo descobriu a América em 1492 , mas estava na verdade em busca do caminho para a Índia, a terra do ouro e das especiarias.',
        },
        {
          response_text: 'América',
          point: -10,
          feedback:
            'Opa, resposta errada! Colombo descobriu a América em 1492, mas estava na verdade em busca do caminho das Índias, a terra do ouro e das especiarias.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question: 'Qual o antônimo de artificial?​',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Natural',
          point: 30,
          feedback:
            'Resposta certa! Parabéns! Saber usar as palavras de forma eficiente é fundamental para se comunicar bem!',
        },
        {
          response_text: 'Simples',
          point: -10,
          feedback:
            'Ops! não foi dessa vez! O antônimo (ou contrário) de Artificial é Natural. Aprender a usar as palavras de forma eficiente é fundamental para se comunicar bem.​',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question: 'Ao analisar um site na Internet você deve:​',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Confiar em qualquer site, desde que apareça no Google.',
          point: -10,
          feedback:
            'Opa! Só porque o texto aparece no Google não significa que está correto ou apresenta uma visão imparcial sobre o assunto. É importante analisar criticamente o conteúdo e a fonte! ',
        },
        {
          response_text: 'Analisar criticamente o conteúdo e a fonte. ',
          point: 30,
          feedback:
            'Parabéns! Você sabe que qualquer pessoa pode publicar na Internet, e é importante verificar a fonte e analisar o conteúdo criticamente. ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question: 'Qual a capital do Ceará?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Recife',
          point: -10,
          feedback: 'Não foi desta vez! A capital do Ceará é Fortaleza.',
        },
        {
          response_text: 'Fortaleza',
          point: 30,
          feedback: 'Isso aí! A capital do Ceará é Fortaleza. ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question: 'Quais os elementos químicos mais presentes no corpo humano?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Oxigênio, carbono e hidrogênio',
          point: 30,
          feedback: 'Parabéns! Importante entender como nosso organismo funciona!',
        },
        {
          response_text: 'Oxigênio, carbono e sódio',
          point: -10,
          feedback:
            'Não foi desta vez! Nós também temos sódio no corpo, mas em proporções muito menores, o correto é hidrogênio.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question: 'Qual valor de x na equação <br/>  8x + 16 = 24',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'x=3',
          point: -10,
          feedback: 'Resposta errada! O x nesta equação é igual a 1.',
        },
        {
          response_text: 'x=1',
          point: 30,
          feedback: 'Resposta correta!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question: 'A floresta está para a árvore assim como a árvore está para...',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Solo.',
          point: -10,
          feedback:
            'Esta é uma questão de lógica. A floresta está para a árvore assim como a árvore está para a folha.',
        },
        {
          response_text: 'Folha.',
          point: 30,
          feedback: 'Parabéns, você usou a lógica para resolver a questão!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  inspiration: [
    {
      level: 'junior',
      question: '<b>Eleonora Arifova</b><br/>Empreendedora e ex aluna da JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"O <b>empreendedorismo</b> é aplicável a qualquer caminho que se escolha na vida. A aptidão para identificar <b>oportunidades</b> e implementar ideias pode beneficiar qualquer pessoa. Portanto, <b>liberte seu talento</b> empreendedor e siga seus sonhos.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question:
        '<b>Murillo Sabino</b><br/>Fundador do projeto RUAS<br/>Ex-aluno JA Brasil',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Não <b>“busquem”</b> o seu propósito. <b>Cultivem um propósito</b>. Ele será a  união entre o que atrai sua curiosidade, o que você  faz muito bem e do que <b>o mundo está precisando</b> para ser um lugar melhor."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question: '<b>Jerome Cowans</b><br/>Membro do Jamaica House Fellowship',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“O que eu aprendi e que considero crucial para todos os jovens foi: durante toda a sua vida será essencial <b>compreender o lado prático</b> do que está fazendo, assim como o lado teórico.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question:
        '<b>Leen Abu Baker</b><br/>Vice-presidente do Conselho de Ex-alunos da INJAZ Al-Arab',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Comece a buscar <b>seus interesses</b> ainda jovem e não tenha medo de <b>compartilhá-los</b> com a comunidade que o cerca.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question: '<b>Bill Herp</b><br/>Ex aluno da JA e fundador da Linear Air',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Você definitivamente <b>precisa estudar</b>. Não pode achar que vai ser um empreendedor ou ter um emprego decente <b>sem instrução</b>. E isso não significa necessariamente cursar uma universidade; pode ser uma instrução <b>técnica</b> ou <b>profissionalizante</b>. O importante é que lhe permita tornar-se um especialista em uma ou múltiplas áreas.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question: '<b>Donna Shalala</b><br/>Ex aluna JA e executiva',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Minha filosofia de educação é: os estudantes não devem se preparar para o seu <b>primeiro emprego</b>, mas para seu terceiro, quarto e quinto emprego. Precisam ter a capacidade mental de assimilar <b>novas tecnologias</b> e <b>novas ideias</b>. Assim, é muito importante que os jovens se vejam como eternos aprendizes.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question: '<b>Adedayo Fashanu</b><br/>Ex aluna JA e Jornalista ',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“<b>Confiança</b> é a chave para o progresso, mas não baseiem sua confiança unicamente nos resultados que alcançarem, porque o que vai acontecer quando você falhar em alguma coisa? <b>Seria motivo para perder a confiança em si mesmo?</b> Não, sua confiança deve <b>ter raízes</b> na ‘causa maior’ do trabalho no qual você está imerso e no processo positivo de <b>realizar</b> esse trabalho.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question: '<b>Bonnie Chiu</b><br/>Ex-aluna da JA Hong Kong',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Comece sempre pelo “<b>por quê?</b>”. É a pergunta mais importante a ser  feita na construção de <b>sua vida</b> e de <b>sua carreira:</b> por que estou <b>fazendo</b> isso? por que me <b>importo</b> com isso? por que isso é <b>relevante</b> pra mim?"',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question: '<b>Raymon Setiadi</b><br/>Empreendedor e ex-aluno JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Nunca pense que você <b>sabe tudo</b> e nunca se sinta muito <b>confortável</b> com a sua situação. Porque quando você pensa dessa maneira, você não dá a si mesmo nenhuma <b>oportunidade de crescimento.</b>”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question: '<b>Camilla ljunggren</b><br/>Empreendedor e ex-aluno JA ',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '”Seja lá o que <b>optar por fazer</b>, você tem que saber que vai <b>demorar</b>. Contar como construí nossa fábrica leva 10 minutos e parece ter sido muito fácil. Mas não é fácil. <b>Leva tempo.</b> No meu caso foram 16 a 17 anos.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
  ],
  skills: [
    {
      level: 'junior',
      question:
        'A professora organizou a turma em grupos e <name> terá que fazer o trabalho com colegas que não conhece. <name> resolve:​',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Fazer o trabalho individualmente e enviar para o grupo.',
          point: -30,
          feedback:
            '<name> perdeu uma ótima oportunidade de trabalhar em equipe. Esta é uma daquelas habilidades essenciais que não se aprende nos livros, mas sim praticando!',
        },
        {
          response_text: 'Fazer uma reunião para organizar o trabalho.',
          point: 50,
          feedback:
            'Boa iniciativa. <name> aprendeu muito sobre colaboração e organização. Apesar de algumas dificuldades iniciais, o trabalho ficou ótimo e <name> fez novos amigos.​',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        'O cachorro destruiu a maquete que <name> passou 1 mês preparando para a Feira de Inovação da escola. Faltam 24 horas para a apresentação, o que fazer?​',
      type_question: 'decision_making',
      options: [
        {
          response_text:
            'Cancelar sua participação e se preparar para a Feira do ano que vem.',
          point: -20,
          feedback:
            'Pena, a ideia era perfeita e <name> poderia ter apresentado de outra forma. Flexibilidade e criatividade valem ouro no Mundo VUCA!',
        },
        {
          response_text: 'Adaptar a apresentação usando outros recursos.',
          point: 30,
          feedback:
            '<name> usou desenhos e uma  bela história para apresentar sua ideia. Flexibilidade e criatividade para resolver problemas vão ajudar <name> a chegar ao Portal Z! ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        '<name> se elegeu como representante de turma, mas está com dificuldades de cumprir suas tarefas. Decide então:',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Respirar fundo e torcer para o ano terminar logo.',
          point: -20,
          feedback:
            'Administrar o tempo é um dos grandes desafios do Mundo VUCA. Há muitas estratégias para isso, como priorizar as tarefas mais urgentes ou pedir ajuda. ',
        },
        {
          response_text: 'Escolher as prioridades e definir prazos para cada tarefa.',
          point: 30,
          feedback:
            'Administrar o tempo é um dos grandes desafios do Mundo VUCA. Há muitas estratégias para isso, inclusive priorizar as tarefas mais urgentes ou pedir ajuda. ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        '<name> tem que escrever uma redação para a escola, mas não sabe por onde começar. <name> resolve:',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Pesquisar na Internet e copiar parte de um texto de um blog.',
          point: -20,
          feedback:
            'Opa! Copiar ideias de outras pessoa sem crédito é plágio. E plágio é crime!',
        },
        {
          response_text: 'Pesquisar sobre o tema na Internet para se inspirar.',
          point: 30,
          feedback:
            'Boa! <name> levantou dados e encontrou referências para criar sua própria história.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question: 'A feira de ciências da escola é mês que vem. <name> resolve…​',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Trabalhar na organização.',
          point: 30,
          feedback:
            '<name> mostrou iniciativa e trabalhou pesado para organizar a feira! A lista de coisas que ela aprendeu nem cabe aqui. A feira foi um sucesso, e uma experiência para vida toda!',
        },
        {
          response_text: 'Visitar a feira com seus amigos.',
          point: 10,
          feedback:
            '​<name> não quis organizar, mas aproveitou a experiência e aprendeu mais sobre o mundo!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        '<name> saiu confiante do teste de matemática, mas quando a professora entregou as notas descobriu que não tinha ido tão bem. Como reagir?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Fica triste, rasga o teste e esquece o assunto.',
          point: -30,
          feedback:
            '<name> perdeu uma ótima chance de aprender com os erros. Se tivesse revisado as questões teria percebido que seu problema foi a interpretação das questões. Que pena!',
        },
        {
          response_text: 'Fica triste e procura a professora para falar sobre o teste.',
          point: 30,
          feedback:
            '​Depois da conversa com a professora, <name> percebeu que não tinha lido as questões direito. Aprendeu com os erros e  já sabe o que precisa melhorar para a próxima.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  job: [
    {
      level: 'junior',
      question:
        'Hoje foi dia de feedback sobre seu trabalho. A mãe de <name> falou sobre suas habilidades no trabalho e fez algumas sugestões de melhoria.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text:
            'Conhecer suas habilidades e fraquezas é peça chave para a <name> chegar ao Portal Z!',
          point: 30,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question:
        '<name> não prestou atenção na receita que sua mãe lhe ensinou e estragou uma leva de doces.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'Mais atenção na próxima!',
          point: -20,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question:
        '<name> visitou uma startup que desenvolve sites com a escola, conheceu toda a estrutura e descobriu profissões que não conhecia. Uhuul!!!',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '<name> aproveitou a oportunidade e ganhou mais XP.',
          point: 20,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'junior',
      question:
        'A mãe de <name> recebeu uma encomenda grande de doces e  vai precisar de ajuda extra no final de semana. Mas <name> tem muita coisa para preparar para a escola. O que resolve fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Fazer as tarefas rapidinho para ficar livre.',
          point: -10,
          feedback:
            'Conciliar trabalho e estudo é um desafio. <name> entregou as tarefas de qualquer jeito e não aprendeu nada. Se quiser chegar ao Portal Z <name> terá que se organizar!',
        },
        {
          response_text:
            'Organizar seus horários para terminar as tarefas da escola durante a semana.',
          point: 30,
          feedback:
            '<name> cortou TV e celular durante a semana e focou nas tarefas da escola. Foi uma semana pesada, mas <name> conseguiu ajudar sua mãe e ficou com aquela sensação de dever cumprido!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        'Um dos clientes não pagou no dia combinado pois está sem dinheiro. E agora?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Assume o prejuízo, porém não vende mais para esse cliente.',
          point: -10,
          feedback:
            'Uma pena, mas isso pode acontecer com todo mundo. <name> poderia ter tentado negociar antes de cortar a relação com o cliente.',
        },
        {
          response_text: 'Negocia com o cliente para receber parcelado.',
          point: 30,
          feedback:
            'Sua iniciativa garantiu o recebimento do pedido e ainda manteve a boa relação com o cliente!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'junior',
      question:
        'Um colega da escola também começou a vender doces e agora <name> tem um concorrente. Como lidar com a situação?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Combina com o colega de vender doces diferentes. ',
          point: 30,
          feedback:
            'Boa escolha! Agora toda a escola tem mais opções para comprar e <name> garantiu a clientela. ',
        },
        {
          response_text: 'Espalha boatos negativos sobre os doces do colega.',
          point: -10,
          feedback:
            'Poxa! As pessoas perceberam que isso não era verdade e as vendas de doces de <name> caíram. ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
}
