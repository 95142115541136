import React from 'react'

import { Card } from '../Cards'
import { BackgroundBlur } from '../GameCards/styles'

import { Container, BodyCard } from './styles'

export const Loading = () => (
  <Container>
    <Card isSmall data_test="LoadingCard">
      <BodyCard>
        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_fptpaeac.json"
          background="transparent"
          speed="1"
          loop
          autoplay></lottie-player>
      </BodyCard>
    </Card>
    <BackgroundBlur />
  </Container>
)
