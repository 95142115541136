import styled, { css } from 'styled-components'

export const InvalidLogin = styled.div`
  margin-bottom: 20px;
  padding-left: 25px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bolder;
  line-height: 1.67;
  letter-spacing: -0.05px;
  color: ${({ theme }) => theme.colors.orange};
  transition: all 2s;
  display: none;

  ${({ isValidLogin }) =>
    typeof isValidLogin === 'boolean' &&
    !isValidLogin &&
    css`
      display: block;
    `}
`

export const InputLoginContainer = styled.div`
  display: grid;
  gap: 10px;
  padding-top: 10px;
`

export const LabelDontHaveAccount = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    height: 34px;
    cursor: pointer;
    font-family: MilkyNice;
    font-size: 14px;
    letter-spacing: -0.06px;
    text-align: center;
    font-weight: bold;
    padding-top: 20px;

    b {
      color: ${({ theme }) => theme.colors.EmeraldGreen};
    }
  }
`

export const DisplayFlex = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: 10px;
`

export const ForgetPassword = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.EmeraldGreen};
  width: 162px;
  height: 20px;
  font-family: MilkyNice;
  font-size: 14px;
  padding-top: 8px;
  cursor: pointer;
  text-align: right;
  letter-spacing: -0.06px;
`
