import React, { useEffect, useMemo, useState } from 'react'

import { isMobileSize } from '../../common/utils'
import {
  confirmDeleteGameParagraph,
  confirmNewGameParagraph,
  deleteGame,
  deleteText,
  newGame,
  savedGameTitle,
} from '../../common/strings'

import {
  Container,
  ContainerHasntSavedGame,
  ButtonContainer,
  ContainerHasSavedGame,
  CardSavedGame,
  ButtonSavedGameContainer,
  ContainerButtonNewGame,
  Body,
} from './styles'

import {
  CardWithHeader,
  CircularButtonComponent,
  Menu,
  ProgressBar,
} from '../../components'
import { VucaLogoWithDescription, LogoVucaNewGame } from '../../assets/img'
import { useHistory } from 'react-router-dom'
import { ButtonWithRadiusBorderComponent } from '../../components/Buttons/buttonWithRadiusBorder/'
import { useStore } from '../../store/hooks'
import { IcPlay } from '../../assets/img/buttonIcons'

import { getGame } from '../../services/API/game'
import { types } from '../../store'
import { ConfirmInfoCard } from '../../components/Cards/ConfirmInfoCard'
import { clear, set } from '../../services/storage'
import { Loading } from '../../components/Loading'
import { reportContinueGameProgress } from '../../services/analytics'

export const Home = () => {
  const [showLoading, setShowLoading] = useState(false)
  const [showConfirmNewGameCard, setShowConfirmNewGameCard] = useState(false)
  const [showConfirmDeleteGame, setShowConfirmDeleteGame] = useState(false)
  const isMobile = useMemo(isMobileSize, [])
  const history = useHistory()

  const { store, dispatch } = useStore()
  const { G, auth_token } = store

  const [hasSavedGame, setHasSavedGame] = useState(false)

  useEffect(checkSavedGame, [G])
  function checkSavedGame() {
    const hasSavedProgress = G.progress < 1000 && G.turnCounter < 45
    setHasSavedGame(hasSavedProgress)
  }

  useEffect(getSavedGame, [auth_token])
  function getSavedGame() {
    const SavedGame = async () => {
      setShowLoading(true)
      const response = await getGame(store)
      setShowLoading(false)
      if (response && response.data && response.data.game) {
        dispatch({ type: types.SET_G, payload: response.data.game })
      }
    }

    SavedGame()
  }

  function goToGame() {
    if (hasSavedGame) {
      handleNewGameClick()
    } else {
      clear('gameState')
      setHasSavedGame(false)
    }

    history.push('/start')
  }

  function deleteSavedGame() {
    setHasSavedGame(false)
    setShowConfirmDeleteGame(!showConfirmDeleteGame)
  }

  function handleNewGameClick() {
    setShowConfirmNewGameCard(!showConfirmNewGameCard)
  }

  function handleDeleteGameClick() {
    setShowConfirmDeleteGame(!showConfirmDeleteGame)
  }

  function continueGame() {
    reportContinueGameProgress()
    set('gameState', { G: G })
    history.push('/game')
  }

  return (
    <>
      {showLoading && <Loading />}
      <Container data-test="HomeContainer" hasSavedGame={hasSavedGame}>
        <Menu />
        {!hasSavedGame && (
          <ContainerHasntSavedGame>
            <figure>
              {isMobile ? (
                <VucaLogoWithDescription width="236px" height="70px" />
              ) : (
                <LogoVucaNewGame width="353px" height="149px" />
              )}
            </figure>
            <ButtonContainer>
              <ButtonWithRadiusBorderComponent
                onClick={goToGame}
                data_test="buttonHomeNewGame"
                text={newGame}
              />
            </ButtonContainer>
          </ContainerHasntSavedGame>
        )}

        {hasSavedGame && G.maxTurns && (
          <ContainerHasSavedGame>
            <figure>
              {isMobile ? (
                <VucaLogoWithDescription />
              ) : (
                <VucaLogoWithDescription width="700px" height="200px" />
              )}
            </figure>
            <CardSavedGame>
              <CardWithHeader
                backgroundColorHeader="pistachioGreen"
                titleCard={'Jogo salvo'}
                data_test="deleteAccountCard">
                <Body>
                  <ProgressBar isHome G={G} />
                  <ButtonSavedGameContainer>
                    <CircularButtonComponent
                      data_test="menuButtonMobile"
                      onClick={continueGame}>
                      <IcPlay width="16" height="16" />
                    </CircularButtonComponent>
                    {/* <CircularButtonComponent
                      color="white"
                      data_test="menuButtonMobile"
                      onClick={handleDeleteGameClick}>
                      <IcDelete width="16" height="16" />
                    </CircularButtonComponent> */}
                  </ButtonSavedGameContainer>
                </Body>
              </CardWithHeader>
              <ContainerButtonNewGame>
                <ButtonWithRadiusBorderComponent
                  onClick={handleNewGameClick}
                  color="white"
                  data_test="startNewGameButton"
                  text={newGame}
                />
              </ContainerButtonNewGame>
            </CardSavedGame>
          </ContainerHasSavedGame>
        )}
      </Container>
      {showConfirmNewGameCard && (
        <ConfirmInfoCard
          onConfirmClick={goToGame}
          onCancelClick={handleNewGameClick}
          title={savedGameTitle}
          paragraph={confirmNewGameParagraph}
          confirmText={newGame}
        />
      )}

      {showConfirmDeleteGame && (
        <ConfirmInfoCard
          onConfirmClick={deleteSavedGame}
          onCancelClick={handleDeleteGameClick}
          title={deleteGame}
          paragraph={confirmDeleteGameParagraph}
          confirmText={deleteText}
        />
      )}
    </>
  )
}
