export const nameGame = 'ja-game'
export const juniorStage = 'Ensino Fundamental'
export const middleStage = 'Ensino Médio'
export const superiorStage = 'Ensino Superior'
export const chooseACharacterText = 'Escolha um personagem'
export const signUpText = 'Cadastro'
export const chooseText = 'Escolher'
export const loginText = 'Login'
export const accountText = 'Conta'
export const saveText = 'Salvar'
export const rulesText = 'Regras'
export const rulesGameText = 'Regras do Jogo'
export const exitGameText = 'Sair do Jogo'
export const exitText = 'Sair'
export const continueText = 'Continuar'
export const cancelText = 'Cancelar'
export const startText = 'Começar'
export const okText = 'OK'

// Game save
export const saveGame = 'Salvar partida'
export const saveGameParagraph = 'O jogo foi salvo com sucesso!'
export const saveGameErrorParagraph =
  'Ocorreu um erro ao salvar o jogo. Tente novamente mais tarde.'
export const savedGameTitle = 'Jogo salvo'
export const confirmNewGameParagraph =
  'Você já tem uma partida salva e não finalizada na sua conta. Se iniciar e salvar uma nova partida você perderá a partida anterior. <br/><br/>Deseja iniciar um novo jogo?'
export const confirmDeleteGameParagraph =
  'Tem certeza que deseja excluir essa partida? <br/><br/>Após a exclusão da partida você terá que começar um novo jogo.'

// Card Categories
export const knowledgeCategory = 'Quiz'
export const skillsCategory = 'Habilidades'
export const inspirationCategory = 'Inspiração'
export const jobCategory = 'Trabalho'
export const challengeCategory = 'Situação'

// Portal Z info
export const thisIsPortalZ = 'Esse é o Portal Z.'
export const portalZInfo =
  'Após se formar no ensino superior <name> chegará ao Portal Z e estará pronto para novas oportunidades.'

// Language connectors
export const andText = 'e'

// TOOLTIPS
export const turnCounterTooltip =
  'Essa é a quantidade de vezes que você pode jogar o dado'

const middleStageAlert = 'Você passou para o Ensino Médio!'
const superiorStageAlert = 'Você passou para o Ensino Superior!'
const superdiorStageEndedAlert = 'Você terminou o Ensino Superior!'

const middleStageMessage =
  '<name> concluiu o ensino fundamental e deu seu primeiro passo para chegar ao Portal Z. Preparado para continuar essa jornada?'
const superiorStageAlertMessage =
  'O Ensino Médio é uma jornada difícil e cheia de altos e baixos e <name> chegou até aqui!  Parabéns! Só mais um pouquinho e vocês chegarão no objetivo final!'
export const superdiorStageEndedAlertMessage =
  '<name> concluiu a última etapa da sua jornada de formação! Parabéns, você foi fundamental para essa conquista e juntos chegaram até aqui!'

// StageAlert
export const stringsStageAlert = {
  junior: {
    title: middleStageAlert,
    message: middleStageMessage,
  },
  middle: {
    title: superiorStageAlert,
    message: superiorStageAlertMessage,
  },
  superior: {
    title: superdiorStageEndedAlert,
    message: superdiorStageEndedAlertMessage,
  },
}

export const knowledgeMessage =
  'Hora de testar seus conhecimentos! Nessa casa você vai responder perguntas que podem dar ou te tirar pontos de experiência.'
export const inspirationMessage =
  'Boa! Essa é uma casa de Inspiração. Aqui você receberá aquela forcinha para seguir em frente e ainda alguns pontos extras de experiência.'
export const jobMessage =
  'Essa é uma casa de Trabalho. Se seu personagem estiver trabalhando, aparecerão aqui alguns desafios para você resolver. Se ainda não teve a opção de começar a trabalhar, então é só seguir em frente!'
export const challengeMessage =
  'Sempre que parar nessa casa você irá receber cartas com desafios de situações do dia a dia que podem impactar na vida do seu personagem'
export const skillsMessage =
  'Você está em um casa de Habilidades, aqui você vai receber desafios que ajudarão a desenvolver habilidades nos personagens ou não!'

// feedback
export const toEndText = 'Fim'
export const toThankParticipation = 'Agradecemos a sua participação.'
export const startNewGameQuestion = ' Vamos começar uma nova partida?'

// rules card
export const rulesCardTitle = 'Você chegou ao mundo VUCA'
export const firstParagraphRules = `<span>Cris</span>, <span>Milena</span>, <span>Brenda</span> e
<span>Jonatan</span> estão passando por um período decisivo. Eles irão
começar no Ensino Fundamental e enfrentarão novos desafios
<span>no Mundo VUCA.</span>
Um lugar imprevisível e cheio de surpresas.`

export const secondParagraphRules = ` Você precisa ajudar um deles a passar pelas três etapas: <span>Ensino
Fundamental, Médio e Superior</span> para conseguir 1000 pontos de experiência e
chegar ao <span>Portal Z: o portal das oportunidades</span>, onde eles podem conseguir
a carreira dos sonhos!`

export const thirdParagraphRules = `Mas não pense que é fácil. <span>A qualquer momento você pode ter um imprevisto
em casa, precisar contar com a sorte e também com os seus conhecimentos</span>
para conseguir mais pontos de experiência. Vamos lá?`

// end game card
export const playerWinTitle = '<name> chegou ao portal Z!'
export const playerWinDescribe =
  'O portal Z irá abrir portas para grandes e novas oportunidades. Você pode seguir sem medo, depois do Portal Z, há outros portais com infinitas possibilidades, sua jornada está apenas começando. Aproveite!'
export const playerLoserFistParagraph = '<name> não completou<br /> <maxPoints> a tempo.'
export const playerLoserSecondParagraph = 'Mas não desanime, você pode tentar de novo.'
export const playerLoserThirdParagraph = 'E aí? Aceita o desafio?'
export const playAgainText = 'Jogar Novamente'

// cards texts
export const leaveJobCardTitle = 'Sair do trabalho'
export const leaveJobCardParagraph =
  'Você só poderá voltar a trabalhar quando cair em uma nova oportunidade na casa de Situação'
export const leaveJobCardQuestion = 'Tem certeza que quer sair do seu trabalho agora?'

export const startJobCardTitle = 'Começou a trabalhar'
export const startJobTooltipTitle = '<name> está trabalhando!'
export const startJobCardParagraph =
  '<name> entrou no mercado de trabalho e agora vai precisar balancear a sua vida profissional com os estudos.'

export const leaveJobInformation =
  'A partir de agora você pode parar de trabalhar, focar nos estudos e pensar no seu futuro.'

export const howToPlayTitle = 'Como jogar'
export const playText = 'Jogar'
export const howToPlayText = `Jogue o dado para começar. Você irá andar o número de casas que tirar e poderá cair em uma das cinco casas: <span>Trabalho, Quiz, Habilidades, Situação e Inspiração.</span>`
export const backText = 'Voltar'

export const playWithoutLoginTitle = 'Jogar sem login'
export const playWithoutLoginText = `Você pode jogar sem fazer login em uma conta, mas não poderá <b>salvar o progresso da sua partida nem o histórico de jogadas.</b><br/><br/>Quer jogar mesmo assim?`

// misc text
export const theTilesOfText = 'As casas de'
export const nowWorthHalfThePointsText = 'agora valem metade dos pontos'
export const letsBeginText = 'Vamos começar?'
export const nextText = 'Próximo'
export const jumpText = 'Pular'

export const termsSignUp = 'Termos de Uso e Privacidade'

export const gameConstants = {
  CONDITION_TYPES: {
    DEBUFF: 'debuff',
    BUFF: 'buff',
  },
}

export const savedGame = 'Jogo salvo'
export const deleteGame = 'Excluir jogo'
export const deleteText = 'Excluir'
export const newGame = 'Novo jogo'
export const doLogin = 'Fazer login'
export const infoLogin = 'Para salvar o jogo, faça o login ou cadastre-se.'
export const orText = 'Ou'

export const confirmDeleteGame =
  'Tem certeza que deseja excluir essa partida?<br/><br/>Após a exclusão da partida você terá que começar um novo jogo.'

export const myAccount = 'Minha Conta'
export const editLabel = 'Alterar'
export const myMedals = 'Minhas medalhas'
export const editPassword = 'Alterar senha'
export const editAccount = 'Alterar dados'
export const confirmEditEmailAccount =
  'Você está alterando seu e-mail para <b><email></b>. Tem certeza que deseja alterar o seu e-mail?'
export const confirmLeaveWithoutSave =
  'Alguns campos com informações importantes foram editados. <br/><br/> Se você não salvar as alterações, os dados editados serão cancelados e iremos considerar os dados preenchidos anteriormente.'
export const editedAccountText = 'Dados alterados com sucesso.'
export const errorOnEditAccountText = 'Ocorreu um erro ao atualizar os dados.'
export const currentPassword = 'Senha atual'

export const successPassword = 'Sua senha foi alterada com sucesso.'
export const errorPassword =
  'Ocorreu um erro ao alterar a senha. Por favor, tente novamente.'

export const deleteAccount = 'Excluir conta'
export const deleteAccountParagraph = `É uma pena ver você indo embora! <br/><br/> Tem certeza que deseja fechar a sua conta no Game VUCA? Se você cancelar a sua conta você não poderá mais: <br/>- Salvar jogos <br/>- Ter acesso ao histórico de partidas <br/>- Ter acesso as medalhas recebidas <br/><br/> Você deseja excluir a sua conta?`
export const deletedAccount = 'Conta excluída'
export const errorDeleteAccount =
  'Ocorreu um erro ao excluir a conta. Por favor, tente novamente mais tarde.'
export const deletedAccountPragraph = 'Sua conta foi excluída'

export const backToHome = 'Voltar ao início'

export const soundText = 'Sons'
export const soundOn = 'Ligado'
export const soundOff = 'Desligado'

export const feedbackText = 'Avaliação'
export const wouldRecommend = 'Recomendaria'
export const wouldntRecommend = 'Não recomendaria'
export const wouldYouRecommendQuestion = 'Você recomendaria o Game VUCA a um amigo?'
export const feedbackInfo =
  'Avalie as frases abaixo de acordo com a sua percepção após jogar o Game VUCA:'
export const iFeelMotivated = '<b>Me sinto mais motivado a pensar no meu futuro.</b>'
export const successInStudies = '<b>Acredito que é possível ter sucesso nos estudos.</b>'
export const studyToCompleteMyGoals =
  '<b>Tenho mais vontade de estudar para atingir meus objetivos.</b>'
export const disagreeText = 'Discordo'
export const agreeText = 'Concordo'

export const seeTerms = 'Ver Termos de Uso e Privacidade'
export const termsTextContent = `
<center>Termo de Consentimento para Tratamento de Dados Pessoais</center>
<br /><br />

<p>
  Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o
  tratamento e compartilhamento de seus dados pessoais de forma anonimizada para finalidade específica, em
  conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
</p>

<br /><br />

<div id="box-titular">
  <b>TITULAR</b>
  <br />
  Pessoa a quem se referem os dados pessoais que são objeto de tratamento.
</div>

<br /><br />

<p>
  Ao aceitar o presente termo, o Titular consente e concorda que a a empresa Associação Junior Achievement do Brasil,
  CNPJ nº 04.906.381.001-23, endereço comercial, Avenida Ipiranga 6681 – Prédio96E – sala 210 – Tecnopuc, Bairro
  Partenon, CEP 90.160-091, Porto Alegre- RS, doravante denominada Controlador, tome decisões referentes ao tratamento
  de seus dados pessoais, bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se
  referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição,
  processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,
  comunicação, transferência, difusão ou extração.
</p>

<br>
<b>Dados Pessoais</b>
<br>
<p>
  O Controlador fica autorizado a tomar decisões referentes ao tratamento e a realizar o tratamento dos seguintes
  dados pessoais do Titular:
</p>

<br>
<ul>
  <li>Nome completo. </li>
  <li>Data de nascimento. </li>
  <li>Número e imagem da Carteira de Identidade (RG). </li>
  <li>Número e imagem do Cadastro de Pessoas Físicas (CPF). </li>
  <li>Número e imagem da Carteira Nacional de Habilitação (CNH). </li>
  <li>Estado civil. </li>
  <li>Nível de instrução ou escolaridade. </li>
  <li>Endereço completo. </li>
  <li>Números de telefone, WhatsApp e endereços de e-mail. </li>
  <li>Nome de usuário e senha específicos para uso dos serviços do Controlador. </li>
  <li>Comunicação, verbal e escrita, mantida entre o Titular e o Controlador. </li>
</ul>

<br>
<b>Finalidades do Tratamento dos Dados</b>
<br />
<p>
  Os dados pessoais listados neste termo, são compartilhados com terceiros parceiros de forma anonimizada para
  que estes possam acompanhar as atividades da Junior Achievement dentro do seu propósito social a promoção da
  educação empreendedora e tem as seguintes finalidades:
</p>
<br>
<ul>
  <li>Possibilitar que o Controlador envie ou forneça ao Titular seus produtos e serviços, de forma gratuita.
  </li>
  <li>Possibilitar que o Controlador envie comunicados, mensagens eletrônicas via WhatsApp e e-mail para fins
    de relacionamento institucional. </li>
  <li>Possibilitar que o Controlador identifique e entre em contato com o Titular para fins de relacionamento
    comercial.</li>
  <li>Possibilitar que o Controlador estruture, teste, promova e faça propaganda de produtos e serviços,
    personalizados ou não ao perfil do Titular. </li>
  <li>Possibilitar que o Controlador compartilhe desses dados com os terceiros parceiros de forma anonimizada
    para que estes terceiros possam acompanhar as atividades realizadas pelo Controlador. </li>
</ul>

<br>
<b>Compartilhamento de Dados </b>
<br />
<p>
  O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de
  tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os
  princípios e as garantias estabelecidas pela Lei nº 13.709.
</p>

<br>
<b>Segurança dos Dados </b>
<br />
<p>
  O Controlador responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas
  aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas
  de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
  <br />
  Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao Titular e à Autoridade
  Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar
  risco ou dano relevante ao Titular.
</p>

<br>
<b>Término do Tratamento dos Dados </b>
<br />
<p>
  O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o período em que eles
  forem pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anônimos, sem
  possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.
  <br />
  O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que
  sejam eliminados os dados pessoais não anônimos do Titular. O Titular fica ciente de que poderá ser
  inviável ao Controlador continuar o fornecimento de produtos ou serviços ao Titular a partir da
  eliminação dos dados pessoais.
</p>

<br>
<b>Direitos do Titular</b>
<br />
<p>
  O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer
  momento e mediante requisição: I - confirmação da existência de tratamento; II - acesso aos dados;
  III - correção de dados incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou
  eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei
  nº 13.709; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
  expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão
  controlador; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante
  requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
  comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento do titular,
  exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas
  e privadas com as quais o controlador realizou uso compartilhado de dados; VIII - informação sobre a
  possibilidade de não fornecer consentimento e sobre as consequências da negativa; IX - revogação do
  consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709.
</p>

<br>
<b>Direito de Revogação do Consentimento</b>
<br />
<p>
  Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via
  e-mail ou correspondência ao Controlador.
</p>
 
`

export const typeItCurrentPassword = 'Digite a senha atual'
export const invalidPassword = '<b>Senha incorreta</b>'

export const forgetPasswordTitle = 'Esqueci a senha'
export const forgetPasswordParagraph =
  'Enviaremos um e-mail com instruções para redefinição de senha.'
export const forgetPasswordParagraphEmailSent =
  'Você receberá um e-mail com instruções para a redefinição de senha se o seu e-mail estiver cadastrado.'
export const emailLabel = 'E-mail'
export const emailLabelPlaceholder = 'Digite o seu e-mail'
// export const emailError = '<b>Digite um e-mail válido.</b>'
export const sendText = 'Enviar'

export const emailText = 'E-mail'
export const passwordText = 'Senha'
export const rememberText = 'Lembrar'
export const forgetPasswordText = 'Esqueceu sua senha?'
export const loginFacebookText = 'Login com Facebook'
export const loginGoogleText = 'Login com Google'
export const dontHaveAccountText = 'Não tem uma conta? <b>Faça o seu cadastro.</b>'
export const signUpYourselfText = 'Cadastre-se'
export const invalidLogigText = 'E-mail ou senha incorreta.'
export const nameLabel = 'Nome Completo'
export const namePlaceholder = 'Digite o seu nome completo'
export const emailPLaceholder = 'Digite o seu e-mail'
export const nameError = 'Insira seu nome completo'
export const emailAlreadySignedUp = 'E-mail já cadastrado'
export const dateOfBirthTLabel = 'Data de nascimento'
export const dateOfBirthTooltip =
  'O Game VUCA é indicado para maiores de 11 anos, por isso armazenamos a sua data de nascimento.'
export const dayLabel = 'Dia'
export const mounthLabel = 'Mês'
export const january = 'Jan'
export const february = 'Feb'
export const march = 'Mar'
export const april = 'Abril'
export const may = 'Mai'
export const june = 'Jun'
export const july = 'Jul'
export const august = 'Ago'
export const september = 'Set'
export const october = 'Out'
export const november = 'Nov'
export const december = 'Dez'
export const yearLabel = 'Ano'
export const genderText = 'Gênero'
export const feminineText = 'Feminino'
export const maleText = 'Masculino'
export const notBinary = 'Não binário'
export const other = 'Outro'
export const preferNotToSay = 'Prefiro não dizer'
export const ethnicityText = 'Raça'
export const white = 'Branco'
export const parde = 'Pardo'
export const black = 'Negro'
export const yellow = 'Amarelo'
export const indie = 'Indígeno'
// export const country = 'País'
export const state = 'Estado'
export const city = 'Cidade'
export const areYouStudying = 'Estuda atualmente?'
export const yesText = 'Sim'
export const noText = 'Não'
export const createPasswordLabel = 'Criar senha'
export const newPasswordLabel = 'Nova senha'
export const newPasswordPlaceholder = 'Digite a nova senha'
export const confirmNewPassword = 'Confirme a nova senha'
export const agreeTerms = 'Concordo com os <b>Termos de Uso e Privacidade</b>'
export const schooling = 'Escolaridade'

export const select = 'Selecione'
export const dadoOpcinal =
  'Dado opcional, não precisamos de dados sobre gênero, raça, cidade, localização e escolaridade mas pedimos estes dados para estatísticas internas.'

export const passwordInfo =
  'A senha deve ter no mínimo 8 e no máximo 12 caractéres, sendo entre eles pelo menos uma letra maiúscula, uma minúscula, um número e um caractér especial (!@#$%^&).'
export const confirmPassword = 'Confirmar a senha'
export const passwordStrengthLabel = 'Força da senha: '
export const passwordWeak = ' Fraco'
export const passwordModerate = ' Moderado'
export const passwordStrong = ' Forte'

export const PasswordStrenghtCaracters =
  'A senha deve ter no mínimo 8 e no máximo 12 caractéres.'
export const PasswordStrenghtUpper = 'Pelo menos uma letra maiúscula.'
export const PasswordStrenghtLower = 'Pelo menos uma letra minúscula.'
export const PasswordStrenghtNumber = 'Pelo menos um número.'
export const PasswordStrenghtSpecial = 'Pelo menos um caractér especial (!@#$%ˆ&).'

export const school_yearLabel = 'Ano letivo'
export const type_institutionLabel = 'Tipo de Escola'
export const publicSchool = 'Pública'
export const privateSchool = 'Privada'
export const accountSettings = 'Configurações da Conta'
export const personalData = 'Dados Pessoais'
export const orPlayWithSocialMedia = 'Ou jogue usando as Redes Sociais'

export const shareText = 'Compartilhar'
export const shareGameText = 'Compartilhar o jogo'
export const copyText = 'Copiar'
export const shareGameParagraph =
  'Gostou do Game VUCA? Compartilhe com os seus amigos pelo link:'
export const copiedText = 'Copiado!'

// Card Medals
export const jonathanMedalText = 'Medalha Jonathan'
export const milenaMedalText = 'Medalha Milena'
export const brendaMedalText = 'Medalha Brenda'
export const crisMedalText = 'Medalha Cris'
export const seeMoreText = 'Ver mais'
export const seeMedalText = 'Ver medalha'
export const winMedalText = 'Você conquistou a medalha <name>!'
export const winMedalParagraph =
  'Medalhas são recompensas por conseguir chegar ao Portal Z e ganhar o Game VUCA. As medalhas conquistadas ficarão salvas e você pode consultá-las no menu do jogo se estiver logado.'

export const youHaventLoggedYet = 'Você não fez login!'
export const toSaveGameText =
  'Para salvar o jogo, você precisa fazer login ou se cadastrar (se não tiver login ainda)'
export const noProgressGame = 'Nenhum jogo em progresso'
export const toSaveGameNoProgressText =
  'Para salvar, você precisa iniciar um novo jogo.<br/>'

export const malePersona1 = 'Cris'
export const malePersona2 = 'Jonathan'
export const femalepersona1 = 'Milena'
export const femalepersona2 = 'Brenda'
