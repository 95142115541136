import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1rem;
  place-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 99999;
`

export const BodyCard = styled.div`
  width: 241px;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  animation: filterGrayAnimate 1s infinite;

  @keyframes filterGrayAnimate {
    from {
      filter: grayscale(0.8);
    }
    25% {
      filter: grayscale(0.4);
    }
    50% {
      filter: grayscale(0);
    }
    75% {
      filter: grayscale(0.4);
    }
    to {
      filter: grayscale(0.8);
    }
  }
`
