import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import queryString from 'query-string'

import {
  editPassword,
  successPassword,
  errorPassword,
  editLabel,
  doLogin,
  newPasswordLabel,
  newPasswordPlaceholder,
  passwordInfo,
  confirmNewPassword,
} from '../../common/strings'

import { verifyToken, resetPassword } from '../../services/API/password'

import { VucaLogoWithDescription } from '../../assets/img'
import { CardWithHeader } from '../../components'
import { ContainerButton, ContentCard } from '../../components/GameCards/styles'
import { ContainerChangePassword } from '../../components/Account/components/changePassword/styles'
import { Password } from '../SignUp/SignUpCard/components/cardBody/FormSignUp/components'
import {
  ContentIconWithParagraph,
  Paragraph,
} from '../../components/Account/components/deleteAccountCard/styles'
import { Footer } from '../ChoiceCharacter/CharactersCard/components'

import { Container } from './styles'
import { ButtonWithRadiusBorderComponent } from '../../components/Buttons/buttonWithRadiusBorder'

export const ResetPassword = () => {
  const history = useHistory()
  const location = useLocation()

  const [token, setToken] = useState('')
  const [tokenVerified, setTokenVerified] = useState(false)
  const [showRequired, setShowRequired] = useState(false)
  const [hasPasswordChanged, sethasPasswordChanged] = useState(false)
  const [msgInfo, setMsgInfo] = useState(successPassword)

  const changePasswordStrings = useMemo(setChangePasswordStrings, [])
  function setChangePasswordStrings() {
    return {
      newPassword: {
        label: newPasswordLabel,
        placeholder: newPasswordPlaceholder,
        dataTest: 'inputNewPassword',
        info: passwordInfo,
      },
      confirmPassword: {
        label: confirmNewPassword,
        placeholder: confirmNewPassword,
        dataTest: 'inputConfirmPassword',
      },
    }
  }

  const [dataResetPassword, setDataResetPassword] = useState({
    token: '',
    email: '',
    password: '',
  })

  const memoizedDataOfButton = useMemo(dataOfButtons, [
    hasPasswordChanged,
    dataResetPassword,
  ])

  async function validateToken(token) {
    const response = await verifyToken({ token: token })
    const { status, data } = response

    if (response && status === 200) {
      const object = {
        token: token,
        email: data.email,
      }

      setDataResetPassword({ ...dataResetPassword, ...object })
      setTokenVerified(true)
    } else {
      setToken('')
    }
  }

  useEffect(getTokenParam, [])
  function getTokenParam() {
    const objectSearch = queryString.parse(location.search)
    const tokenParam = objectSearch.token

    if (tokenParam) {
      setToken(tokenParam)
      validateToken(tokenParam)
    }
  }

  function dataOfButtons() {
    return [
      {
        text: editLabel,
        data_test: 'editPasswordButton',
        hide: hasPasswordChanged,
        color: 'white',
        onClick: sendNewPassword,
      },
      {
        text: doLogin,
        data_test: 'goToLohinButton',
        hide: !hasPasswordChanged,
        onClick: goToLogin,
      },
    ]
  }

  function sendNewPassword() {
    if (dataResetPassword.password) {
      setShowRequired(false)
      resetUserPassword()
    } else {
      setShowRequired(true)
    }
  }

  async function resetUserPassword() {
    const response = await resetPassword(dataResetPassword)
    const { status } = response

    if (response && status !== 200) {
      setMsgInfo(errorPassword)
    }

    sethasPasswordChanged(true)
  }

  function goToLogin() {
    history.push('/')
  }

  function recieveNewPassword(password) {
    const passwordObj = { password: password }
    setDataResetPassword({ ...dataResetPassword, ...passwordObj })
  }

  function showMsg() {
    if (token) {
      return <p>Carregando</p>
    } else {
      return <p>Ocorreu um erro</p>
    }
  }

  return (
    <>
      <Container data-test="LoginContainer">
        <figure className="logo">
          <VucaLogoWithDescription />
        </figure>

        {tokenVerified && (
          <CardWithHeader
            backgroundColorHeader="pistachioGreen"
            titleCard={editPassword}
            data_test="ChangePasswordCard">
            <ContentCard>
              {hasPasswordChanged && (
                <ContentIconWithParagraph>
                  <Paragraph
                    dangerouslySetInnerHTML={{ __html: msgInfo }}
                    data-test="paragraphChangePasswordCard"
                  />
                </ContentIconWithParagraph>
              )}

              {!hasPasswordChanged && (
                <ContainerChangePassword>
                  <Password
                    signUpStrings={changePasswordStrings}
                    sendPassword={recieveNewPassword}
                    showRequired={showRequired}
                  />
                </ContainerChangePassword>
              )}

              <ContainerButton>
                {memoizedDataOfButton.map(
                  ({ text, data_test, hide, onClick }) =>
                    !hide && (
                      <ButtonWithRadiusBorderComponent
                        onClick={onClick}
                        key={text}
                        data_test={data_test}
                        text={text}
                      />
                    ),
                )}
              </ContainerButton>
            </ContentCard>
            <Footer />
          </CardWithHeader>
        )}

        {!tokenVerified && showMsg()}
      </Container>
    </>
  )
}
