import styled, { css } from 'styled-components'

const linearGradients = {
  orange: `linear-gradient(
    to bottom,
    rgba(253, 151, 49, 1) 0%,
    rgba(253, 151, 49, 1) 50%,
    rgba(253, 128, 4, 1) 51%,
    rgba(253, 128, 4, 1) 71%,
    rgba(253, 128, 4, 1) 100%)`,
  orangeInHover: `linear-gradient(
    to bottom,
    rgba(227, 137, 47, 1) 0%,
    rgba(227, 137, 47, 1) 48%,
    rgba(253, 130, 4, 1) 48%,
    rgba(222, 112, 0, 1) 48%,
    rgba(222, 112, 0, 1) 100%
  )`,
  white: `linear-gradient(
    rgb(255 255 255) 0%,
    rgb(255 255 255) 50%,
    rgb(255 255 255) 51%,
    rgb(255 255 255) 71%,
    rgb(218 211 205) 100%
  )`,
}

export const Button = styled.button`
  border: solid 0.2rem ${({ theme, colors }) => theme.colors[colors.borderColor]};
  border-radius: 2rem;
  box-shadow: -2.5px 3px ${({ theme, colors }) => theme.colors[colors.borderColor]};
  color: ${({ theme, colors }) => theme.colors[colors.colorText]};
  font-family: MilkyNice;
  padding: 0.5rem 0;
  margin-top: 1rem;
  text-decoration: none;
  background-color: ${({ theme, colors }) => theme.colors[colors.bgColor]};

  min-width: 298px;
  height: 40px;

  &:focus,
  &:hover {
    outline: none;
    background: none;
  }

  ${({ colors }) =>
    colors.bgColor === 'blueFacebook' &&
    css`
      &:hover {
        background: ${({ theme }) => theme.colors.lightBlue};
      }

      &:focus {
        background: ${({ theme }) => theme.colors.selectedBlue};
        box-shadow: -2.5px 3px ${({ theme, colors }) => theme.colors.borderSelectedBlue};
        border-color: ${({ theme }) => theme.colors.borderSelectedBlue};
      }
    `}

  ${({ colors }) =>
    colors.bgColor === 'orange' &&
    css`
      background: ${linearGradients.orange};

      &:focus,
      &:hover {
        background: ${linearGradients.orangeInHover};
        background-color: none;
      }
    `}

  ${({ colors }) =>
    colors.bgColor === 'white' &&
    css`
      background: ${linearGradients.white};

      &:focus {
        border: 4px solid black;
        background-color: ${({ theme }) => theme.colors.whiteOff};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.whiteOff};
      }
    `}

  @media screen and (min-width: 1024px) {
    padding: 0.7rem 2rem;
    height: 50px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-width: 288px;
  }


  &:disabled {
    background: none;
    background-color: ${({ theme }) => theme.colors.veryLightPink};
    border: solid 0.2rem ${({ theme }) => theme.colors.brownGrey};
    box-shadow: -2.5px 3px ${({ theme }) => theme.colors.brownGrey};
    color: ${({ theme }) => theme.colors.brownGrey};
  }

  .buttonValue {
    position: relative;
    top: -2px;
  }
`

export const DisplayFlex = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};

  figure {
    padding-right: 10px !important;
  }
`
