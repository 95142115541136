import { config } from '../../../game/config'

const { employed } = config.player.conditions

export const middle = {
  challenge: [
    {
      level: 'middle',
      question:
        '<name> não se preveniu e contraiu uma DST(Doença sexualmente transmissível). Por isso vai ter que fazer um longo tratamento de saúde e ficar algum período longe da escola.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: -20,
          feedback:
            'Loucura, né? Mas prevenção é coisa séria! <name> acabou perdendo alguns XP para dar atenção nesse assunto.',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question:
        '<name> se mudou para um bairro que fica muito longe da escola e o tempo de deslocamento dobrou. Mesmo tendo que acordar muito cedo, <name> segue com determinação para concluir o Ensino Médio.',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 30,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question:
        'A mãe de <name>  voltou a trabalhar fora e precisa de sua ajuda para cuidar do irmão mais novo. <name> resolve:',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Não ir à aula durante a semana para ficar com o irmão.',
          point: -40,
          feedback:
            '<name> perdeu uma semana de aula e agora terá mais dificuldade para acompanhar o conteúdo!',
        },
        {
          response_text: 'Buscar ajuda de uma vizinha para não perder as aulas.',
          point: 40,
          feedback:
            'Graças a sua iniciativa, <name> não perdeu nenhuma aula e ganhou mais XP. Rumo ao Portal Z!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'Algumas aulas da escola de <name> se tornaram online. Como <name> não tem computador, precisa acompanhar tudo pelo celular, mas isso se mostra muito cansativo. O que <name> faz?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Não presta muito pouca atenção nas aulas pelo celular.',
          point: -10,
          feedback: 'Fingir que está estudando só atrasa a vida. ',
        },
        {
          response_text: 'Desliga as notificações e se esforça para  prestar atenção.',
          point: 20,
          feedback: 'Parabéns! Graças à dedicação <name> manteve notas boas.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'Por dificuldades financeiras, a mãe de <name> não conseguirá pagar a mensalidade do curso de inglês. E agora?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Desiste de aprender inglês até melhorar a situação financeira.',
          point: -10,
          feedback:
            'Desistir do curso de inglês, sem tentar uma outra opção, pode atrapalhar os planos no futuro.',
        },
        {
          response_text:
            'Procura um curso gratuito na internet e segue seus estudos de forma autônoma.',
          point: 30,
          feedback:
            '<name> entende que a adaptação pode ser complicada, mas vai tentar para não parar de estudar! ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question: '<name> precisa de um celular melhor mas está sem dinheiro. O que fazer?',
      type_question: 'decision_making',
      job_entry: true,
      options: [
        {
          response_text: 'Procura um emprego no comércio.',
          point: 10,
          feedback:
            'Muitos jovens acabam deixando a escola de lado para trabalhar. O desafio de <name> será conciliar os dois para conseguir chegar ao Portal Z.',
          conditions: [employed],
        },
        {
          response_text: 'Organiza uma vaquinha com amigos e familiares.',
          point: 30,
          feedback:
            'Toda decisão tem um aprendizado, né? Vai demorar mais para comprar o celular, mas <name> poderá focar nos estudos e ganhar muito Xp',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  knowledge: [
    {
      level: 'middle',
      question: 'Qual destes itens não caracteriza um sistema democrático?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Acesso à informação.',
          point: -30,
          feedback:
            'Um sistema democrático não proíbe manifestações políticas. Democracia significa liberdade de expressão, eleições livres, liberdade de associação política e acesso à informação.',
        },
        {
          response_text: 'Proibição de manifestações políticas.',
          point: 40,
          feedback: 'Boa! <name> já sabe que democracia tem a ver com liberdade.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'Cinco bolas numeradas de 1 a 5 são colocadas em uma urna. A probabilidade de sortear um número par é de: ',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: '20%',
          point: -20,
          feedback: 'Ops! A resposta correta é 40%',
        },
        {
          response_text: '40%',
          point: 30,
          feedback: 'Resposta correta!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question: 'Qual frase está correta? ',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Tinha muitas pessoas no jogo.',
          point: 30,
          feedback:
            'Resposta correta. Quando o verbo TER é usado com o significado de EXISTIR, ele é conjugado na 3ª pessoa do singular.',
        },
        {
          response_text: 'Tinham muitas pessoas no jogo.',
          point: -20,
          feedback:
            'Resposta errada. Quando o verbo TER é usado com o significado de EXISTIR, ele é conjugado na 3ª pessoa do singular: “Tinha muitas pessoas” ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question: 'O indiano Mohandas Gandhi ficou famoso mundialmente por:',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Liderar um movimento não violento pela independência da Índia.',
          point: 30,
          feedback: 'Resposta correta!',
        },
        {
          response_text: 'Iniciar uma guerra mundial.',
          point: -20,
          feedback:
            'Resposta incorreta. Gandhi liderou o movimento de independência da Índia e ficou conhecido pela sua forma de manifestação pacífica.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question: 'Qual melhor estratégia para construir um argumento?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text:
            'Recorrer a dados concretos e fazer comparações para justificar seu ponto de vista.',
          point: 40,
          feedback: 'Boa! Uma boa argumentação é feita de fatos, não de gritos!',
        },
        {
          response_text: 'Falar firme e atacar o posicionamento contrário.',
          point: -30,
          feedback:
            'Não dá para convencer outra pessoa na base do grito e do ataque. Argumentar é apresentar dados e ideias que sustentem uma opinião.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question: 'Qual dos itens abaixo não representa uma reação química?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Fritar um ovo',
          point: -30,
          feedback:
            'Ops! A resposta certa é a outra opção, pois a água e o óleo não se misturam.',
        },
        {
          response_text: 'Misturar água com óleo.',
          point: 40,
          feedback:
            'Boa! Misturar esses dois elementos não representa uma reação química.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'Imagine que Maria ganha R$ 1.200  por mês de salário. Seu orçamento inclui R$ 450,00 reais de aluguel, R$ 300,00 de alimentação e R$ 100,00 de contas de luz e água. Quanto sobra do seu orçamento mensal para as demais despesas da família?',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'R$ 350,00',
          point: 40,
          feedback:
            'Resposta certa. Calcular corretamente o orçamento familiar pode evitar uma bela dor de cabeça!',
        },
        {
          response_text: 'R$ 250,00 ',
          point: -30,
          feedback:
            'Esta conta não está fechando! A resposta certa é R$ 350,00. Calcular corretamente o orçamento familiar pode evitar uma bela dor de cabeça!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'Os antibióticos são medicamentos utilizados para combater infecções causadas por..',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'Bactéria.',
          point: 40,
          feedback: 'Boa! Resposta certa.',
        },
        {
          response_text: 'Vírus.',
          point: -30,
          feedback:
            'Deu ruim! Os antibióticos são medicamentos que combatem as bactérias, não os vírus.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  skills: [
    {
      level: 'middle',
      question:
        'Um atleta olímpico vai visitar a escola e o diretor está procurando um estudante para ajudar no tour da escola. O que <name> faz?',
      type_question: 'decision_making',
      options: [
        {
          response_text: '<name> se candidata e se empolga com a oportunidade.',
          point: 40,
          feedback:
            '<name> surpreendeu o atleta com uma apresentação especial. Usou a criatividade e desenvolveu sua postura e oratória.',
        },
        {
          response_text: 'Fica com medo, e prefere deixar a missão para outro colega.',
          point: -30,
          feedback:
            '​<name> não conseguiu driblar o medo e ainda precisa melhorar sua autoconfiança!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        '<name> assumiu a coordenação do Festival de Dança, mas sua equipe não tem experiência em eventos e os membros discordam em tudo. O que fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Passar o cargo para uma pessoa mais paciente.',
          point: -20,
          feedback:
            'Poxa! <name> perdeu a  chance de praticar uma habilidade essencial no Mundo Vuca:   liderar pessoas e projetos.',
        },
        {
          response_text:
            'Conversar com a equipe para identificar os interesses e habilidades de cada um.',
          point: 30,
          feedback:
            '​Boa! Pontos para <name>, que aprendeu muito sobre liderar pessoas e coordenar projetos!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'Uma amiga de <name> não consegue entender por que a biblioteca da escola só abre uma vez por mês. Ela comenta com <name>, que responde:',
      type_question: 'decision_making',
      options: [
        {
          response_text: '"Sempre foi assim!"',
          point: -20,
          feedback:
            'Opa! Esta frase está proibida no Mundo VUCA.  Não é porque as coisas são assim que não podem ser melhoradas, não é mesmo?',
        },
        {
          response_text:
            'Show! Não é porque as coisas são assim que não podem ser melhoradas, não é mesmo? A maior parte das inovações - pequenas ou grandes - começam assim.',
          point: 30,
          feedback:
            '​Boa! Pontos para <name>, que aprendeu muito sobre liderar pessoas e coordenar projetos!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        '<name> descobriu pelas redes sociais que sua melhor amiga mentiu sobre o final de semana. <name> resolve:',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Publicar um texto nas redes sociais xingando a amiga.',
          point: -20,
          feedback:
            '<name> agiu por impulso e atacou a amiga publicamente. Faltou autocontrole e diálogo, elementos fundamentais no Mundo Vuca.',
        },
        {
          response_text: 'Conversar com a amiga.',
          point: 30,
          feedback:
            '​Boa! <name> se sentiu mal, mas não se deixou levar pelo impulso. Pontos para <name> pela iniciativa e pelo autocontrole! ',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question: 'Chegou o período de eleição do grêmio estudantil! <name> resolve:',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Apoiar a chapa que tem a melhor proposta na sua opinião.',
          point: 40,
          feedback:
            '<name> se envolveu ativamente. Teve que liderar equipes e convencer seus colegas. A experiência garantiu novas habilidades e um estoque de XP para chegar ao Portal Z.',
        },
        {
          response_text: 'Não se envolver nas eleições.',
          point: -30,
          feedback:
            '​<name> não teve iniciativa e perdeu a oportunidade de participar de decisões importantes para escola e seus colegas.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'A escola fez  parceria com uma ONG para oferecer um curso de empreendedorismo para estudantes. O que <name> faz?',
      type_question: 'decision_making',
      options: [
        {
          response_text: '<name> se inscreve no curso mesmo não sendo obrigatório.',
          point: 40,
          feedback:
            'Boa iniciativa! <name> criou uma empresa estudantil com os colegas e descobriu habilidades que nem sabia que tinha! A experiência vai ajudar <name> nas decisões futuras!!!',
        },
        {
          response_text: 'Não se inscreve porque não tem interesse em abrir seu negócio.',
          point: -30,
          feedback:
            '<name> perdeu a chance de explorar seus próprios talentos e descobrir novas opções de carreira.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  inspiration: [
    {
      level: 'middle',
      question: '<b>Bisman Deu</b><br/>Empreendedora e ex aluna da JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Um conselho crucial aos jovens é criar </b>oportunidades</b> para si mesmos. Tendemos a <b>esperar</b> que a oportunidade venha bater à nossa porta, mas isso <b>não costuma acontecer</b>. Acredito firmemente em tentar criar tantas oportunidades quanto puder."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Daneil Cheung</b><br/>Empreendedor social e ativista',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Expanda a sua rede não apenas para receber conselhos e ajuda, mas também para se disponibilizar a dar conselhos e ajudar os outros.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question:
        '<b>Nyi Mas Gianti Bingah Erbiana</b><br/>Chefe de assessoria jurídica da Mitsubishi Indonésia',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Procure ser flexível. Não estou sugerindo ser um seguidor dos outros ou que não tenha a própria identidade. Mas o jovem deve ser capaz de se adaptar às mudanças no mundo e de entrar em consenso com outras pessoas.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Jim Hemak</b><br/>Ex-aluno JA EUA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Aproveite toda a oportunidade de se envolver em organizações enquanto estudante ao longo da vida. Essas experiências podem significar muito aprendizado para qualquer tipo de carreira ou negócio que venha a ter."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Alfred bright</b><br/>Artista, empreendedor e ex aluno da JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Primeiro, minha mensagem não mudou: <b>maximize seu potencial</b>. Vá fundo dentro de si mesmo e encontre aquilo que gostaria de fazer mesmo que não fosse pago por isso. Siga sua <b>paixão</b>, encontre a si mesmo e maximize seu potencial. Mantenha uma atitude positiva em relação a você mesmo. Foi o que eu fiz. Mantive uma <b>atitude positiva</b> e nunca deixei de ser eu mesmo.”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Kim kaupe</b><br/>Empreendedora e ex aluna da JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Não importa se você é um empreendedor independente ou se atua dentro de uma companhia: <b>é sempre sensato pedir ajuda</b>. Você ficará surpreso ao constatar quanta gente quer ver você se sair bem e está pronta a <b>estender a mão</b>. Portanto, <b>não</b> tenha <b>medo</b> de pedir ajuda.',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Pedro Englert</b><br/>Empreendedor e ex aluno da JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Meu primeiro conselho aos jovens é <b>faça algo que você gosta</b>, porque apenas <b>você</b> dedicará o tempo e a energia necessária para fazer isso ter sucesso.” “Não tenha <b>medo de errar</b>, mas não persista no erro e evite cometê-lo duas vezes”.',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Jerome Cowans</b><br/>Empreendedor e ex-aluno JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Eu também encorajaria os jovens a <b>pensarem</b> sobre a perspectiva de <b>médio a longo prazo</b> para suas carreiras. Você poderia fazer algo agora como uma carreira que ficará obsoleta em 5 ou 10 anos, então faça algo que <b>você ame fazer</b>, mas também esteja preparado para mudá-lo no <b>futuro.</b>”',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Amanda Cornélio</b><br/>ex-aluna JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '"Não coloque limites para os <b>sonhos</b>, quanto mais a gente se dedica, mais próximos estamos deles! E <b>podem acontecer</b> quando a gente menos espera."',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question: '<b>Jim hemak</b><br/>Empreendedor e ex-aluno JA',
      type_question: 'only_good_thing',
      options: [
        {
          response_text:
            '“Aproveite <b>oportunidades</b> para se envolver em organizações, seja na <b>escola</b> ou na <b>vida</b>. Isso pode ser de grande <b>aprendizado</b> na sua vida.',
          point: 50,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
  ],
  job: [
    {
      level: 'middle',
      question:
        '<name> tinha que fazer um relatório sobre suas atividades no trabalho e enviar por e-mail. <name> acabou deixando para a última hora e esqueceu de registrar várias tarefas. Mandou mal!',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: -30,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question:
        '<name> está indo muito bem e seu rendimento no trabalho é ótimo! O gerente admira seu esforço e lhe recompensará com um bônus neste mês!',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 20,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question:
        'Novas pessoas foram contratadas e <name> vai ajudá-las durante as primeiras semanas. A equipe confia em seu trabalho!',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: '',
          point: 30,
          feedback: '',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'middle',
      question:
        '<name> mudou de função no trabalho e está com dúvidas em várias atividades, o que <name> deve fazer?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Encarar o desafio e aprender na prática.',
          point: -30,
          feedback:
            'Aprender na prática é muito importante, mas alguns erros podem ser evitados pedindo ajuda aos colegas de trabalho. Assim, todo mundo aprende!',
        },
        {
          response_text: 'Pedir ajuda aos colegas de trabalho.',
          point: 40,
          feedback:
            'Acertou demais! Conversar e pedir ajuda mostra que <name> tem vontade de aprender!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        'O movimento do comércio está baixo e <name> pensa em criar uma loja online para ajudar nas vendas. Mas <name> não sabe como propor sua ideia, uma vez que é responsabilidade de outro setor. O que fazer? ',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Seguir trabalhando e não se envolver neste assunto.',
          point: -32,
          feedback:
            'Um colega deu a ideia de montar a loja online, que foi muito bem aceita. <name> poderia ter se destacado se tivesse acreditado em si mesmo(a). ',
        },
        {
          response_text: 'Compartilhar a ideia com a equipe.',
          point: 30,
          feedback:
            'A liderança gostou muito da ideia e vai implementá-la. Ponto positivo para <name>, que se destacou em mais essa!',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'middle',
      question:
        '<name> precisa ir ao médico logo cedo, e vai se atrasar para o trabalho. E agora?',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'Avisa o chefe com antecedência.',
          point: 40,
          feedback:
            'A equipe de <name> conseguiu se organizar para cobrir o atraso e tudo ficou bem. Xp para quem pensou na equipe.',
        },
        {
          response_text: 'Pega um atestado para justificar o atraso.',
          point: -30,
          feedback: '<name> não avisou o chefe e deixou a equipe toda na mão.',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
}
