import React, { memo, useMemo, useState } from 'react'
import { useStore } from '../../store/hooks'

import PropTypes from 'prop-types'

import {
  loginText,
  saveText,
  rulesText,
  exitText,
  accountText,
  soundText,
  soundOn,
  soundOff,
  backText,
  youHaventLoggedYet,
  toSaveGameText,
  noProgressGame,
  toSaveGameNoProgressText,
} from '../../common/strings'

import {
  IcPerson,
  IcDownload,
  IcExit,
  IcRule,
  IcSound,
  IcLogin,
  IcBack,
  VucaLogoMenu,
  IcX,
} from '../../assets/img'

import soundtrack from '../../assets/music/soundtrack.mp3'

import { logout } from '../../services/API/login'
import { get } from '../../services/storage/'

import { HorizontalMenu, SoundEffect, ToggleSound, Toggle } from './styles'

import { Account } from '..'
import { DisplayFlex } from '../Input/styles'
import { types } from '../../store'
import { Sound } from '../Sound'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'
import { gamePropTypes } from '../../game'
import { ExpTagContainer, Tooltip } from '../ProgressBar/styles'
import { BackgroundBlur } from '../GameCards/styles'
import { isMobileSize } from '../../common/utils'

const DesktopMenu = ({
  G,
  onRulesClick,
  showLoginCard,
  isShowLoginCard,
  saveGameClick,
  isShowSignUpCard,
}) => {
  const isMobile = useMemo(isMobileSize, [])

  const userWithoutLogin = {
    title: youHaventLoggedYet,
    paragraph: toSaveGameText,
  }

  const userWithoutProgressGame = {
    title: noProgressGame,
    paragraph: toSaveGameNoProgressText,
  }

  const [tooltipSave, setTooltipSave] = useState({
    widthSize: 310,
    heightSize: 140,
    afterTop: -152,
    afterLeft: 110,
    marginTop: 230,
    marginLeft: 300,
  })

  const [isShowAccount, setIsShowAccount] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [textTooltip, setTextTooltip] = useState(userWithoutLogin)

  const { store, dispatch } = useStore()
  const { sound } = store

  const [labelSound, setLabelSound] = useState(getEnableSound(sound))

  const memoizedDataOfButton = useMemo(dataOfButtons, [
    isShowLoginCard,
    isShowSignUpCard,
    isShowAccount,
    G,
    get('token'),
  ])

  function isUserLogged() {
    return !!get('token')
  }

  function toggleSound() {
    dispatch({ type: types.SET_SOUND, payload: !sound })
    setLabelSound(getEnableSound(!sound))
  }

  function getEnableSound(sound) {
    return sound ? soundOn : soundOff
  }

  function logoutGame() {
    logout(dispatch)
    window.location.replace('http://gamevuca.org.br/')
  }

  function showAccount() {
    setIsShowAccount(true)
  }

  function closeAccount() {
    setIsShowAccount(false)
  }

  function saveGame(G) {
    if (G && isUserLogged()) {
      saveGameClick(G)
      return
    } else if (!isUserLogged()) {
      setTooltipSave({ ...tooltipSave, ...{ afterTop: -152 } })
      setTextTooltip(userWithoutLogin)
    } else if (!G) {
      setTooltipSave({ ...tooltipSave, ...{ afterTop: -130 } })
      setTextTooltip(userWithoutProgressGame)
    }

    setShowTooltip(true)
    if (isMobile) {
      setTimeout(function () {
        setShowTooltip(false)
      }, 10000)
    }
  }

  function dataOfButtons() {
    return [
      {
        text: loginText,
        data_test: 'desktopMenuLoginButton',
        Icon: IcLogin,
        hide: isUserLogged(),
        onClick: showLoginCard,
      },
      {
        text: accountText,
        data_test: 'desktopMenuAccountButton',
        Icon: IcPerson,
        hide: !isUserLogged() || isShowAccount,
        onClick: showAccount,
      },
      {
        text: backText,
        data_test: 'desktopBackAccountButton',
        Icon: IcBack,
        hide: !isShowAccount,
        onClick: closeAccount,
      },
      {
        text: saveText,
        data_test: 'desktopMenuSaveButton',
        Icon: IcDownload,
        hide: false,
        onClick: () => saveGame(G),
      },
      {
        type: 'soundEffect',
        Icon: IcSound,
        text: soundText,
      },
      {
        text: rulesText,
        data_test: 'desktopMenuRulesButton',
        Icon: IcRule,
        hide: false,
        onClick: onRulesClick,
      },
      {
        text: exitText,
        data_test: 'desktopMenuExitButton',
        Icon: IcExit,
        hide: false,
        onClick: logoutGame,
      },
    ]
  }
  return (
    <>
      {sound && <Sound autoPlay loop mp3Sound={soundtrack} />}
      {isShowAccount && <Account closeAccount={closeAccount} />}

      <HorizontalMenu>
        {!isShowAccount && (
          <figure>
            <VucaLogoMenu />
          </figure>
        )}

        {memoizedDataOfButton.map(({ type, text, data_test, Icon, hide, onClick }) => {
          if (!hide) {
            if (!type) {
              return (
                <>
                  <ButtonWithRadiusBorderComponent
                    data_test={data_test}
                    color="white"
                    className="menuItem"
                    onClick={onClick || (() => {})}
                    key={text}
                    withoutMarginTop={true}
                    text={text}
                    Icon={Icon}
                  />
                  {showTooltip && text === saveText && (
                    <>
                      <Tooltip styles={tooltipSave} withCloseButton>
                        <ExpTagContainer>
                          <b>{textTooltip.title}</b>
                          <IcX onClick={() => setShowTooltip(false)} />
                        </ExpTagContainer>
                        <p dangerouslySetInnerHTML={{ __html: textTooltip.paragraph }} />
                      </Tooltip>
                      <BackgroundBlur zIndex={87} />
                    </>
                  )}
                </>
              )
            } else {
              return (
                <DisplayFlex key={text} gap="20px">
                  <SoundEffect className="soundEffect">
                    {text}
                    <Icon width="24" height="24" />
                  </SoundEffect>
                  <ToggleSound
                    onClick={toggleSound}
                    data-test="music"
                    className="toggleSound">
                    <Toggle active={sound}>{labelSound}</Toggle>
                  </ToggleSound>
                </DisplayFlex>
              )
            }
          }
          return <></>
        })}
      </HorizontalMenu>
    </>
  )
}

DesktopMenu.propTypes = {
  G: gamePropTypes.G,
  onRulesClick: PropTypes.func,
  saveGameClick: PropTypes.func.isRequired,
  showLoginCard: PropTypes.func.isRequired,
  isShowLoginCard: PropTypes.bool.isRequired,
  isShowSignUpCard: PropTypes.bool.isRequired,
}
const DesktopMenuMemoized = memo(DesktopMenu)

export { DesktopMenuMemoized as DesktopMenu }
