import React from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '..'
import { ConfirmationButtonContainer } from './styles'
import {
  BackgroundBlur,
  ContentCard,
  Container as CardContainer,
} from '../../GameCards/styles'
import {
  exitText,
  cancelText,
  leaveJobCardParagraph,
  leaveJobCardQuestion,
  leaveJobCardTitle,
} from '../../../common/strings'
import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'

export const LeaveJobConfirmationCard = ({ onLeaveJobClick, onCancelClick }) => {
  return (
    <>
      <CardContainer>
        <CardWithHeader
          isSmall
          data_test="leaveJobConfirmation"
          titleCard={leaveJobCardTitle}
          backgroundColorHeader="amber">
          <ContentCard data-test="leaveJobConfirmationContent">
            {leaveJobCardParagraph}
            <br /> <br />
            {leaveJobCardQuestion}
          </ContentCard>
          <ConfirmationButtonContainer>
            <ButtonWithRadiusBorderComponent
              data_test="leaveJobExitButton"
              onClick={onLeaveJobClick}
              text={exitText}
            />
            <ButtonWithRadiusBorderComponent
              data_test="leaveJobCancelButton"
              color="white"
              onClick={onCancelClick}
              text={cancelText}
            />
          </ConfirmationButtonContainer>
        </CardWithHeader>
      </CardContainer>
      <BackgroundBlur />
    </>
  )
}

LeaveJobConfirmationCard.propTypes = {
  onLeaveJobClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
}
