import {
  nameLabel,
  namePlaceholder,
  nameError,
  emailText,
  emailPLaceholder,
  emailAlreadySignedUp,
  dateOfBirthTLabel,
  dateOfBirthTooltip,
  dayLabel,
  mounthLabel,
  january,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
  yearLabel,
  genderText,
  dadoOpcinal,
  select,
  feminineText,
  maleText,
  notBinary,
  other,
  preferNotToSay,
  ethnicityText,
  white,
  parde,
  black,
  yellow,
  indie,
  state,
  city,
  areYouStudying,
  yesText,
  noText,
  schooling,
  juniorStage,
  middleStage,
  superiorStage,
  school_yearLabel,
  type_institutionLabel,
  publicSchool,
  privateSchool,
  createPasswordLabel,
  newPasswordLabel,
  confirmPassword,
  termsSignUp,
  agreeTerms,
  accountSettings,
  personalData,
} from '../../../../../../common/strings'

export const getSignUpStrings = () => {
  return {
    name: {
      label: nameLabel + '*',
      placeholder: namePlaceholder,
      dataTest: 'inputSignUpName',
      error: `<b>${nameError}</b>`,
    },
    email: {
      label: emailText + '*',
      placeholder: emailPLaceholder,
      dataTest: 'inputSignUpEmail',
      error: `<b>${emailAlreadySignedUp}</b>`,
    },
    birthDate: {
      label: dateOfBirthTLabel + '*',
      tooltip: dateOfBirthTooltip,
      placeholder: [
        {
          label: dayLabel,
          nameValue: 'day',
          maxLength: 2,
          dataTest: 'selectSignUpDayOfBirth',
          selectDataTest: 'containerSelectSignUpDayOfBirth',
          options: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
          ],
        },
        {
          label: mounthLabel,
          nameValue: 'month',
          maxLength: 3,
          dataTest: 'selectSignUpMonthOfBirth',
          selectDataTest: 'containerSelectSignUpMonthOfBirth',
          options: [
            { label: january, id: 0 },
            { label: february, id: 1 },
            { label: march, id: 2 },
            { label: april, id: 3 },
            { label: may, id: 4 },
            { label: june, id: 5 },
            { label: july, id: 6 },
            { label: august, id: 7 },
            { label: september, id: 8 },
            { label: october, id: 9 },
            { label: november, id: 10 },
            { label: december, id: 11 },
          ],
        },
        {
          label: yearLabel,
          nameValue: 'year',
          maxLength: 4,
          dataTest: 'selectSignUpYearOfBirth',
          selectDataTest: 'containerSelectSignUpYearOfBirth',
          options: [],
        },
      ],
    },
    gender: {
      label: genderText,
      tooltip: dadoOpcinal,
      placeholder: select,
      dataTest: 'selectSignUpEthnicity',
      selectDataTest: 'containerSelectSignUpEthnicity',
      options: [
        { label: feminineText, id: 'feminino' },
        { label: maleText, id: 'masculino' },
        { label: notBinary, id: 'noBinario' },
        { label: other, id: 'other' },
        { label: preferNotToSay, id: 'noSaid' },
      ],
    },
    ethnicity: {
      label: ethnicityText,
      tooltip: dadoOpcinal,
      placeholder: select,
      dataTest: 'selectSignUpEthnicity',
      selectDataTest: 'containerSelectSignUpEthnicity',
      options: [
        { label: white, id: 'branco' },
        { label: parde, id: 'pardo' },
        { label: black, id: 'negro' },
        { label: yellow, id: 'amarelo' },
        { label: indie, id: 'indigeno' },
      ],
    },
    state: {
      label: state,
      tooltip: dadoOpcinal,
      placeholder: select,
      dataTest: 'selectSignUpState',
      selectDataTest: 'containerSelectSignUpState',
      options: [{}],
    },
    city: {
      label: city,
      tooltip: dadoOpcinal,
      placeholder: select,
      selectDataTest: 'containerSelectSignUpCity',
      dataTest: 'selectSignUpCity',
      options: [{ nome: '' }],
    },
    you_are_studying: {
      label: areYouStudying,
      tooltip: dadoOpcinal,
      dataTest: 'radioSignUpAreYouStudying',
      placeholder: [
        { label: yesText, for: 'true' },
        { label: noText, for: 'false' },
      ],
    },
    schooling: {
      label: schooling,
      tooltip: dadoOpcinal,
      placeholder: select,
      dataTest: 'selectSignUpschooling',
      selectDataTest: 'containerSelectSignUpSchooling',
      options: [
        { label: juniorStage, id: 'ensFundamental' },
        { label: middleStage, id: 'ensMedio' },
        { label: superiorStage, id: 'ensSuperior' },
      ],
    },
    school_year: {
      label: school_yearLabel,
      tooltip: dadoOpcinal,
      placeholder: select,
      dataTest: 'selectSignUpSchoolYear',
      selectDataTest: 'containerSelectSignUpSchoolYear',
      options: [],
    },
    type_institution: {
      label: type_institutionLabel,
      tooltip: dadoOpcinal,
      dataTest: 'radioSignUpTypeOfInstitution',
      placeholder: [
        { label: publicSchool, for: 'publica' },
        { label: privateSchool, for: 'privada' },
      ],
    },
    newPassword: {
      label: createPasswordLabel + '*',
      placeholder: newPasswordLabel,
      dataTest: 'inputSignUpPassword',
    },
    confirmPassword: {
      label: confirmPassword + '*',
      placeholder: confirmPassword,
      dataTest: 'inputSignUpConfirmPassword',
    },
    terms: {
      label: termsSignUp,
      dataTest: 'checkboxSignUpTextTerm',
    },
    aceite: {
      dataTest: 'checkboxSignUpAcceptTerm',
      label: agreeTerms,
    },
    labels: {
      accountConfiguration: accountSettings,
      terms: termsSignUp,
      pessoalData: personalData,
    },
  }
}
