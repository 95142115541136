import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import {
  backText,
  playText,
  okText,
  editLabel,
  editAccount,
  confirmEditEmailAccount,
  confirmLeaveWithoutSave,
  editedAccountText,
  errorOnEditAccountText,
  exitText,
} from '../../../../../../common/strings'

import { getSignUpStrings } from './signUpStrings'
import { Input } from '../../../../../../components'
import { getStates, getCitys } from '../../../../../../services/API/location'

import { useStore } from '../../../../../../store/hooks'
import { types } from '../../../../../../store'

import { register, update, getUser } from '../../../../../../services/API/user'
import { set } from '../../../../../../services/storage'

import { Select, RadioGroup, Password, DateOfBirth } from './components/'
import { CardTerms } from '../../../../../../components/Cards'

import {
  Container,
  Title,
  ContainerAceite,
  ContainerButtons,
  FormContainer,
  AlertTerms,
} from './styles'

import {
  reportCompleteRegistration,
  reportUpdateAccount,
} from '../../../../../../services/analytics'

import {
  CheckBox,
  LabelCheckBox,
} from '../../../../../../components/Forms/Checkbox/styles'

import { useHistory } from 'react-router-dom'
import { ConfirmInfoCard } from '../../../../../../components/Cards/ConfirmInfoCard'
import { replaceDataTagFromString } from '../../../../../../common/utils'
import { IcAlert } from '../../../../../../assets/img'
import { Loading } from '../../../../../../components/Loading'
import { ButtonWithRadiusBorderComponent } from '../../../../../../components/Buttons/buttonWithRadiusBorder'

export const FormSignUp = ({ isEdit, closeSignUpCard, isSignUpInsideGame }) => {
  const { dispatch } = useStore()

  const signUpStrings = useMemo(getSignUpStrings, [])
  const [showTerms, setShowTerms] = useState(false)
  const [showConfirmCard, setShowConfirmCard] = useState(false)
  const [showConfirmLeaveWithoutSave, setShowConfirmLeaveWithoutSave] = useState(false)
  const [showAccountEditedAlert, setShowAccountEditedAlert] = useState(false)
  const [states, setStates] = useState([])
  const [citys, setCitys] = useState([])
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [showRequired, setShowRequired] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showErrorOnSubmit, setShowErrorOnSubmit] = useState(false)

  const schoolYearOptions = useMemo(getAllSchoolYear, [])
  const [currentSchoolYear, setCurrentSchoolYear] = useState([])
  const [changeEmailInfo, setChangeEmailInfo] = useState('')

  const history = useHistory()

  const [editDataSignUp, setEditDataSignUp] = useState({})
  const [dataFromBase, setDataFromBase] = useState({})
  const [data, setData] = useState({
    name: null,
    email: null,
    date_of_birth: null,
    ethnicity: null,
    gender: null,
    state: null,
    city: null,
    you_are_studying: null,
    schooling: null,
    school_year: null,
    type_institution: null,
    authentication: {
      local: {
        password: null,
      },
    },
    aceite: false,
  })

  function getAllSchoolYear() {
    return {
      ensFundamental: [
        { label: '1º ano', id: 'primeiroAnoEnsFundamental' },
        { label: '2º ano', id: 'segundoAnoEnsFundamental' },
        { label: '3º ano', id: 'terceiroAnoEnsFundamental' },
        { label: '4º ano', id: 'quartoAnoEnsFundamental' },
        { label: '5º ano', id: 'quintoAnoEnsFundamental' },
        { label: '6º ano', id: 'sextoAnoEnsFundamental' },
        { label: '7º ano', id: 'setimoAnoEnsFundamental' },
        { label: '8º ano', id: 'oitavoAnoEnsFundamental' },
        { label: '9º ano', id: 'nonoAnoEnsFundamental' },
      ],
      ensMedio: [
        { label: '1º ano', id: 'primeiroAnoEsnMedio' },
        { label: '2º ano', id: 'segundoAnoEsnMedio' },
        { label: '3º ano', id: 'terceiroAnoEsnMedio' },
      ],
      ensSuperior: [
        { label: '1º periodo', id: 'primeiroPeriodo' },
        { label: '2º periodo', id: 'segundoPeriodo' },
        { label: '3º periodo', id: 'terceiroPeriodo' },
        { label: '4º periodo', id: 'quartoPeriodo' },
        { label: '5º periodo', id: 'quintoPeriodo' },
        { label: '6º periodo', id: 'sextoPeriodo' },
        { label: '7º periodo', id: 'setimoPeriodo' },
        { label: '8º periodo', id: 'oitavoPeriodo' },
        { label: '9º periodo', id: 'nonoPeriodo' },
      ],
    }
  }

  useEffect(getUserDatas, [])
  function getUserDatas() {
    getUserData()
  }

  async function getUserData() {
    if (isEdit) {
      setShowLoading(true)
      const response = await getUser()
      setShowLoading(false)

      if (response && response.user) {
        setDataFromUser(response.user)
      } else {
        closeSignUpCard()
      }
    }
  }

  function setDataFromUser(dataUser) {
    if (dataUser) {
      if (dataUser.schooling) {
        changeSchoolYearOptions(dataUser.schooling)
      }

      if (typeof dataUser.you_are_studying === 'boolean') {
        dataUser.you_are_studying = dataUser.you_are_studying.toString()
      }

      setDataFromBase(dataUser)
      setData(dataUser)
    }
  }

  function setValueInData(value) {
    setData({ ...data, ...value })
  }

  useEffect(clearCity, [citys])
  function clearCity() {
    setValueInData({ city: null })
  }

  useEffect(getAllStates, [])
  function getAllStates() {
    const stateData = async () => {
      setStates(await getStates())
    }

    stateData()
  }

  async function getAllCitysFromUF(id, value) {
    const citysData = await getCitys(id)
    setCitys(citysData)
    setCurrentCityOnData(value)
  }

  function setCurrentCityOnData(value) {
    if (data) {
      const cityUser = data.city
      setData({ ...data, ...{ state: value, city: '' } })
      setData({ ...data, ...{ state: value, city: cityUser } })
    }
  }

  function resetStudyingInputs(value) {
    const inputStudying = {
      you_are_studying: value,
      schooling: null,
      school_year: null,
      type_institution: null,
    }

    setValueInData(inputStudying)
  }

  function setStudyingChanges(studyingValue) {
    if (studyingValue === 'true') {
      const value = { you_are_studying: studyingValue }
      setValueInData(value)
    } else {
      resetStudyingInputs(studyingValue)
    }
  }

  function changeSchoolYearOptions(schooling) {
    setCurrentSchoolYear(schoolYearOptions[schooling])
  }

  function configDatas(event) {
    const { name, id, value } = event.target

    if (name === 'state' && id) {
      getAllCitysFromUF(id, value)
    } else if (name === 'you_are_studying') {
      setStudyingChanges(value)
    } else if (name === 'schooling') {
      changeSchoolYearOptions(id)
    }
  }

  function setFormValue(event) {
    configDatas(event)
    const value = retrieveValueOfField(event.target)

    if (value && event.target.name !== 'state') {
      setValueInData(value)
    }
  }

  function retrieveValueOfField(target) {
    const { name } = target

    if (isInputOrRadioGroup(name)) {
      return { [name]: target.value }
    } else if (isSelect(name)) {
      return { [name]: target.id }
    } else if (isCheckbox(name)) {
      return { [name]: target.checked }
    }
  }

  function isInputOrRadioGroup(name) {
    const inputs = ['name', 'email', 'type_institution', 'state', 'city']
    return inputs.includes(name)
  }

  function isSelect(name) {
    const selects = ['ethnicity', 'gender', 'schooling', 'school_year']
    return selects.includes(name)
  }

  function isCheckbox(name) {
    const checkboxes = ['aceite']
    return checkboxes.includes(name)
  }

  function setReceviedValue(name, valueRecevied) {
    const value = { [name]: valueRecevied }
    setValueInData(value)
  }

  function setPassword(passw) {
    const value = {
      authentication: {
        local: {
          password: passw,
        },
      },
    }

    setValueInData(value)
  }

  const isIncompleteForm = useMemo(incompleteForm, [data])

  function isCompleteName(name) {
    const regName = /^([a-zA-Záâàéêèíîìóôòúûù]{1,}\s[a-zA-Záâàéêèíîìóôòúûù]{1,}'?-?[a-zA-Záâàéêèíîìóôòúûù]{1,}\s?([a-zA-Záâàéêèíîìóôòúûù]{1,})?)/
    return regName.test(name)
  }

  function incompleteForm() {
    if (isEdit) {
      return !(isCompleteName(data.name) && data.name && data.email && data.date_of_birth)
    }

    return !(
      isCompleteName(data.name) &&
      data.name &&
      data.email &&
      data.date_of_birth &&
      data.aceite &&
      data.authentication.local.password
    )
  }

  function hasInvalidEmail(status, msg) {
    return status === 400 && msg === 'This email is already registered.'
  }

  function finalizeRegisterUpdate(response, { finalFunction, analytics, isUpdate }) {
    if (response) {
      const { status } = response
      const invalidEmail = hasInvalidEmail(status, response.data.message)
      if (status === 201) {
        setErrorEmail(false)

        if (!isUpdate) {
          dispatch({ type: types.SET_USER_DETAILS, payload: response.data.user })
          set('user', response.data.user)
          dispatch({ type: types.SET_AUTH_TOKEN, payload: response.data.token })
          set('token', response.data.token)
        }

        analytics()

        finalFunction()
      } else if (invalidEmail) {
        setErrorEmail(true)
      }
    } else {
      setShowErrorOnSubmit(true)
    }
  }

  async function registerUser(dataSignUp) {
    setShowLoading(true)
    const response = await register(dataSignUp)
    setShowLoading(false)

    finalizeRegisterUpdate(response, {
      finalFunction: startGame,
      analytics: reportCompleteRegistration,
      isUpdate: false,
    })
  }

  function deleteUnusedProps(dataSignUp) {
    const unusedProps = ['_id', '__v', 'createdAt', 'updatedAt', 'is_active', 'authTypes']

    unusedProps.forEach((prop) => {
      delete dataSignUp[prop]
    })
  }

  async function updateUser(dataSignUp) {
    const userId = dataSignUp._id
    deleteUnusedProps(dataSignUp)
    const response = await update(dataSignUp, userId)

    finalizeRegisterUpdate(response, {
      finalFunction: openAccountEditedAlert,
      analytics: reportUpdateAccount,
      isUpdate: true,
    })
  }

  function openAccountEditedAlert() {
    setShowAccountEditedAlert(true)
  }

  function closeAccountEditedAlert() {
    setShowAccountEditedAlert(false)
    closeSignUpCard()
  }

  const hasEmailChanged = useMemo(() => {
    return data.email !== dataFromBase.email
  }, [data, dataFromBase])

  function submitForm(e) {
    e.preventDefault()

    setErrorName(!isCompleteName(data.name))
    if (isIncompleteForm) {
      setShowRequired(true)
    } else {
      setShowRequired(false)
      const dataSignUp = { ...data }

      delete dataSignUp.aceite
      const removeEmptyData = (obj) => {
        Object.keys(obj).forEach((key) => !obj[key] && delete obj[key])
      }

      removeEmptyData(dataSignUp)

      if (isEdit) {
        setEditDataSignUp(dataSignUp)
        checkChangedEmail(dataSignUp)
      } else {
        registerUser(dataSignUp)
      }
    }
  }

  function checkChangedEmail(dataSignUp) {
    if (hasEmailChanged) {
      openConfirmCard()
    } else {
      editUser(dataSignUp)
    }
  }

  function editUser(dataSignUp) {
    setShowLoading(true)
    updateUser(dataSignUp)
    setShowLoading(false)
  }

  function closeConfirmCardAndUpdadeUser() {
    closeConfirmCard()
    updateUser(editDataSignUp)
  }

  function startGame() {
    if (isSignUpInsideGame) {
      closeSignUpCard()
    } else {
      history.push('/home')
    }
  }

  function closeCardTerms() {
    setShowTerms(false)
  }

  function openCardTerms() {
    setShowTerms(true)
  }

  function closeConfirmCard() {
    setShowConfirmCard(false)
  }

  function openConfirmCard() {
    const newChangeEmailInfo = replaceDataTagFromString(
      confirmEditEmailAccount,
      data.email,
      '<email>',
    )
    setChangeEmailInfo(newChangeEmailInfo)
    setShowConfirmCard(true)
  }

  function hasEdition(obj1, obj2) {
    return JSON.stringify(obj1) !== JSON.stringify(obj2)
  }

  function closeConfirmLeaveWithoutSave() {
    setShowConfirmLeaveWithoutSave(false)
  }

  function closeErrorOnSubmit() {
    setShowErrorOnSubmit(false)
  }

  function closeCard() {
    if (isEdit && hasEdition(data, dataFromBase)) {
      setShowConfirmLeaveWithoutSave(true)
    } else {
      closeSignUpCard()
    }
  }

  function clearInput(name) {
    data[name] = ''
    setData(data)
  }

  return (
    <>
      {showLoading && <Loading />}

      {showTerms && <CardTerms closeCardTerms={closeCardTerms} />}

      {showConfirmCard && (
        <ConfirmInfoCard
          onConfirmClick={closeConfirmCardAndUpdadeUser}
          onCancelClick={closeConfirmCard}
          title={editAccount}
          paragraph={changeEmailInfo}
          confirmText={editLabel}
        />
      )}

      {showConfirmLeaveWithoutSave && (
        <ConfirmInfoCard
          onConfirmClick={closeSignUpCard}
          onCancelClick={closeConfirmLeaveWithoutSave}
          title={editAccount}
          paragraph={confirmLeaveWithoutSave}
          confirmText={exitText}
        />
      )}

      {showErrorOnSubmit && (
        <ConfirmInfoCard
          isOnlyInfo
          onConfirmClick={closeErrorOnSubmit}
          title={editAccount}
          paragraph={errorOnEditAccountText}
          confirmText={okText}
        />
      )}

      {showAccountEditedAlert && (
        <ConfirmInfoCard
          isOnlyInfo
          onConfirmClick={closeAccountEditedAlert}
          title={editAccount}
          paragraph={editedAccountText}
          confirmText={okText}
        />
      )}

      <form onSubmit={submitForm} autoComplete="off">
        <FormContainer isEdit>
          <Title isFist>{signUpStrings.labels.pessoalData}</Title>
          <Container>
            <Input
              objectString={signUpStrings.name}
              inputName="name"
              sendValue={setFormValue}
              isRequired={true}
              showRequired={showRequired}
              showError={errorName}
              value={data.name}
            />

            {!isEdit && (
              <Input
                objectString={signUpStrings.email}
                inputName="email"
                type="email"
                sendValue={setFormValue}
                isRequired={true}
                showRequired={showRequired}
                showError={errorEmail}
                clearInput={clearInput}
                value={data.email}
              />
            )}

            <DateOfBirth
              data-test="ContainerSignUpDateOfBirt"
              signUpStrings={signUpStrings}
              showRequired={showRequired}
              dateOfBirth={data.date_of_birth}
              sendCompleteDateOfBith={(dateOfBirth) =>
                setReceviedValue('date_of_birth', dateOfBirth)
              }
            />

            {isEdit && <div className="divGrid"></div>}

            <Select
              objectString={signUpStrings.gender}
              original={[...signUpStrings.gender.options]}
              inputName="gender"
              id={data.gender}
              sendValue={setFormValue}
            />

            <Select
              objectString={signUpStrings.ethnicity}
              original={[...signUpStrings.ethnicity.options]}
              inputName="ethnicity"
              id={data.ethnicity}
              sendValue={setFormValue}
            />

            <div className="divGrid"></div>

            <Select
              objectString={{ ...signUpStrings.state, ...{ options: states } }}
              original={states}
              withAutocomplete={true}
              value={data.state}
              inputName="state"
              sendValue={setFormValue}
            />

            <Select
              objectString={{ ...signUpStrings.city, ...{ options: citys } }}
              original={citys}
              withAutocomplete={true}
              inputName="city"
              value={data.city}
              dataValue={data}
              sendValue={setFormValue}
              clearSelect={clearInput}
            />

            <RadioGroup
              objectString={signUpStrings.you_are_studying}
              radioGroupName="you_are_studying"
              sendValue={setFormValue}
              value={data.you_are_studying}
            />

            {data?.you_are_studying === 'true' && (
              <>
                <Select
                  objectString={signUpStrings.schooling}
                  inputName="schooling"
                  id={data.schooling}
                  sendValue={setFormValue}
                />

                <Select
                  objectString={{
                    ...signUpStrings.school_year,
                    ...{ options: currentSchoolYear || [] },
                  }}
                  id={data.school_year}
                  inputName="school_year"
                  sendValue={setFormValue}
                />

                <RadioGroup
                  objectString={signUpStrings.type_institution}
                  radioGroupName="type_institution"
                  sendValue={setFormValue}
                  value={data.type_institution}
                />
              </>
            )}
          </Container>

          {!isEdit && (
            <>
              <Title>{signUpStrings.labels.accountConfiguration}</Title>
              <Container>
                <Password
                  data-test="ContainerSignUpPassword"
                  signUpStrings={signUpStrings}
                  showRequired={showRequired}
                  sendPassword={setPassword}
                />
              </Container>

              <Title>{signUpStrings.labels.terms}</Title>
              <ContainerAceite>
                <CheckBox
                  id="aceite"
                  type="checkbox"
                  name="aceite"
                  onChange={setFormValue}
                  data-test={signUpStrings.aceite.dataTest}
                />
                <LabelCheckBox
                  onClick={openCardTerms}
                  data-test="acceptTerms"
                  dangerouslySetInnerHTML={{
                    __html: signUpStrings.aceite.label,
                  }}
                  htmlFor="aceite"
                />
              </ContainerAceite>

              {showRequired && !data.aceite && (
                <AlertTerms>
                  <p>
                    <IcAlert height="24px" width="24px" />
                    Para prosseguir, é preciso concordar com os Termos de Uso e
                    Privacidade do Game VUCA.
                  </p>
                </AlertTerms>
              )}
            </>
          )}
        </FormContainer>

        <ContainerButtons>
          <ButtonWithRadiusBorderComponent
            color="white"
            onClick={closeCard}
            text={backText}
            data_test="backSignUp"
          />
          <ButtonWithRadiusBorderComponent
            type="submit"
            color="white"
            onClick={submitForm}
            text={isEdit ? editLabel : playText}
            disabled={isEdit && !hasEdition(data, dataFromBase)}
            data_test="playSignUp"
          />
        </ContainerButtons>
      </form>
    </>
  )
}

FormSignUp.propTypes = {
  isEdit: PropTypes.bool,
  closeSignUpCard: PropTypes.func.isRequired,
  isSignUpInsideGame: PropTypes.bool,
}

FormSignUp.defaultProps = {
  isEdit: false,
  isSignUpInsideGame: false,
}
