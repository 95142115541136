import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { EndGameAsset } from '../../assets/img/endGameCards/index.'
import { isMobileSize, replaceKeyToText } from '../../common/utils'
import {
  playerWinTitle,
  playerWinDescribe,
  playerLoserFistParagraph,
  playerLoserSecondParagraph,
  playerLoserThirdParagraph,
  superdiorStageEndedAlertMessage,
  nextText,
  playAgainText,
  exitText,
} from '../../common/strings'

import { Card } from '..'
import {
  BackgroundBlur,
  Container,
  ContentCard,
  Figure,
  Paragraphs,
  ContainerButtons,
  Title,
  Flag,
  IconPlayWin,
} from './styles'
import { CardFlagLarge, StarsPlayerWin } from '../../assets/img'

import SoundPlayWin from '../../assets/music/player_win.mp3'
import SoundPlayLoser from '../../assets/music/game_over.mp3'

import { Share } from '../Share'
import { useStore } from '../../store/hooks'
import { Sound } from '../Sound'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'

export function CardEndGame({
  character,
  maxPoints,
  playerWin,
  openWinMedalCard,
  openFeedBack,
  openShareCard,
}) {
  const isMobile = useMemo(isMobileSize, [])
  const { store } = useStore()
  const { sound } = store

  const firstParagraphOfLoser = React.useMemo(retrieveParagraphWithCitedData, [])
  function retrieveParagraphWithCitedData() {
    const replacedWithName = replaceKeyToText(
      playerLoserFistParagraph,
      '<name>',
      character.name,
    )
    const replacedWithNameAndMaxPoint = replaceKeyToText(
      replacedWithName,
      '<maxPoints>',
      maxPoints,
    )
    return replacedWithNameAndMaxPoint
  }

  return (
    <>
      {sound && (
        <Sound loop={false} mp3Sound={playerWin ? SoundPlayWin : SoundPlayLoser} />
      )}
      <Container>
        <Card isSmall data_test="CardEndGame" maxWidth="505px">
          {playerWin && (
            <Flag>
              <CardFlagLarge />
            </Flag>
          )}
          <ContentCard playerWin={playerWin}>
            {playerWin && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: replaceKeyToText(playerWinTitle, '<name>', 'Você'),
                }}
              />
            )}

            <Figure>
              {playerWin ? (
                <IconPlayWin>
                  <StarsPlayerWin />
                </IconPlayWin>
              ) : (
                <EndGameAsset height="36" style={{ marginTop: '20px' }} />
              )}
            </Figure>

            <section data-test="endGameCardContent">
              {playerWin && (
                <>
                  <Paragraphs
                    dangerouslySetInnerHTML={{
                      __html: replaceKeyToText(
                        superdiorStageEndedAlertMessage,
                        '<name>',
                        character.name,
                      ),
                    }}
                  />
                  <br />
                  <Paragraphs dangerouslySetInnerHTML={{ __html: playerWinDescribe }} />
                </>
              )}

              {!playerWin && (
                <>
                  {[
                    firstParagraphOfLoser,
                    playerLoserSecondParagraph,
                    playerLoserThirdParagraph,
                  ].map((__html, index) => (
                    <Paragraphs
                      key={index}
                      dangerouslySetInnerHTML={{ __html }}
                      withSpace
                    />
                  ))}
                </>
              )}
            </section>

            <ContainerButtons>
              {playerWin && (
                <ButtonWithRadiusBorderComponent
                  onClick={openWinMedalCard}
                  text={nextText}
                />
              )}

              {!playerWin && (
                <>
                  <ButtonWithRadiusBorderComponent
                    onClick={() => openFeedBack(true)}
                    text={playAgainText}
                  />

                  <ButtonWithRadiusBorderComponent
                    withoutMarginTop={isMobile}
                    color="white"
                    onClick={() => openFeedBack(false)}
                    text={exitText}
                  />
                </>
              )}
            </ContainerButtons>
            <Share openShareCard={() => openShareCard(true)} />
          </ContentCard>
        </Card>
      </Container>
      <BackgroundBlur />
    </>
  )
}

CardEndGame.propTypes = {
  character: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
  }),
  maxPoints: PropTypes.number.isRequired,
  playerWin: PropTypes.bool.isRequired,
  openWinMedalCard: PropTypes.func.isRequired,
  openShareCard: PropTypes.func.isRequired,
  openFeedBack: PropTypes.func.isRequired,
}
