import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  playText,
  backText,
  playWithoutLoginTitle,
  playWithoutLoginText,
} from '../../../../common/strings'

import { reportPlayWithoutLogin } from '../../../../services/analytics'

import { CardWithHeader } from '../../../../components'
import { BackgroundBlur, Container } from '../../../../components/GameCards/styles'

import { ContentCard, ContainerButton } from './styles'
import { ButtonWithRadiusBorderComponent } from '../../../../components/Buttons/buttonWithRadiusBorder'

import { isMobileSize } from '../../../../common/utils'

export const CardPlayWithoutLogin = ({ closeCardWithoutLogin }) => {
  const isMobile = useMemo(isMobileSize, [])
  function playWithoutLogin() {
    reportPlayWithoutLogin()
    closeCardWithoutLogin(true)
  }

  function back() {
    closeCardWithoutLogin(false)
  }

  return (
    <>
      <Container>
        <CardWithHeader
          titleCard={playWithoutLoginTitle}
          backgroundColorHeader="white"
          isSmall
          data_test="playWithoutLoginCard">
          <ContentCard>
            <p dangerouslySetInnerHTML={{ __html: playWithoutLoginText }} />

            <ContainerButton>
              <ButtonWithRadiusBorderComponent
                color="white"
                type="button"
                onClick={back}
                data_test="backLoginButton"
                text={backText}
              />

              <ButtonWithRadiusBorderComponent
                withoutMarginTop={isMobile}
                data_test="goToGameWithoutLoginButton"
                onClick={playWithoutLogin}
                text={playText}
              />
            </ContainerButton>
          </ContentCard>
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </>
  )
}

CardPlayWithoutLogin.propTypes = {
  closeCardWithoutLogin: PropTypes.func.isRequired,
}
