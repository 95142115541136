import { useMemo } from 'react'

import {
  ImgQuiz,
  ImgSituation,
  ImgAbility,
  ImgJob,
  ImgInspiration,
} from '../../assets/img/categoriesCards'

import {
  knowledgeCategory,
  knowledgeMessage,
  inspirationCategory,
  inspirationMessage,
  jobCategory,
  jobMessage,
  challengeCategory,
  challengeMessage,
  skillsCategory,
  skillsMessage,
} from '../../common/strings'

export function useContentOfTextOnUnderstandingCards(categoryName) {
  const stringUnderstandingCard = {
    knowledge: {
      title: knowledgeCategory,
      message: knowledgeMessage,
    },
    inspiration: {
      title: inspirationCategory,
      message: inspirationMessage,
    },
    job: {
      title: jobCategory,
      message: jobMessage,
    },
    challenge: {
      title: challengeCategory,
      message: challengeMessage,
    },
    skills: {
      title: skillsCategory,
      message: skillsMessage,
    },
  }

  const contentCard = useMemo(
    () => ({
      challenge: {
        ...stringUnderstandingCard.challenge,
        Icon: ImgSituation,
      },
      inspiration: {
        ...stringUnderstandingCard.inspiration,
        Icon: ImgInspiration,
      },
      knowledge: {
        ...stringUnderstandingCard.knowledge,
        Icon: ImgQuiz,
      },
      skills: {
        ...stringUnderstandingCard.skills,
        Icon: ImgAbility,
      },
      job: {
        ...stringUnderstandingCard.job,
        Icon: ImgJob,
      },
    }),
    [stringUnderstandingCard],
  )[categoryName]

  return {
    ...contentCard,
  }
}
