import React from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '..'
import { ConfirmationButtonContainer, Paragraph } from './styles'
import {
  BackgroundBlur,
  ContentCard,
  Container as CardContainer,
} from '../../GameCards/styles'

import { cancelText } from '../../../common/strings'
import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'

export const ConfirmInfoCard = ({
  isOnlyInfo,
  onConfirmClick,
  onCancelClick,
  title,
  paragraph,
  confirmText,
}) => {
  return (
    <>
      <CardContainer>
        <CardWithHeader isSmall data_test="cardInfoConfirmation" titleCard={title}>
          <ContentCard data-test="cardInfoConfirmationContent">
            <Paragraph
              data-test="cardInfoConfirmationParagraph"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
            <ConfirmationButtonContainer isOnlyInfo={isOnlyInfo}>
              {!isOnlyInfo && (
                <ButtonWithRadiusBorderComponent
                  data_test="cancelButton"
                  color="white"
                  type="button"
                  onClick={onCancelClick}
                  text={cancelText}
                />
              )}

              <ButtonWithRadiusBorderComponent
                data_test="confirmButton"
                type="button"
                onClick={onConfirmClick}
                text={confirmText}
              />
            </ConfirmationButtonContainer>
          </ContentCard>
        </CardWithHeader>
      </CardContainer>
      <BackgroundBlur />
    </>
  )
}

ConfirmInfoCard.propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  isOnlyInfo: PropTypes.bool,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
}

ConfirmInfoCard.defaultProps = {
  onCancelClick: () => {},
  isOnlyInfo: false,
}
