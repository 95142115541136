import { string } from 'prop-types'
import React from 'react'

import { ToolTip } from './styles'

export const Tooltip = ({ tooltipMsg }) => (
  <ToolTip tooltipMsg={tooltipMsg}>
    <b>?</b>
  </ToolTip>
)

Tooltip.propTypes = {
  tooltipMsg: string,
}
