import React from 'react'
import PropTypes from 'prop-types'

import { howToPlayTitle, playText, howToPlayText, backText } from '../../common/strings'

import { HowToPlayImageCard } from '../../assets/img'
import { CardWithHeader } from '../../components'
import { BackgroundBlur, Container } from '../GameCards/styles'

import { ContentCard, ContainerButton } from './styles'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'

export const CardHowToPlay = ({ closeCardHowToPlay, back }) => (
  <>
    <Container>
      <CardWithHeader
        titleCard={howToPlayTitle}
        backgroundColorHeader="white"
        isSmall
        data_test="howToPlayCard">
        <ContentCard>
          <figure>
            <HowToPlayImageCard width="100%" />
          </figure>

          <p dangerouslySetInnerHTML={{ __html: howToPlayText }} />

          <ContainerButton>
            <ButtonWithRadiusBorderComponent
              color="white"
              onClick={back}
              data_test="leaveGame"
              text={backText}
            />

            <ButtonWithRadiusBorderComponent
              data_test="goToGame"
              onClick={closeCardHowToPlay}
              text={playText}
            />
          </ContainerButton>
        </ContentCard>
      </CardWithHeader>
    </Container>
    <BackgroundBlur />
  </>
)

CardHowToPlay.propTypes = {
  closeCardHowToPlay: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}
