import React from 'react'
import PropTypes, { shape } from 'prop-types'

import { IcArrowRight } from '../../../../../../assets/img'

import {
  ContainerCharactersNButtons,
  BalloonOfDescriptionCharacter,
} from '../../../styles'
import { CircularButtonComponent } from '../../../../../../components/Buttons/circularButton'

export const CharactersDetailsMobile = ({ characters, dataChooseCharacters }) => (
  <>
    <ContainerCharactersNButtons>
      <CircularButtonComponent
        color="lemonGreen"
        rotateImg
        disabled={dataChooseCharacters.previous.isDisabled}
        onClick={dataChooseCharacters.previous.handleClick}>
        <IcArrowRight width="18" height="18" />
      </CircularButtonComponent>

      <figure>{characters[dataChooseCharacters.indexCurrentCharacter].image()}</figure>

      <CircularButtonComponent
        color="lemonGreen"
        disabled={dataChooseCharacters.next.isDisabled}
        onClick={dataChooseCharacters.next.handleClick}>
        <IcArrowRight width="18" height="18" />
      </CircularButtonComponent>
    </ContainerCharactersNButtons>

    <BalloonOfDescriptionCharacter>
      <p>{characters[dataChooseCharacters.indexCurrentCharacter].details}</p>
    </BalloonOfDescriptionCharacter>
  </>
)

const previousAndNextProps = PropTypes.shape({
  isDisabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
}).isRequired

CharactersDetailsMobile.propTypes = {
  characters: PropTypes.arrayOf(
    shape({
      image: PropTypes.func.isRequired,
      details: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  dataChooseCharacters: PropTypes.shape({
    indexCurrentCharacter: PropTypes.number.isRequired,
    previous: previousAndNextProps,
    next: previousAndNextProps,
  }),
}
