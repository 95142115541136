import { useState, useMemo } from 'react'

import {
  ImgQuiz,
  ImgAbility,
  ImgJob,
  ImgSituation,
} from '../../assets/img/categoriesCards'

export function useLogicCard({
  currentCard,
  close,
  givePoint,
  addCondition,
  removeCondition,
}) {
  const [indexSelectedOptions, setIndexSelectedOptions] = useState(null)

  const [done, setDone] = useState(false)

  const disableButton = useMemo(() => indexSelectedOptions === null, [
    indexSelectedOptions,
  ])

  const { title, Icon } = useMemo(
    () => ({
      challenge: {
        title: 'Situação',
        Icon: ImgSituation,
      },
      knowledge: {
        title: 'Quiz',
        Icon: ImgQuiz,
      },
      inspiration: {
        title: 'Inspiração',
        icon: 'baloon',
      },
      skills: {
        title: 'Habilidades',
        Icon: ImgAbility,
      },
      job: {
        title: 'Trabalho',
        Icon: ImgJob,
      },
    }),
    [],
  )[currentCard.category]

  const paragraphsContent = useMemo(setParagraphText, [done])
  function setParagraphText() {
    if (done) {
      return currentCard.options[indexSelectedOptions].feedback
    }
    return currentCard.question
  }

  function selectOption(index) {
    return () => {
      if (indexSelectedOptions === index) {
        setIndexSelectedOptions(null)
      } else {
        setIndexSelectedOptions(index)
      }
    }
  }

  function actionButton() {
    if (!done) {
      setDone(true)
      return
    }
    givePointsAndClose(currentCard.options[indexSelectedOptions])
  }

  function givePointsAndClose(optionSelected) {
    if (optionSelected.conditions?.length > 0) {
      optionSelected.conditions.forEach((condition) => {
        addCondition(condition)
      })
    }
    givePoint(optionSelected.point)
    close()
  }
  const dataOfButton = useMemo(dataButton, [indexSelectedOptions, done])
  function dataButton() {
    if (!currentCard.has_interaction) {
      return {
        text: 'ok',
        disableButton: false,
        actionButton() {
          givePointsAndClose(currentCard.options[0])
        },
      }
    }

    return {
      text: !done ? 'Escolher' : 'OK',
      disableButton,
      actionButton,
    }
  }

  // remove
  const pointsDetails = useMemo(points, [indexSelectedOptions])
  function points() {
    const { point } = currentCard.options[indexSelectedOptions] || currentCard.options[0]
    const isNegative = point <= 0
    return {
      point: formatPoints(point),
      isNegative,
    }
  }

  function formatPoints(point) {
    if (point > 0) {
      return `+${point}xp`
    }
    return `${point}xp`
  }

  return {
    title,
    Icon,
    indexSelectedOptions,
    done,
    disableButton,
    paragraphsContent,
    selectOption, // ok
    dataOfButton,
    pointsDetails,
  }
}
