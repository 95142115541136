import React from 'react'
import PropTypes from 'prop-types'

import { CheckBox } from '..'
import { OptionCard, OptionTextContainer } from './styles'

export const Questions = ({ options, indexSelectedOptions, selectOption }) =>
  options.map(({ response_text }, index) => {
    const check = indexSelectedOptions === index
    const selectWithCurrentIndex = selectOption(index)
    return (
      <OptionCard
        data-test={`option-${index}`}
        key={index}
        onClick={selectWithCurrentIndex}
        checked={check}>
        <div className="checkboxQuestion">
          <CheckBox retrieveCheckOrUnchecked={selectWithCurrentIndex} checked={check} />
        </div>
        <OptionTextContainer data-test={`option-text-${index}`}>
          <p dangerouslySetInnerHTML={{ __html: response_text }} />
        </OptionTextContainer>
      </OptionCard>
    )
  })

Questions.propTypes = {
  options: PropTypes.arrayOf({
    response_text: PropTypes.string.isRequired,
    point: PropTypes.number.isRequired,
    feedback: PropTypes.string.isRequired,
  }).isRequired,
  indexSelectedOptions: PropTypes.any.isRequired,
  selectOption: PropTypes.func.isRequired,
}
