import React, { useRef } from 'react'

import { Button, DisplayFlex } from './styles'
import { func, object } from 'prop-types'
import { useStore } from '../../../../store/hooks'

import ClickButton from '../../../../assets/music/click_buttons.mp3'

export const ButtonWithRadiusBorderAndIcon = ({ buttonValues, Icon, sendClick }) => {
  const { store } = useStore()
  const { sound } = store
  const audio = useRef()

  async function sendOnClick(e) {
    if (sound && audio.current !== null) {
      audio.current.play()
    }

    setTimeout(function () {
      sendClick(e)
    }, 300)
  }

  return (
    <>
      <audio ref={audio}>
        <source src={ClickButton} type="audio/mpeg" />
      </audio>
      <Button
        type="button"
        colors={buttonValues.colors}
        onClick={sendOnClick}
        data-test={buttonValues.dataTest}>
        <DisplayFlex justifyContent="center">
          <figure>
            <Icon width="16" height="16" />
          </figure>
          <div className="buttonValue">{buttonValues.value}</div>
        </DisplayFlex>
      </Button>
    </>
  )
}

ButtonWithRadiusBorderAndIcon.propTypes = {
  buttonValues: object,
  Icon: object,
  sendClick: func.isRequired,
}
