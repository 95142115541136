import React from 'react'

import { Body, Footer } from './components'

import { CardWithHeader } from '../../../components'

import { chooseACharacterText } from '../../../common/strings'
import { Container } from './styles'

export const CharactersCard = () => (
  <Container>
    <div className="choiceCharacterCard">
      <CardWithHeader
        titleCard={chooseACharacterText}
        backgroundColorHeader="pistachioGreen"
        handleClickCloseButton={() => {}}
        withFooter={false}
        data_test="CharacterCard">
        <Body />
        <Footer />
      </CardWithHeader>
    </div>
  </Container>
)
