import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { BackgroundBlur, Container } from '../../../GameCards/styles'
import { CardWithHeader } from '../../../Cards'

import { doLogin, infoLogin, orText } from '../../../../common/strings'
import { LoginEmailCollunm } from '../../../../pages/Login/components/LoginEmail'

import { ContentCard, InfoLoginCard, OrText } from './styles'
import { LoginSocialMediaCollunm } from '../../../../pages/Login/components/LoginSocialMedia'
import { Loading } from '../../../Loading'
import { getLoginStrings } from '../../../../pages/Login/loginStrings'

export const LoginCard = ({ showSignUpCard, closeLoginCard }) => {
  const [showLoading, setShowLoading] = useState(false)
  const loginStrings = useMemo(getLoginStrings, [])

  return (
    <>
      {showLoading && <Loading />}
      <Container>
        <CardWithHeader
          titleCard={doLogin}
          withCloseButton={true}
          handleClickCloseButton={closeLoginCard}
          data_test="LoginCard">
          <ContentCard>
            <InfoLoginCard>{infoLogin}</InfoLoginCard>

            <div>
              <LoginEmailCollunm
                isLoginCard
                showSignUpCard={showSignUpCard}
                closeLoginCard={closeLoginCard}
                handleLoading={setShowLoading}
                loginStrings={loginStrings}
              />
            </div>

            <OrText>{orText}</OrText>

            <div>
              <LoginSocialMediaCollunm
                isLoginCard
                showSignUpCard={showSignUpCard}
                closeLoginCard={closeLoginCard}
                loginStrings={loginStrings}
              />
            </div>
          </ContentCard>
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </>
  )
}

LoginCard.propTypes = {
  showSignUpCard: PropTypes.func.isRequired,
  closeLoginCard: PropTypes.func,
}
