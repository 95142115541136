import React from 'react'
import { ThemeProvider } from 'styled-components'

import { AppStore } from './store'
import { Routes } from './routes'
import { theme, Reset, Animations } from './styles'

export const App = () => (
  <AppStore>
    <ThemeProvider theme={theme}>
      <Routes />
      <Reset />
      <Animations />
    </ThemeProvider>
  </AppStore>
)
