import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ExpTagContainer, Tooltip } from '../../ProgressBar/styles'

import {
  andText,
  knowledgeCategory,
  leaveJobCardTitle,
  leaveJobInformation,
  nowWorthHalfThePointsText,
  skillsCategory,
  startJobCardParagraph,
  startJobTooltipTitle,
  theTilesOfText,
} from '../../../common/strings'

import { replaceKeyToText } from '../../../common/utils'

import { JobButtonContainer, BandJob, LatchLeaveJob } from './styles'
import { IcJob, IcX, IcXCircle } from '../../../assets/img'

import { gamePropTypes } from '../../../game'

export const BandLeaveJob = ({ G, onJobButtonClick, showLeaveJobButton }) => {
  const [showTooltipEnterJob, setShowTooltipEnterJob] = useState(true)
  const [showTooltipLeaveJob, setShowTooltipLeaveJob] = useState(false)

  const tooltipEnterJob = {
    widthSize: 310,
    heightSize: 225,
    afterTop: -232,
    afterLeft: 1,
    afterTopMobile: -212,
    afterLeftMobile: 255,
    marginTop: 115,
    marginLeft: 1,
    marginTopMobile: 130,
    marginLeftMobile: -310,
  }

  const tooltipLeaveJob = {
    widthSize: 310,
    heightSize: 145,
    afterTop: -153,
    afterLeft: 1,
    afterTopMobile: -153,
    afterLeftMobile: 255,
    marginTop: 110,
    marginLeft: 10,
    marginTopMobile: 140,
    marginLeftMobile: -310,
  }

  useEffect(() => {
    setTimeout(function () {
      setShowTooltipEnterJob(false)
    }, 10000)
  }, [])

  useEffect(() => {
    if (showLeaveJobButton) {
      setShowTooltipLeaveJob(true)

      setTimeout(function () {
        setShowTooltipLeaveJob(false)
      }, 10000)
    }
  }, [showLeaveJobButton])

  return (
    <div>
      <JobButtonContainer showLeaveJobButton={showLeaveJobButton}>
        <BandJob>
          <IcJob />
        </BandJob>
        {showLeaveJobButton && (
          <LatchLeaveJob onClick={onJobButtonClick} data-test="leaveJobButton">
            <b>{leaveJobCardTitle}</b>
            <IcXCircle />
          </LatchLeaveJob>
        )}
      </JobButtonContainer>

      {showTooltipEnterJob && (
        <>
          <Tooltip styles={tooltipEnterJob} withCloseButton>
            <ExpTagContainer>
              <b
                dangerouslySetInnerHTML={{
                  __html: replaceKeyToText(
                    startJobTooltipTitle,
                    '<name>',
                    G.character.name,
                  ),
                }}
              />
              <IcX
                onClick={() => setShowTooltipEnterJob(false)}
                data-test="closeEnterJobInfo"
              />
            </ExpTagContainer>
            <p
              dangerouslySetInnerHTML={{
                __html: replaceKeyToText(
                  startJobCardParagraph,
                  '<name>',
                  G.character.name,
                ),
              }}
            />
            <p>
              {theTilesOfText}
              <b>
                {` ${skillsCategory} ${andText} ${knowledgeCategory} `}
                {nowWorthHalfThePointsText}
              </b>
            </p>
          </Tooltip>
        </>
      )}

      {showTooltipLeaveJob && (
        <>
          <Tooltip styles={tooltipLeaveJob} withCloseButton>
            <ExpTagContainer>
              <b
                dangerouslySetInnerHTML={{
                  __html: leaveJobCardTitle,
                }}
              />
              <IcX
                onClick={() => setShowTooltipLeaveJob(false)}
                data-test="closeLeaveJobInfo"
              />
            </ExpTagContainer>
            <p
              dangerouslySetInnerHTML={{
                __html: leaveJobInformation,
              }}
            />
          </Tooltip>
        </>
      )}
    </div>
  )
}

BandLeaveJob.propTypes = {
  G: gamePropTypes.G,
  onJobButtonClick: PropTypes.func.isRequired,
  showLeaveJobButton: PropTypes.bool.isRequired,
}
