import styled, { css } from 'styled-components'

import backgroundCloudsDesktop from '../../assets/img/backgrounds/backgroundCloudsDesktop.svg'
import backgroundCloudsMobile from '../../assets/img/backgrounds/backgroundCloudsMobile.svg'

export const Container = styled.div`
  background-image: url(${backgroundCloudsMobile});
  background-color: ${({ theme }) => theme.colors.veryLightBlue};
  object-fit: contain;
  width: 100%;
  padding: 1rem;
  background-size: 102%;
  background-position-x: -3px;
  height: 100vh;

  .logo {
    display: flex;
    justify-content: center;
  }

  .logo svg {
    font-family: MilkyNice;
    width: 236px;
    height: 70px;
  }

  @media screen and (min-width: 1024px) {
    background-image: url(${backgroundCloudsDesktop});
    height: 100vh;

    .logo svg {
      font-family: MilkyNice;
      height: 96px;
      width: 324px;
    }
  }
`

export const InvalidLogin = styled.div`
  margin-top: -8px;
  margin-bottom: 20px;
  padding-left: 25px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bolder;
  line-height: 1.67;
  letter-spacing: -0.05px;
  color: ${({ theme }) => theme.colors.orange};
  transition: all 2s;
  display: none;

  ${({ isValidLogin }) =>
    typeof isValidLogin === 'boolean' &&
    !isValidLogin &&
    css`
      display: block;
    `}
`

export const InputLoginContainer = styled.div`
  display: grid;
  gap: 10px;
  padding-top: 10px;
`

export const LabelDontHaveAccount = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    width: 272px;
    height: 34px;
    cursor: pointer;
    font-family: MilkyNice;
    font-size: 14px;
    letter-spacing: -0.06px;
    text-align: center;
    font-weight: bold;
    padding-top: 20px;

    b {
      color: ${({ theme }) => theme.colors.EmeraldGreen};
    }
  }
`

export const DisplayFlex = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  width: 100%;

  ${({ withoutPaddingBottom }) =>
    withoutPaddingBottom &&
    css`
      padding-bottom: 0;
    `}

  @media screen and (max-width: 1024px) {
    .signUpTop {
      visibility: hidden;
      position: absolute;
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 10px;

    .signUpBottom {
      display: none;
      visibility: hidden;
    }
  }
`

export const ForgetPassword = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.EmeraldGreen};
  width: 162px;
  height: 20px;
  font-family: MilkyNice;
  font-size: 14px;
  padding-top: 8px;
  cursor: pointer;
  text-align: right;
  letter-spacing: -0.06px;
`

export const LoginTitle = styled.div`
  width: 289px;
  height: 53px;
  font-family: MilkyNice;
  font-size: 24px;
  letter-spacing: -0.11px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const LoginContainer = styled.div`
  display: grid;
  gap: 1rem;
  justify-items: center;
  padding: 100px;
  grid-template-columns: 1fr;

  padding: 30px 0 calc(25% - 85px);

  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 60px calc(25% - 85px);
    gap: 89px;
  }
`

export const Side = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`

export const LoginEmail = styled.div``

export const LoginSocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const ButtonWithRadiusBorder = styled.button`
  border: solid 0.2rem ${({ theme, borderColor }) => theme.colors[borderColor]};
  border-radius: 2rem;
  box-shadow: -2.5px 3px ${({ theme, borderColor }) => theme.colors[borderColor]};
  color: ${({ theme, colorText }) => theme.colors[colorText]};
  font-family: MilkyNice;
  padding: 0.5rem 1.12rem;
  margin-top: 1rem;
  text-decoration: none;
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};

  min-width: 130px;
  max-height: 45px;

  @media screen and (min-width: 1024px) {
    padding: 0.7rem 2rem;
  }

  &:focus,
  &:hover {
    background: none;
    background-color: ${({ theme }) => theme.colors.orangeButtonFocused};
  }

  &:disabled {
    background: none;
    background-color: ${({ theme }) => theme.colors.veryLightPink};
    border: solid 0.2rem ${({ theme }) => theme.colors.brownGrey};
    box-shadow: -2.5px 3px ${({ theme }) => theme.colors.brownGrey};
    color: ${({ theme }) => theme.colors.brownGrey};
  }
`
