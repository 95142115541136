import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Card } from '..'
import { CardContent, ContainerButtons } from './styles'
import { Paragraph } from '../cardWithFlag/styles'
import {
  exitText,
  playAgainText,
  seeMedalText,
  winMedalParagraph,
  winMedalText,
} from '../../../common/strings'
import { isMobileSize, replaceKeyToText } from '../../../common/utils'
import { Share } from '../../Share'
import { ButtonWithRadiusBorderComponent } from '../../Buttons'
import { TextMedal } from '../../Account/components/medalCard/styles'
import { Container } from '../../CardEndGame/styles'
import { get } from '../../../services/storage'
import { setMedal } from '../../../services/API/medals'
import { gamePropTypes } from '../../../game'
import { setGame } from '../../../services/API/game'
import { useStore } from '../../../store/hooks'
import { types } from '../../../store'

export const WinMedalCard = ({
  G,
  openFeedBack,
  openShareCard,
  openMedalCard,
  character,
  Icon,
}) => {
  const { dispatch } = useStore()
  const isMobile = useMemo(isMobileSize, [])
  const isLogged = useMemo(() => {
    return !!get('token')
  }, [])

  useEffect(setNewMedal, [])
  function setNewMedal() {
    if (isLogged) {
      saveGame()
      const object_medal = {
        [character.toLowerCase()]: true,
      }

      setMedal(object_medal)
    }
  }

  const saveGame = async () => {
    const payload = {
      user: get('user').id,
      game: G,
    }

    await setGame(payload)
    dispatch({ type: types.SET_G, payload: payload.game })
  }

  return (
    <>
      <Container>
        <Card data_test="CardWinMedal" isSmall maxWidth="700px">
          <CardContent>
            <Icon />
            <TextMedal
              dangerouslySetInnerHTML={{
                __html: replaceKeyToText(winMedalText, '<name>', character),
              }}
            />
            <Paragraph>{winMedalParagraph}</Paragraph>
            <ContainerButtons>
              {isLogged && (
                <ButtonWithRadiusBorderComponent
                  onClick={openMedalCard}
                  width={197}
                  text={seeMedalText}
                />
              )}
              <ButtonWithRadiusBorderComponent
                onClick={() => openFeedBack(true)}
                withoutMarginTop={isMobile}
                width={197}
                text={playAgainText}
              />
              <ButtonWithRadiusBorderComponent
                withoutMarginTop={isMobile}
                color="white"
                width={197}
                onClick={() => openFeedBack(false)}
                text={exitText}
              />
            </ContainerButtons>
            <Share openShareCard={() => openShareCard(false)} />
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

WinMedalCard.propTypes = {
  G: gamePropTypes.G,
  openFeedBack: PropTypes.func.isRequired,
  openShareCard: PropTypes.func.isRequired,
  character: PropTypes.string.isRequired,
  openMedalCard: PropTypes.func.isRequired,
  Icon: PropTypes.object.isRequired,
}
