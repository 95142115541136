import React from 'react'
import PropTypes from 'prop-types'
import { shareText } from '../../common/strings'
import { IcSharedFacebook, IcShare, IcWhatsapp } from '../../assets/img'

import { ShareContent, ShareText } from './styles'
import { CircularButtonComponent } from '../Buttons/circularButton'
import {
  reportClickFacebookSharedLink,
  reportClickWhatsappSharedLink,
} from '../../services/analytics'

export const Share = ({ openShareCard }) => {
  const sharedButtons = [
    {
      Icon: IcShare,
      color: 'orange',
      onClick: openShareCard,
    },
    {
      Icon: IcWhatsapp,
      color: 'greenWhastapp',
      onClick: shareLinkWhatsapp,
    },
    {
      Icon: IcSharedFacebook,
      color: 'blueFacebook',
      onClick: sharedLinkFacebook,
    },
  ]

  function shareLinkWhatsapp() {
    reportClickWhatsappSharedLink()
    window.open(process.env.REACT_APP_WHATSAPP, '_blank')
  }

  function sharedLinkFacebook() {
    reportClickFacebookSharedLink()
    window.open(process.env.REACT_APP_FACEBOOK, '_blank')
  }

  return (
    <>
      <ShareContent>
        <ShareText>{shareText}</ShareText>
        {sharedButtons.map(({ Icon, color, onClick }, index) => {
          return (
            <CircularButtonComponent color={color} onClick={onClick} key={index}>
              <Icon />
            </CircularButtonComponent>
          )
        })}
      </ShareContent>
    </>
  )
}

Share.propTypes = {
  openShareCard: PropTypes.func.isRequired,
}
