import React, { useEffect, useMemo, useState } from 'react'
import ProtoTypes, { bool, func } from 'prop-types'

import {
  PasswordStrengthContainer,
  PasswordStrength,
  LabelPasswordStrength,
  PasswordRules,
  PasswordRule,
} from './styles'

import { Input } from '../../../../../../../../components'
import {
  passwordStrengthLabel,
  passwordWeak,
  passwordModerate,
  passwordStrong,
  PasswordStrenghtCaracters,
  PasswordStrenghtUpper,
  PasswordStrenghtLower,
  PasswordStrenghtNumber,
  PasswordStrenghtSpecial,
} from '../../../../../../../../common/strings'

export const Password = ({ signUpStrings, sendPassword, showRequired }) => {
  const [password, setPassword] = useState()
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [matchPassword, setMatchPassword] = useState(null)
  const [passwordRules, setPasswordRules] = useState({
    caracters: false,
    capitalLetter: false,
    lowerCase: false,
    number: false,
    specialCharacter: false,
  })

  const memoizedDataOfButton = useMemo(dataForPasswordRules, [passwordRules])
  function dataForPasswordRules() {
    return [
      {
        check: passwordRules.caracters,
        dataTest: 'labelPasswordRuleMinCaracters',
        text: PasswordStrenghtCaracters,
      },
      {
        check: passwordRules.capitalLetter,
        dataTest: 'labelPasswordRuleCapitalLetter',
        text: PasswordStrenghtUpper,
      },
      {
        check: passwordRules.lowerCase,
        dataTest: 'labelPasswordRuleLowerCase',
        text: PasswordStrenghtLower,
      },
      {
        check: passwordRules.number,
        dataTest: 'labelPasswordRuleNumber',
        text: PasswordStrenghtNumber,
      },
      {
        check: passwordRules.specialCharacter,
        dataTest: 'labelPasswordRuleSpecialCharacter',
        text: PasswordStrenghtSpecial,
      },
    ]
  }

  const [passwordData, setPasswordData] = useState({
    newPassword: null,
    confirmPassword: null,
  })

  useEffect(isPasswordMatch, [matchPassword])
  function isPasswordMatch() {
    const passwordMatched = matchPassword ? password : null
    sendPassword(passwordMatched)
  }

  function isStrongPassword(password) {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&])[0-9a-zA-Z$!%*&@#]{8,}$/
    return password.match(regex)
  }

  function getPasswordStrength(password) {
    if (password.length < 8) {
      const isEmptyOrWeak = password.length === 0 ? 0 : 25
      setPasswordStrength(isEmptyOrWeak)
    } else if (password.length < 10) {
      const isModerateOrWeak = isStrongPassword(password) ? 50 : 25
      setPasswordStrength(isModerateOrWeak)
    } else if (password.length >= 10) {
      const isStrongOrWeak = isStrongPassword(password) ? 100 : 25
      setPasswordStrength(isStrongOrWeak)
    }
  }

  function verifyPassword(confirmPassword) {
    const match =
      passwordData.newPassword === confirmPassword &&
      isStrongPassword(passwordData.newPassword)

    const isMatchPassword = match ? passwordData.newPassword : null
    setPassword(isMatchPassword)
    setMatchPassword(!!match)
  }

  function configDatas(event) {
    const { name, value } = event.target

    const mapActionToEachFiled = {
      newPassword: getPasswordStrength,
      confirmPassword: verifyPassword,
    }

    mapActionToEachFiled[name] && mapActionToEachFiled[name](value)
  }

  function updatePasswordRules(event) {
    const { name, value: password } = event.target
    const rules = { ...passwordRules }

    if (name === 'newPassword') {
      const hasMinumumCharacters = password.length > 7
      const hasCapitalLetter = password.match(/[A-Z]/)
      const hasLowerletter = password.match(/[a-z]/)
      const hasNumber = password.match(/[0-9]/)
      const hasSpecialCharacter = password.match(/[!@#$%^&]/)

      rules.caracters = hasMinumumCharacters
      rules.capitalLetter = hasCapitalLetter
      rules.lowerCase = hasLowerletter
      rules.number = hasNumber
      rules.specialCharacter = hasSpecialCharacter

      setPasswordRules(rules)
    }
  }

  function setFormValue(event) {
    configDatas(event)
    updatePasswordRules(event)

    const value = { [event.target.name]: event.target.value }
    setPasswordData({ ...passwordData, ...value })
  }

  function getStrength() {
    const mapPasswordStrengthToEachFiled = {
      0: '',
      25: passwordWeak,
      50: passwordModerate,
      100: passwordStrong,
    }

    return mapPasswordStrengthToEachFiled[passwordStrength]
  }

  useMemo(setCurrentPasswordStrength, [passwordStrength])
  function setCurrentPasswordStrength() {
    return passwordStrength === 25 ? false : passwordStrength >= 50 ? true : null
  }

  return (
    <>
      <div>
        <Input
          objectString={signUpStrings.newPassword}
          inputName="newPassword"
          sendValue={setFormValue}
          type="password"
          isPassword={true}
          isRequired={true}
          showRequired={showRequired}
          maxLength="12"
          matchPassword={setCurrentPasswordStrength()}
        />
        <PasswordRules>
          {memoizedDataOfButton.map(({ check, text, dataTest }, index) => (
            <PasswordRule key={index} data-test={dataTest} check={check}>
              ✓ &nbsp;{text}
            </PasswordRule>
          ))}
        </PasswordRules>
      </div>

      <PasswordStrengthContainer>
        <PasswordStrength strength={passwordStrength}>
          <span></span>
        </PasswordStrength>

        <LabelPasswordStrength strength={passwordStrength}>
          {passwordStrengthLabel}
          <span>{getStrength()}</span>
        </LabelPasswordStrength>
      </PasswordStrengthContainer>

      <Input
        objectString={signUpStrings.confirmPassword}
        inputName="confirmPassword"
        sendValue={setFormValue}
        type="password"
        isPassword={true}
        isRequired={true}
        showRequired={showRequired}
        maxLength="12"
        matchPassword={matchPassword}
      />
    </>
  )
}

const passwordPropType = {
  label: ProtoTypes.string.isRequired,
  placeholder: ProtoTypes.string.isRequired,
  dataTest: ProtoTypes.string.isRequired,
  info: ProtoTypes.string,
  showRequired: ProtoTypes.bool,
}

Password.propTypes = {
  signUpStrings: ProtoTypes.shape({
    newPassword: ProtoTypes.shape(passwordPropType),
    confirmPassword: ProtoTypes.shape(passwordPropType),
  }),
  sendPassword: func.isRequired,
  showRequired: bool,
}

Password.defaultProps = {
  showRequired: false,
}
