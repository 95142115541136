import styled, { css } from 'styled-components'

export const MenuContainer = styled.header`
  margin: 0 0 1rem 0;

  @media screen and (min-width: 1024px) {
    #mobileMenuButton {
      display: none;
    }
  }

  .menuItem {
    svg {
      margin: 0 0 0 0.428rem;
      vertical-align: middle;
    }
  }
`

export const HorizontalMenu = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    width: 100%;
    height: 74px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 38px;
    position: relative;
    z-index: 90;

    button,
    .soundEffect,
    .toggleSound,
    figure {
      margin: 0 15px;
    }
  }
`

export const SoundEffect = styled.div`
  font-family: MilkyNice;
  font-size: 23px;
  letter-spacing: -0.1px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`

export const ToggleSound = styled.div`
  min-width: 268px;
  height: 45px;
  border-radius: 23px;
  border: solid 3px ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: -2.5px 3px rgb(29, 29, 27);
`

export const Toggle = styled.div`
  width: 146px;
  height: 45px;
  border-radius: 23px;
  border: solid 3px ${({ theme }) => theme.colors.black};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  position: relative;
  top: -3px;
  transition: all 500ms;

  font-family: MilkyNice;
  font-size: 18px;
  letter-spacing: -0.08px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};

  ${({ active }) =>
    active &&
    css`
      right: 3px;
      background-color: ${({ theme }) => theme.colors.lemonGreen};
    `}

  ${({ active }) =>
    !active &&
    css`
      right: -118px;
      background-color: ${({ theme }) => theme.colors.reddishOrange};
    `}
`

export const TextButtonWithRadiusBorder = styled.span`
  padding-left: 10px;
`

export const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 1rem;
  place-items: start;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  left: 0;
  color: ${({ theme }) => theme.colors.white};
  top: 0;
  background-color: ${({ theme }) => theme.colors.lemonGreen};
`

export const MenuMobileItem = styled.button`
  background: none;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding-left: calc(60px - 1rem);
`

export const MenuMobileContainerItems = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: flex-start;

  .menuItem {
    margin-bottom: 30px;
  }
`

export const MenuMobileItemText = styled.span`
  font-family: MilkyNice;
  font-size: 18px;
  letter-spacing: -0.08px;
  padding-left: 10px;
`
