import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import { turnCounterTooltip } from '../../common/strings'
import { Container } from './styles'

export const Counter = ({ value, maxValue }) => {
  return (
    <Container data-tip="counter-tooltip">
      <span>{`${value}${maxValue ? `/${maxValue}` : ''}`}</span>
      <ReactTooltip place="right" effect="solid">
        {turnCounterTooltip}
      </ReactTooltip>
    </Container>
  )
}

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
}
