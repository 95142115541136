import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '../../../components'
import { BackgroundBlur, Container } from '../../../components/GameCards/styles'
import { IcCopy } from '../../../assets/img'
import {
  ContentCard,
  ContainerButton,
  LinkIconContainer,
  VucaLink,
  LinkCopied,
} from './styles'
import {
  copyText,
  backText,
  shareGameText,
  shareGameParagraph,
  copiedText,
} from '../../../common/strings'
import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'
import { reportCopiedTheShareLink } from '../../../services/analytics'

export const ShareCard = ({ back }) => {
  const [showCopied, setShowCopied] = useState(false)

  function copyLink() {
    reportCopiedTheShareLink()
    navigator.clipboard.writeText(process.env.REACT_APP_GAME_VUCA)
    showCopiedMessage()
  }

  function showCopiedMessage() {
    setShowCopied(true)
    const fourSecounds = 4000
    setInterval(function () {
      setShowCopied(false)
    }, fourSecounds)
  }

  return (
    <>
      <Container>
        <CardWithHeader
          titleCard={shareGameText}
          backgroundColorHeader="white"
          isSmall
          data_test="shareCard">
          <ContentCard>
            <p dangerouslySetInnerHTML={{ __html: shareGameParagraph }} />
            <LinkIconContainer>
              <VucaLink>{process.env.REACT_APP_GAME_VUCA}</VucaLink>
              <IcCopy onClick={copyLink} />
            </LinkIconContainer>
            {showCopied && <LinkCopied>{copiedText}</LinkCopied>}
            <ContainerButton>
              <ButtonWithRadiusBorderComponent
                color="white"
                onClick={copyLink}
                data_test="copyLinkButton"
                text={copyText}
              />

              <ButtonWithRadiusBorderComponent
                data_test="backButton"
                onClick={back}
                text={backText}
              />
            </ContainerButton>
          </ContentCard>
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </>
  )
}

ShareCard.propTypes = {
  back: PropTypes.func.isRequired,
}
