import React from 'react'
import {
  CrisCharacter,
  MilenaCharacter,
  BrendaCharacter,
  JonathanCharacter,
} from '../../assets/img/characters'
import CrisIcon from '../../assets/img/characters/Cris.svg'
import BrendaIcon from '../../assets/img/characters/Brenda.svg'
import MilenaIcon from '../../assets/img/characters/Milena.svg'
import JonathanIcon from '../../assets/img/characters/Jonathan.svg'

export const characters = [
  {
    image: () => <CrisCharacter height="200" />,
    name: 'Cris',
    icon: CrisIcon,
    details:
      'Tenho 15 anos gosto de realizar atividades animadas. Não me identifico com nenhum gênero e enfrento alguns problemas por isso. Me engajo em causas sociais pois acredito em um mundo melhor.',
  },
  {
    image: () => <MilenaCharacter height="200" />,
    name: 'Milena',
    icon: MilenaIcon,
    details:
      'Tenho 15 anos e sou do tipo empreendedora. Escrevo sobre minha vida e amo postar tudo. Não gosto de acordar cedo, mas moro longe da escola. Sonho em trabalhar no meu próprio negócio, por que não?',
  },
  {
    image: () => <BrendaCharacter height="200" />,
    name: 'Brenda',
    icon: BrendaIcon,
    details:
      'Sou criativa e muito curiosa. Gosto de arte e cultura, mas não sei muito bem o que quero fazer do futuro. Afinal, o mundo anda tão difícil...',
  },
  {
    image: () => <JonathanCharacter height="200" />,
    name: 'Jonathan',
    icon: JonathanIcon,
    details:
      'Tenho 16 anos, sou tímido, mas muito organizado. As pessoas dizem que eu não gosto de mudanças e é verdade. Sonho em fazer uma boa faculdade e ter um emprego estável.',
  },
]
