import React from 'react'
import PropTypes from 'prop-types'

import { BalloonOfDescriptionCharacter } from '../../../../styles'

import { Container, Character } from './styles'

export const CharactersDetailsDesktop = ({ characters, dataChooseCharacters }) => (
  <Container quantityColumns={characters.length}>
    {characters.map((item, index) => (
      <Character
        key={index}
        onClick={dataChooseCharacters.select(index)}
        selectedCharacter={dataChooseCharacters.indexCurrentCharacter === index}>
        <figure>{item.image()}</figure>
        <BalloonOfDescriptionCharacter>
          <p>{item.details}</p>
        </BalloonOfDescriptionCharacter>
      </Character>
    ))}
  </Container>
)

CharactersDetailsDesktop.propTypes = {
  characters: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.func.isRequired,
      details: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  dataChooseCharacters: PropTypes.shape({
    indexCurrentCharacter: PropTypes.number,
    select: PropTypes.func.isRequired,
  }),
}
