import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  ContentCard,
  ContainerButton,
  TextMedal,
  CenterFlex,
  MedalDatas,
  MedalData,
  Data,
} from './styles'

import { backText } from '../../../../common/strings'
import { Card, CardWithHeader } from '../../../Cards'
import { isMobileSize } from '../../../../common/utils'
import { BackgroundBlur } from '../../../GameCards/styles'
import { ButtonWithRadiusBorderComponent } from '../../../Buttons'
import { IcPeople, IcDate } from '../../../../assets/img'
import { get } from '../../../../services/storage'

export const CardMedal = ({ closeMedalCard, currentMedal, Icon }) => {
  const isMobile = useMemo(isMobileSize, [])

  function formateDate(date) {
    let dateAux = new Date()

    if (date) {
      dateAux = new Date(date)
    }

    return (
      dateAux.getDate() + '/' + (dateAux.getMonth() + 1) + '/' + dateAux.getFullYear()
    )
  }

  function returnContentCard() {
    return (
      <ContentCard>
        <CenterFlex>
          <Icon />
          <TextMedal
            dangerouslySetInnerHTML={{
              __html: currentMedal.text,
            }}
          />
        </CenterFlex>
        <MedalDatas>
          <MedalData>
            <IcPeople />
            <Data paddingLeft="16">{currentMedal.username || get('user').name}</Data>
          </MedalData>
          <br />
          <MedalData>
            <IcDate />
            <Data paddingLeft="10">{formateDate(currentMedal.date)}</Data>
          </MedalData>
        </MedalDatas>

        <ContainerButton>
          <ButtonWithRadiusBorderComponent
            type="button"
            color={isMobile ? '' : 'white'}
            data_test="goToGame"
            onClick={() => closeMedalCard(false)}
            text={backText}
          />
        </ContainerButton>
      </ContentCard>
    )
  }

  return (
    <div>
      <Container>
        {isMobile && (
          <CardWithHeader titleCard={currentMedal.text} isSmall data_test="myMedalsCard">
            {returnContentCard()}
          </CardWithHeader>
        )}

        {!isMobile && (
          <Card minWidth="505px" data_test="myMedalsCard">
            {returnContentCard()}
          </Card>
        )}
      </Container>
      {!isMobile && <BackgroundBlur />}
    </div>
  )
}

CardMedal.propTypes = {
  closeMedalCard: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.bool.isRequired,
  ]),
  currentMedal: PropTypes.object.isRequired,
  Icon: PropTypes.object.isRequired,
}
