import { junior } from './junior'
import { middle } from './middle'
import { superior } from './superior'

const defaults = {
  job: {
    category: 'job',
    question:
      '<name> não está trabalhando. Aproveite para estudar e curtir os desafios dessa fase!',
    type_question: 'luck_or_setback',
    options: [
      {
        response_text: '',
        point: 10,
        feedback: '',
      },
    ],
    complete: false,
    has_interaction: false,
  },
}

export const cards = {
  junior,
  middle,
  superior,
  defaults,
}
