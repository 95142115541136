window.dataLayer = window.dataLayer || []

export function sendEvent({ categoy, action, label }) {
  const event = {
    event: 'ga.event',
    gaCategory: categoy,
    gaAction: action,
    gaLabel: label || null,
  }

  const isProd = document.location.hostname.search('gamevuca.org') !== -1

  if (isProd) {
    window.dataLayer.push(event)
  }
}

export function reportSelectCharacter(name) {
  sendEvent({
    categoy: 'character',
    action: name,
  })
}

export function reportExceededLimitTurn() {
  sendEvent({
    categoy: 'turn',
    action: 'exceededLimit',
  })
}

export function reportReplayGame() {
  sendEvent({
    categoy: 'turn',
    action: 'replayGame',
  })
}

export function reportPlayerWin() {
  sendEvent({
    categoy: 'turn',
    action: 'playerWin',
  })
}

export function reportIntentionLeaveGame(gameStage) {
  sendEvent({
    categoy: 'game',
    action: 'intentionLeaveGame',
    label: gameStage,
  })
}

export function reportPlayWithoutLogin() {
  sendEvent({
    categoy: 'game',
    action: 'playWithoutLogin',
  })
}

export function reportUpdateAccount() {
  sendEvent({
    categoy: 'game',
    action: 'updateRegistration',
  })
}

export function reportPlayWithLogin() {
  sendEvent({
    categoy: 'game',
    action: 'playWithLogin',
  })
}

export function reportCompleteRegistration() {
  sendEvent({
    categoy: 'game',
    action: 'completeRegistration',
  })
}

export function reportCancelRegistration() {
  sendEvent({
    categoy: 'game',
    action: 'cancelRegistration',
  })
}

export function reportAnsweredFeedback(action) {
  sendEvent({
    categoy: 'feedback',
    action: action,
  })
}

export function reportCopiedTheShareLink() {
  sendEvent({
    categoy: 'share',
    action: 'copiedTheShareLink',
  })
}

export function reportClickWhatsappSharedLink() {
  sendEvent({
    categoy: 'share',
    action: 'clickWhatsappSharedLink',
  })
}

export function reportClickFacebookSharedLink() {
  sendEvent({
    categoy: 'share',
    action: 'clickFacebookSharedLink',
  })
}

export function reportContinueGameProgress() {
  sendEvent({
    categoy: 'turn',
    action: 'continueGameProgress',
  })
}

export function reportRetriveGame() {}
