import React from 'react'
import PropTypes from 'prop-types'

import { Body } from './components/cardBody'
import { Footer } from './components/cardFooter'

import { reportCancelRegistration } from '../../../services/analytics'

import { CardWithHeader } from '../../../components'

import { signUpText, editAccount } from '../../../common/strings'
import { Container } from './styles'
import { BackgroundBlur } from '../../../components/GameCards/styles'

export const SignUpCard = ({ isEdit, isSignUpInsideGame, closeSignUpCard }) => {
  function reportAnalyticsCloseCard() {
    if (!isEdit) {
      reportCancelRegistration()
    }

    closeSignUpCard()
  }

  return (
    <>
      <Container>
        <div className="SignUpCard">
          <CardWithHeader
            titleCard={isEdit ? editAccount : signUpText}
            backgroundColorHeader="pistachioGreen"
            handleClickCloseButton={() => {}}
            withFooter={false}
            data_test="SignUpCard">
            <Body
              isEdit={isEdit}
              isSignUpInsideGame={isSignUpInsideGame}
              closeSignUpCard={reportAnalyticsCloseCard}
            />
            <Footer />
          </CardWithHeader>
        </div>
      </Container>
      {isEdit && <BackgroundBlur />}
    </>
  )
}

SignUpCard.propTypes = {
  isEdit: PropTypes.bool,
  isSignUpInsideGame: PropTypes.bool,
  closeSignUpCard: PropTypes.func.isRequired,
}

SignUpCard.defaultProps = {
  isEdit: false,
  isSignUpInsideGame: false,
}
