import React from 'react'
import PropTypes from 'prop-types'

import { continueText } from '../../common/strings'

import { Card } from '..'
import { Container, BackgroundBlur, ContentCard, ContainerButton } from './styles'

import { useContentOfTextOnStage } from './useContentOfTextOnStage'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'

export function StageCompleteAlert({
  characterName,
  nameOfCompleteLevel,
  confirmCompleteStage,
}) {
  const { title, message, Icon } = useContentOfTextOnStage(
    characterName,
    nameOfCompleteLevel,
  )
  return (
    <>
      <Container data-test="containerFeedbackCard">
        <Card isSmall data_test="feedbackCard">
          <ContentCard>
            <figure>
              <Icon width="100%" height="10rem" data-test="iconFeedBackCard" />
            </figure>

            <section>
              <h1
                dangerouslySetInnerHTML={{ __html: title }}
                data-test="titleFeedBackCard"
              />

              <p
                dangerouslySetInnerHTML={{ __html: message }}
                data-test="paragraphFeedBackCard"
              />

              <ContainerButton>
                <ButtonWithRadiusBorderComponent
                  onClick={confirmCompleteStage(nameOfCompleteLevel)}
                  data_test="buttonFeedBackCard"
                  text={continueText}
                />
              </ContainerButton>
            </section>
          </ContentCard>
        </Card>
      </Container>
      <BackgroundBlur />
    </>
  )
}

StageCompleteAlert.propTypes = {
  characterName: PropTypes.string.isRequired,
  nameOfCompleteLevel: PropTypes.string.isRequired,
  confirmCompleteStage: PropTypes.func.isRequired,
}
