import React from 'react'

export { ReactComponent as CrisOnRulesScreen } from './CrisOnRulesScreen.svg'
export { ReactComponent as AllCharactersDesktop } from './allCharactersDesktop.svg'
export { ReactComponent as JonathanOnRulesScreen } from './JonathanOnRulesScreen.svg'
export { ReactComponent as allCharactersRulesScreen } from './allCharactersRulesScreen.svg'

export const CrisCharacter = (props) => (
  <img src={require('./Cris.svg')} alt="our characters" {...props} data-teste="cris" />
)
export const BrendaCharacter = (props) => (
  <img
    src={require('./Brenda.svg')}
    alt="our characters"
    {...props}
    data-teste="brenda"
  />
)
export const MilenaCharacter = (props) => (
  <img
    src={require('./Milena.svg')}
    alt="our characters"
    {...props}
    data-teste="milena"
  />
)
export const JonathanCharacter = (props) => (
  <img
    src={require('./Jonathan.svg')}
    alt="our characters"
    {...props}
    data-teste="jonatan"
  />
)
