import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import {
  forgetPasswordTitle,
  forgetPasswordParagraph,
  forgetPasswordParagraphEmailSent,
  emailLabel,
  emailLabelPlaceholder,
  emailAlreadySignedUp,
  backText,
  sendText,
  okText,
} from '../../../../common/strings'

import { forgotPassword } from '../../../../services/API/password'

import { validateEmail } from '../../../../common/utils'

import { CardWithHeader, Input } from '../../../../components'
import { BackgroundBlur, Container } from '../../../../components/GameCards/styles'

import { ContentCard, ContainerButton } from './styles'
import { ButtonWithRadiusBorderComponent } from '../../../../components/Buttons/buttonWithRadiusBorder'

export const CardForgetPassword = ({ closeCardForgetPassword }) => {
  const [isValidEmail, setIsValidEmail] = useState(null)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [userEmail, setUserEmail] = useState(null)
  const [paragraphEmailSent, setParagraphEmailSent] = useState('')

  const username = useMemo(buildUsernameObject, [])
  const memoizedDataOfButton = useMemo(dataOfButtons, [userEmail, isEmailSent])

  async function sendEmail() {
    const response = await forgotPassword({ email: userEmail })
    const { status } = response

    if (status === 200) {
      setIsEmailSent(true)
    }
  }

  useEffect(formatParagraphEmailSent, [userEmail])
  function formatParagraphEmailSent() {
    const newParagraph = forgetPasswordParagraphEmailSent.replace(/<email>/g, userEmail)
    setParagraphEmailSent(newParagraph)
  }

  function setEmail(e) {
    const { value } = e.target
    setUserEmail(value)

    const isValid = validateEmail(value)
    setIsValidEmail(isValid)
  }

  function buildUsernameObject() {
    return {
      label: emailLabel,
      placeholder: emailLabelPlaceholder,
      error: emailAlreadySignedUp,
    }
  }

  function dataOfButtons() {
    return [
      {
        text: backText,
        data_test: 'forgetPasswordBackButton',
        hide: isEmailSent,
        color: 'white',
        onClick: closeCardForgetPassword,
      },
      {
        text: sendText,
        data_test: 'forgetPasswordSendEmailButton',
        hide: isEmailSent,
        disabled: !isValidEmail,
        onClick: sendEmail,
      },
      {
        text: okText,
        data_test: 'forgetPasswordOkButton',
        hide: !isEmailSent,
        onClick: closeCardForgetPassword,
      },
    ]
  }

  function showEmailInvalid() {
    return typeof isValidEmail === 'boolean' ? !isValidEmail : ''
  }

  return (
    <>
      <Container>
        <CardWithHeader
          titleCard={forgetPasswordTitle}
          backgroundColorHeader="white"
          isSmall
          data_test="playWithoutLoginCard">
          <ContentCard>
            {!isEmailSent && (
              <Input
                objectString={username}
                inputName="emailLogin"
                data-test="inputEmailLogin"
                sendValue={setEmail}
                isRequired={true}
                showError={showEmailInvalid()}
              />
            )}

            <p
              data-test="msgRecoverPassword"
              dangerouslySetInnerHTML={{
                __html: isEmailSent ? paragraphEmailSent : forgetPasswordParagraph,
              }}
            />

            <ContainerButton>
              {memoizedDataOfButton.map(
                ({ text, data_test, hide, color, disabled, onClick }) =>
                  !hide && (
                    <ButtonWithRadiusBorderComponent
                      onClick={onClick}
                      key={text}
                      disabled={disabled}
                      color={color || ''}
                      data_test={data_test}
                      text={text}
                    />
                  ),
              )}
            </ContainerButton>
          </ContentCard>
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </>
  )
}

CardForgetPassword.propTypes = {
  closeCardForgetPassword: PropTypes.func.isRequired,
}
